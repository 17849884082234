export let AU = {
    GENERAL_INFO: "General Info",
    TITLE_1: "What information do I need to provide to register my product?",
    INFORMATION_NEEDED: "Be prepared to provide the following",
    PACKAGE: "Package",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "Your email address and telephone number",
    SHIPPING_ADDRESS: "Your shipping address",
    PRODUCT_NAME: "Name of the product",
    QRCODE: "QR code",
    TITLE_2: "What if I do not have my product package or if I lost my QR code?",
    QRCODE_LOSS: "In case of lost QR ID, it is possible to ask for a new QR label (+ QR ID).",
    GIVE_SOME_INFORMATIONS: "Please provide us with the below information accompanied by the proof of purchase and we will generate a new QR ID for your product",
    PRODUCT_INFORMATIONS: "Information about the product you are trying to register",
    BRAND: "Brand",
    PRODUCT_CODE: "Product Code",
    SKU_EAN_UPC: "Product Code/EAN/UPC",
    SERIAL_NUMBER_AVAILABLE: "Serial Number (if available)",
    BUY_COUNTRY: "Country of Purchase",
    DEALER_NAME: "Merchant's Name",
    BUY_DATE: "Purchase Date",
    PERSONAL_INFORMATIONS: "Your personal information",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    COUNTRY_RESIDENCE: "Country of Residence",
    PROOF_OF_PURCHASE: "Proof of purchase",
    SALES_INVOICE: "Sales invoice",
    SECURITY_LABEL: "Security label",
    SUPPORT: "Support",
    TITLE_3: "What if a problem or an error occurs during the registration process?",
    SUPPORT_HOW_TO: "If the problems occur at the moment of product registration or warranty extension, please provide us with a summary of the problem and below information as follows",
    PROBLEM_DESCRIPTION: "Problem description",
    COPY_MESSAGE: "Please fully copy the error message you see on the website",
    MODEL: "Model",
    REGISTER_WARRANTY: "Register Warranty",
    MY_PRODUCTS: "My Products",
    FAQ: "FAQ",
    NAV_SUPPORT: "Support",
    DISCOVER_MORE: "Discover More",
    LOGOUT: "Logout",
    ACCOUNT: "Account",
    LOGIN: "Log in",
    REGISTER: "Register",
    START_HERE: "Start here!",
    COMPLETED: "Completed",
    LOGIN_OR_REGISTER: "Log-in or register now to obtain the Videndum Media Solutions warranty extension on your products",
    SUPPORT_SUBTITLE: 'For any question, please click on the buttons below to contact us. A member of our local team will get back to you shortly.',
    NAME: 'Name',
    EMAIL: 'Email',
    COUNTRY: 'Country',
    REQUEST_TYPE: 'Request Type',
    REQUEST: 'Request',
    SEND_REQUEST: 'Send request',
    NEED_HELP: "Need help?",
    NEED_HELP_DESCRIPTION: "Our customer service team is here to help you with any questions of issues you may encounter. You can either visit our FAQ section of contact us directly.",
    SITE_TERMS: "Site Terms",
    PRIVACY_POLICY: "Privacy Policy",
    COOKIES_POLICY: "Cookies Policy",
    COOKIES_URL: "https://www.videndummediasolutions.com/en/cookie-policy/",
    MENU: "Menu",
    CUSTOMER_EXPERIENCE: "Your Videndum Media Solutions Customer Experience",
    WELCOME_MESSAGE: "WELCOME TO THE ONLINE PRODUCT REGISTRATION PLATFORM",
    HOMEPAGE_MESSAGE: "Please use this site to register your <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>Colorama</strong>, <strong>National Geographic</strong> and <strong>Avenger</strong> products.",
    DISCOVER: "Discover the range of services we offer below.",
    REGISTER_PRODUCT: "Register product",
    ACCOUNT_TITLE: 'Your account',
    ACCOUNT_SUBTITLE: 'Change your basic information and interests here.',
    MYPROFILE: 'My profile',
    CHANGE_PASSWORD: 'Change password',
    CHANGE_EMAIL: 'Change email',
    ADDRESS: 'Address',
    CITY: 'City',
    COUNTRY_REGION: 'Country/Region',
    ZIPCODE: 'Postal code',
    STATE: 'State',
    PHONE: 'Phone',
    SUBSCRIBE_TO_NEWSLETTER: 'I sign-up to receive free newsletters and I have read, understood and accepted <link>the privacy notice.</link>',
    SAVE: 'Save',
    SAVING: 'Saving data',
    FIELD_REQUIRED: "{field} is mandatory",
    NEWEMAIL: "New email",
    NEWEMAIL_CONFIRMATION: "Confirm new email",
    PASSWORD: "Password",
    OLDPASSWORD: "Old password",
    NEWPASSWORD: "New password",
    NEWPASSWORD_CONFIRMATION: "Confirm new password",
    RECEIPT_UPLOADED: "Receipt uploaded",
    RECEIPT_NOT_UPLOADED: "Receipt not uploaded",
    CHOOSE_COUNTRY: "Choose country",
    CHOOSE_BRAND: "Choose brand",
    COMPLETE_ACCOUNT_TITLE: "Your account is not completed",
    COMPLETE_ACCOUNT_SUBTITLE: "Please complete your account to proceed with product registrations",
    BOUGHT_ONLINE: "I bought it online",
    BOUGHT_IN_STORE: "I bought it in a store",
    THANK_YOU: "Thank you",
    FOR_REGISTERING: "{firstName} {lastName} for registering your product!",
    PRODUCT_REGISTERED: "The product {sku} warranty has been extended {months} months",
    WARRANTY_EXTENSION: "months warranty",
    EXTENSION: "Extension",
    NEW_EXPIRATION: "New expiration date",
    PURCHASED_FROM: "Purchased from",
    TERMS_AND_CONDITIONS: "View full terms and conditions",
    DOWNLOAD_WARRANTY: "Click here to download the warranty certification",
    REGISTER_ANOTHER_PRODUCT: "Register another product",
    GOTO_PRODUCT_LIST: "Go to your product list",
    FORGOT_PASSWORD: 'Forgot your password?',
    NEW_ACCOUNT: "New account",
    WELCOME_USER: 'Welcome {name}',
    EDIT_ACCOUNT: "Edit account",
    LOGIN_TO_WARRANTY: "Sign in to your warranty",
    CHANGE_PURCHASEDATE_RECEIPT: "Change purchase date or upload another receipt",
    PURCHASE_DATE: "Purchase date",
    REGISTER_PRODUCT_TO_EXTEND: "Register your product and extend your warranty",
    INSERT_PRODUCT_CODE: "Insert your product code below to start your warranty registration",
    SAVE_AND_PROCEED: "Save and proceed",
    CHANGE_PRODUCT: "Change product",
    DOWNLOAD_RECEIPT: "Download receipt",
    SERIAL_NUMBER: "Serial Number",
    STORE: "Store",
    RECEIPT: "Receipt",
    REGISTERED_ON: "Registered on",
    PURCHASED_ON: "Purchased on",
    YOUR_PRODUCT_LIST: "Your product list",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "All the products you have registered are displayed and can be modified below.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "To register a new product, simply start the Standard Registration procedure.",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "Note: please ensure that you always keep your original receipts and documents for proof of purchase",
    ADD_PURCHASEDATE_AND_RECEIPT: "Add purchase date and receipt",
    PLEASE_UPLOAD_RECEIPT: "Please upload your receipt to claim using your warranty.",
    UPLOAD_RECEIPT: "Upload your receipt",
    PRIVACY_READ: "I have read <link>the Privacy notice:</link>",
    PRIVACY_URL: "https://www.videndummediasolutions.com/en/privacy-policy/",
    UPLOADING: "Uploading",
    CHOOSE_COUNTRY_AND_STORE: "Select country and store of purchase",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "Please, add the store name and country in which you have purchased your product.",
    NO_OFFICIAL_STORE_FOUND: "No official store found.",
    PROCEED_ANYWAY: "Proceed anyway with the store name inserted.",
    EXTENSION_FOUND: "{months} months warranty extension",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "Insert the product Code/EAN/UPC to discover which warranty is available for this product",
    DISCOVER_WARRANTY: "Discover which warranty is available for this product",
    LOCATE_PRODUCT_CODE: "Locating the product code",
    LOCATE_PRODUCT_CODE_CLICK: "You can find the product code near the barcode at the bottom or on the edges of the box.",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "You can find the Product Code on the packaging. Please refer to the image on the left to find the product code in the upper left corner.",
    LOCATING_EAN_UPC: "Locating EAN or UPC bar code",
    LOCATING_EAN_UPC_DESCRIPTION: "You can find the EAN or UPC code on the packaging. Please refer to the image to the left to find the UPC/EAN in the lower left corner.",
    INSERT_PRODUCT_QR_CODE: "Insert the product QR Code",
    LOCATE_PRODUCT_QR_CODE: "The QR code is located on your product's packaging. Please enter the alphanumeric code under the QR code sign. If your QR code is missing or has been tampered with, please contact customer support.",
    NORMAL_PRODUCT_QR_CODE: "Normal QR code",
    NORMAL_PRODUCT_QR_CODE_DESC: "QR Code should be in-tact and free of extra markings with the alphanumeric code clearly visible.",
    TAMPERED_PRODUCT_QR_CODE: "Tampered with QR codes",
    TAMPERED_PRODUCT_QR_CODE_DESC: "If your QR Code is missing, has extra markings or is rubbed out, please use the link below so that we can assist you.",
    PRODUCT_ELIGIBLE_FOR: "Product eligible for",
    NO_REGISTERED_PRODUCTS: "You have no registered products",
    SUPPORTS: "Supports",
    CAMERA_ACCESSORIES: "Camera Accessories",
    AUDIO_CAPTURE: "Audio Capture",
    BAGS: "Bags",
    MOBILE_POWER: "Mobile Power",
    VIDEO_TRANSMISSION_SYSTEMS: "Video transmission systems",
    PROMPTERS: "Prompters",
    MOTION_CONTROL: "Motion Control",
    DISTRIBUTION_RENTAL_SERVICES: "Distribution, rental & services",
    LIGHTING_AND_CONTROLS: "Lighting and controls",
    ROBOTIC_CAMERA_SYSTEMS: "Robotic camera systems",
    MONITORS: "Monitors",
    OUR_BRANDS: "Our Brands",
    DEALER_MANDATORY: "Store has not been selected",
    REGISTER_ACCOUNT_TITLE: "Register new account",
    PASSWORD_CONFIRMATION: "Confirm password",
    SEND_CODE: 'Send code',
    CODE: 'Code',
    RESET_PASSWORD: 'Reset password',
    RESET_PASSWORD_SUBTITLE: 'Please set the code that we have sent to your email and new password',
    RESET_PASSWORD_NEEDED: "You need to reset your password to proceed",
    SIGNUP_CONFIRM: 'Confirm sign up',
    NOT_REGISTERED: "Not registered?",
    NOT_REGISTERED_SUBTITLE: "Click on button below to create new account.",
    SIGNUP_CONFIRMED: "Your account has been verified, click on login button to login.",
    CONFIRM: "Confirm",
    LOGIN_WITH_CREDENTIALS: "Login with Videndum Warranty account",
    LOGIN_WITH_SOCIAL: "Login with social account",
    UNLIMITED_WARRANTY: "Unlimited warranty",
    VERIFICATION_CODE_NOT_ARRIVED: "Verification code is not arrived?",
    CLICK_ON_BUTTON: "Click on button below to re-send verification code.",
    SEND: "Send",
    PLEASE_CHOOSE_BRAND: "Please choose at least one brand you like.",
    PROFILING: "I give my consent to Videndum Media Solutions Spa to process my personal data and use profiling tools in order to send personalized commercial communications, according to the terms provided in the privacy notice",
    MANAGE_SUBSCRIPTIONS: "To manage your subscriptions click {link}",
    COOKIE_CONSENT: "Please {link} to watch this video",
    ACCEPT_STATISTICS_COOKIES: "accept 'statistics cookies'",
    HERE: "here",
    QRCODE_NOT_VALID: "QR Code is not valid", 
    DOWNLOAD_AREA: "Download area", 
    DOWNLOADABLE_CONTENTS: "Downloadable contents", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Digital Background Library", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Users may choose to download all categories to your device at once, or revisit this page in the future by simply logging into this portal with your SavageUniversal.com account credentials.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Savage Green Screen Wizard Lite Users", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Please use the button below to register your unique serial number included in your product package to gain access to the library.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Here you can find your downloadable contents.", 
    ADD_SERIAL_NUMBER: "Add serial number", 
    CANCEL: "Cancel", 
    DOWNLOAD: "Download", 
    YOUR_SERIAL_NUMBER: "Your serial number: {serialNumber}", 

    INFO_DATA_SAVED: "Data saved",
    INFO_REQUEST_SENT: "Request sent",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "Only non Manfrotto users can change password.",
    INFO_USER_ACCOUNT_SAVED: "User account saved",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "Are you sure that you want to change your email?",
    INFO_CONFIRM_REGISTRATION: "Are you sure that you want to register warranty for this product?",
    INFO_CONFIRM_SERIAL_NUMBER: "Are you sure that you want to register this serial number?",
    INFO_SERIAL_NUMBER_REGISTERED: "Serial number registered.",

    ERR_USER_ALREADY_EXIST: "User already exist",
    ERR_CHECK_PRIVACY: "You need to check the privacy checkbox",
    ERR_USER_NOT_FOUND: "User not found",
    ERR_PASSWORD_REQUIRED: "Password required",
    ERR_PASSWORD_MISMATCH: "Passwords does not match",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "Unable to change password",
    ERR_OLDPASSWORD_REQUIRED: "Old password is required",
    ERR_NEWPASSWORD_REQUIRED: "New password is required",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "New password confirmation is required",
    ERR_CAPTCHA_ISREQUIRED: "Please resolve captcha",
    ERR_NEWEMAIL_REQUIRED: "New email is not valid",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "New email confirmation is not valid",
    ERR_PASSWORD_NOT_VALID: "Password is not valid",
    ERR_EMAIL_DONOT_MATCH: "Emails does not match",
    ERR_PRODUCT_NOT_FOUND: "Product not found",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "Videndum users cannot change password",
    ERR_RESET_PASSWORD_FAILED: "Unable to reset your password.",
    ERR_UNABLE_TO_UPDATE_USER: "Unable to update user informations.",
    ERR_BRANDS_MANDATORY: "At least one brand must be specified.",
    ERR_INVALID_SERIAL_NUMBER: "Please specify valid serial number.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Serial number not found.",

    AVAILABLE_ONLY_ON: "Available only on",
    GITZO_LEGENDE: "GITZO Légende",
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "GITZO Légende, Join the campaign on Indiegogo",
    LEGENDE_TRIPOD_AND_BACKPACK: "Légende Tripod and Backpack",
    LEGENDE_UNLIMITED_WARRANTY: "Unlimited warranty",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "repair, or replace for endless adventure.",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "All Légende Tripods and Backpacks ordered from Indiegogo come with an unlimited warranty, under normal use. If you discover defects in material or workmanship, we will repair it, send a new part, or replace it, free of charge.",
    LEGENDE_READ_TC: "Read T&C",
    LEGENDE_TRIPOD: "Légende Tripod",
    LEGENDE_TRIPOD_SUB: "The best tripod we could make and forever repairable.",
    LEGENDE_100_PARTS_REPLACEABLE: "100% parts replaceable forever repairable",
    LEGENDE_70_REPLACEABLE: "70% of parts user replaceable",
    LEGENDE_SELF_SERVICEABLE: "Self serviceable",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "Self-serviceable, for optimum performance throughout its entire life.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Simple maintenance steps help keep the Légende in perfect shape no matter where you take it.",
    LEGENDE_BACKPACK: "Légende Backpack",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Expertly designed for the travel photographer, crafted for sustainability and the future.",
    LEGENDE_65_RECYCLED: "65% recycled fabrics",
    LEGENDE_ECOFRIENDLY_LEATHER: "Eco- friendly leather",
    LEGENDE_HOWTO_CARE_BACKPACK: "How to care for your Légende backpack.",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Follow these basic steps to care for your Légende Backpack.",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/uk-en/customer-service/warranty-terms-and-conditions/"
}
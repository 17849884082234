import { useContext, useEffect } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { authContext } from 'helpers/services';
import { Spinner } from 'components';


let PrivateRoute = (props: any) => {
    let location = useLocation();
    let history = useHistory();
    let authService = useContext(authContext);

    const returnUrl = `/account/login?returnUrl=${location.pathname}`;


    return (
        <div>
            {(authService.isLoading === true) && <Spinner />}
            {(authService.isLoading === false) &&
                <>
                    {authService.isAuthenticated === false && <Redirect to={returnUrl} />}
                    {authService.isAuthenticated === true &&
                        <>
                            {authService.currentUser?.isCompleted === false && <Redirect to={'/account/complete'} />}
                            {authService.currentUser?.isCompleted === true && <Route {...props} />}
                        </>
                    }
                </>
            }
        </div>
    )
}

export default PrivateRoute;
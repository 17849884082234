export let JP = {
    GENERAL_INFO: "一般的な情報",
    TITLE_1: "製品登録時にご準備いただくもの",
    INFORMATION_NEEDED: "下記をご準備ください。",
    PACKAGE: "外箱",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "領収書",
    SHIPPING_ADDRESS: "メールアドレスと電話番号",
    PRODUCT_NAME: "住所",
    QRCODE: "QRコード",
    TITLE_2: "QRコードを無くされた方、または外箱をお持ちでない方",
    QRCODE_LOSS: "QRコードを無くされた方は、新しいQRラベルを発行することができます。",
    GIVE_SOME_INFORMATIONS: "新しいQRコードを発行させていただきますので、以下の情報をご提供ください。",
    PRODUCT_INFORMATIONS: "登録する製品情報",
    BRAND: "ブランド",
    PRODUCT_CODE: "品番",
    SKU_EAN_UPC: "製品コード/EAN/UPC",
    SERIAL_NUMBER_AVAILABLE: "シリアルナンバー",
    BUY_COUNTRY: "購入された国",
    DEALER_NAME: "ご購入店",
    BUY_DATE: "購入日付",
    PERSONAL_INFORMATIONS: "個人情報",
    FIRSTNAME: "名",
    LASTNAME: "姓",
    EMAIL: 'Eメール',
    COUNTRY_RESIDENCE: "現在居住国",
    PROOF_OF_PURCHASE: "購入証明",
    SALES_INVOICE: "売上請求書",
    SECURITY_LABEL: "セキュリティラベル",
    SUPPORT: "サポート",
    TITLE_3: "登録時にエラーが発生する場合",
    SUPPORT_HOW_TO: "製品登録や延長保証登録中にトラブルが発生した場合、以下の情報を含め、ご連絡ください。",
    PROBLEM_DESCRIPTION: "問題の説明",
    COPY_MESSAGE: "エラーメッセージをコピーする",
    MODEL: "モデル",
    REGISTER_WARRANTY: "保証のアクティベーション（有効化）",
    MY_PRODUCTS: "登録製品",
    FAQ: "FAQ",
    NAV_SUPPORT: "サポート",
    DISCOVER_MORE: "詳細を見る",
    LOGOUT: "ログアウト",
    ACCOUNT: "マイページ",
    LOGIN: "製品登録",
    REGISTER: "登録",
    START_HERE: "ここから始める！",
    COMPLETED: "完了",
    LOGIN_OR_REGISTER: "今すぐログインまたは登録して、製品のVidendum ImagingSolutions保証延長を取得してください", 
    CHANGE_PRODUCT: "製品の変更", 
    SUPPORT_SUBTITLE: 'ご不明な点がございましたら、下のボタンをクリックしてお問い合わせください。日本担当から順次ご連絡いたします。',
    NAME: '名前',
    COUNTRY: '国',
    REQUEST_TYPE: 'お問い合わせ内容',
    REQUEST: '詳細',
    SEND_REQUEST: '送信',
    NEED_HELP: "助けが必要？",
    NEED_HELP_DESCRIPTION: "弊社のカスタマーサービスチームは、発生する可能性のある問題について質問がある場合にお手伝いします。よくある質問のセクションにアクセスして、直接お問い合わせください。",
    SITE_TERMS: "サイト規約",
    PRIVACY_POLICY: "個人情報保護方針",
    PRIVACY_READ: "<link>プライバシーポリシー</link>を確認しました。:", 
    PRIVACY_URL: "https://www.videndummediasolutions.com/en/privacy-policy/",
    COOKIES_POLICY: "クッキーポリシー",
    COOKIES_URL: "https://www.videndummediasolutions.com/en/cookie-policy/",
    MENU: "メニュー",
    CUSTOMER_EXPERIENCE: "Videndum Media Solutionsカスタマーエクスペリエンス",
    WELCOME_MESSAGE: "オンライン製品登録プラットフォームへようこそ",
    HOMEPAGE_MESSAGE: "こちらののサイトにて、 <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>National Geographic</strong> および <strong>Avenger</strong> 製品を登録してください。",
    DISCOVER: "下記より提供サービスの詳細内容をご確認ください。",
    REGISTER_PRODUCT: "製品を登録する",
    ACCOUNT_TITLE: 'マイページ',
    ACCOUNT_SUBTITLE: '基本情報の変更はこちら',
    MYPROFILE: 'プロフィール',
    CHANGE_PASSWORD: 'パスワード変更',
    CHANGE_EMAIL: "メールアドレスを変更する",
    ADDRESS: '住所',
    CITY: '市区町村',
    COUNTRY_REGION: '国',
    ZIPCODE: '郵便番号',
    STATE: '都道府県',
    PHONE: '電話番号',
    SUBSCRIBE_TO_NEWSLETTER: "<link>プライバシーポリシー</link>に同意 し てメルマガを購読します。",
    PROFILING: "当ウェブサイトからの購入商品および閲覧に基づき、メルマガやお知らせを受け取ることを同意します。",
    SAVE: '保存',
    SAVING: 'データの保存',
    FIELD_REQUIRED: "{field}は必須です",
    NEWEMAIL: "新しいメールアドレス",
    NEWEMAIL_CONFIRMATION: "確認",
    PASSWORD: "パスワード",
    OLDPASSWORD: "旧パスワード",
    NEWPASSWORD: "新しいパスワード",
    NEWPASSWORD_CONFIRMATION: "新しいパスワード（確認）",
    RECEIPT_UPLOADED: "領収書をアップロードしました",
    RECEIPT_NOT_UPLOADED: "領収書がアップロードされていません",
    CHOOSE_COUNTRY: "国を選択します",
    CHOOSE_BRAND: "ブランドを選択",
    COMPLETE_ACCOUNT_TITLE: "アカウントは完了していません",
    COMPLETE_ACCOUNT_SUBTITLE: "製品登録を続行するには、アカウントを完了してください",
    BOUGHT_ONLINE: "オンラインで購入しました",
    BOUGHT_IN_STORE: "販売店で購入しました",
    THANK_YOU: "ありがとうございました",
    FOR_REGISTERING: "製品登録するための{名} {姓} ",
    PRODUCT_REGISTERED: "この製品 {sku} の保証は {months} ヶ月延長されました。",
    WARRANTY_EXTENSION: "ヶ月 保証",
    EXTENSION: "延長",
    NEW_EXPIRATION: "新しい有効期限",
    PURCHASED_FROM: "購入国",
    TERMS_AND_CONDITIONS: "利用規約をすべて表示する",
    DOWNLOAD_WARRANTY: "保証書をダウンロードするには、ここをクリックしてください",
    REGISTER_ANOTHER_PRODUCT: "別の製品を登録する",
    GOTO_PRODUCT_LIST: "製品リストに移動する",
    FORGOT_PASSWORD: 'パスワードをお忘れですか',
    NEW_ACCOUNT: "新しいアカウント",
    WELCOME_USER: 'ようこそ {name} 様',
    EDIT_ACCOUNT: "アカウントの編集",
    LOGIN_TO_WARRANTY: "保証にサインインします",
    WELCOME_USER_SUBTITLE: "ようこそユーザーサブタイトルへ",
    CHANGE_PURCHASEDATE_RECEIPT: "購入日を変更するか、別の領収書をアップロードしてください",
    PURCHASE_DATE: "購入日",
    REGISTER_PRODUCT_TO_EXTEND: "製品を登録し、保証を延長します",
    INSERT_PRODUCT_CODE: "保証登録を開始するには、以下に製品コードを入力してください",
    SAVE_AND_PROCEED: "保存して次へ",
    DOWNLOAD_RECEIPT: "領収書をダウンロード",
    SERIAL_NUMBER: "シリアルナンバー",
    STORE: "ストア",
    RECEIPT: "領収書",
    REGISTERED_ON: "ご登録日",
    PURCHASED_ON: "ご購入日",
    YOUR_PRODUCT_LIST: "製品リスト",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "登録したすべての製品が表示され、以下から変更可能です。.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "新製品のご登録ができます。",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "注：購入したことを証明するために、必ず領収書等は保管してください。",
    ADD_PURCHASEDATE_AND_RECEIPT: "購入日と領収書を追加する",
    PLEASE_UPLOAD_RECEIPT: "保証を請求するために、領収書をアップロードしてください",
    UPLOAD_RECEIPT: "領収書をアップロードする",
    UPLOADING: "アップロード",
    CHOOSE_COUNTRY_AND_STORE: "購入した国と販売店をお選びください",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "製品を購入した店舗名と国を入力してください。",
    NO_OFFICIAL_STORE_FOUND: "店舗名が見つかりません。",
    PROCEED_ANYWAY: "店舗名を入力して続行します。",
    EXTENSION_FOUND: "{months} ヶ月延長保証",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "こちらに製品コードもしくはEANコードを入力してください",
    DISCOVER_WARRANTY: "この製品に適用される保証を確認する",
    LOCATE_PRODUCT_CODE: "製品コード",
    LOCATE_PRODUCT_CODE_CLICK: "製品コードの検索については、こちらをクリックしてください",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "製品コードはパッケージに表記されています。",
    LOCATING_EAN_UPC: "EANコード",
    LOCATING_EAN_UPC_DESCRIPTION: "EANコードは商品パッケージに表記されています。 13桁のバーコードをサーチバーにご入力ください。",
    INSERT_PRODUCT_QR_CODE: "QRコードを入力してください",
    LOCATE_PRODUCT_QR_CODE: "製品コードは商品パッケージに表記されています。 QRコードの下にあるアルファベットと数字の12桁の番号をご入力ください。 QRコードが確認できず、あるいは損害の場合、カスタマーサービスまでご連絡ください。 Manfrotto.jp下欄のFAQ（よくある質問）も合わせてご参照ください。",
    PRODUCT_ELIGIBLE_FOR: "対象となる製品",
    NO_REGISTERED_PRODUCTS: "登録済みの製品はありません",
    SUPPORTS: "サポート製品",
    CAMERA_ACCESSORIES: "カメラアクセサリー",
    AUDIO_CAPTURE: "レコーディングシステム",
    BAGS: "バッグ",
    MOBILE_POWER: "モバイルパワー",
    VIDEO_TRANSMISSION_SYSTEMS: "映像伝送システム",
    PROMPTERS: "プロンプター",
    MOTION_CONTROL: "モーションコントロール",
    DISTRIBUTION_RENTAL_SERVICES: "流通・レンタル・サービス",
    LIGHTING_AND_CONTROLS: "ライティング・ライティングコントロール",
    ROBOTIC_CAMERA_SYSTEMS: "ロボティックカメラシステム",
    MONITORS: "モニタ",
    OUR_BRANDS: "取り扱いブランド",
    DEALER_MANDATORY: "ストアが選択されていません", 
    REGISTER_ACCOUNT_TITLE: "新しいアカウントを登録してください。",
    PASSWORD_CONFIRMATION: "パスワードを確認してください。",
    SEND_CODE: 'コードを送信',
    CODE: 'コード',
    RESET_PASSWORD: 'パスワードをリセット',
    RESET_PASSWORD_SUBTITLE: 'メールに送信したコードと新しいパスワードを設定してください。',
    RESET_PASSWORD_NEEDED: "続行するにはパスワードをリセットする必要があります。",
    SIGNUP_CONFIRM: 'サインアップを確認してください。',
    NOT_REGISTERED: "登録されていませんか？",
    NOT_REGISTERED_SUBTITLE: "下のボタンをクリックして、新しいアカウントを作成してください。",
    SIGNUP_CONFIRMED: "アカウントが確認されました。ログインボタンをクリックしてログインしてください。",
    CONFIRM: "確認",
    LOGIN_WITH_CREDENTIALS: "Login with Videndum Warranty account",
    LOGIN_WITH_SOCIAL: "ソーシャルアカウントでログイン",
    UNLIMITED_WARRANTY: "無制限の保証", 
    VERIFICATION_CODE_NOT_ARRIVED: "確認コードが届きませんか？",
    CLICK_ON_BUTTON: "下のボタンをクリックして、確認コードを再送信してください。",
    SEND: "送信",
    PLEASE_CHOOSE_BRAND: "あなたが好きなブランドを少なくとも1つ選んでください。",
    MANAGE_SUBSCRIPTIONS: "サブスクリプションを管理するには、{link}",
    COOKIE_CONSENT: "この動画を視聴するには、{link}を受け入れてください",
    ACCEPT_STATISTICS_COOKIES: "「統計 Cookie」",
    HERE: "ここ",
    QRCODE_NOT_VALID: "QRコードが無効です", 
    DOWNLOAD_AREA: "ダウンロードエリア", 
    DOWNLOADABLE_CONTENTS: "Downloadable contents", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Digital Background Library", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Users may choose to download all categories to your device at once, or revisit this page in the future by simply logging into this portal with your SavageUniversal.com account credentials.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Savage Green Screen Wizard Lite Users", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Please use the button below to register your unique serial number included in your product package to gain access to the library.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Here you can find your downloadable contents.", 
    ADD_SERIAL_NUMBER: "Add serial number", 
    CANCEL: "Cancel", 
    DOWNLOAD: "Download", 
    YOUR_SERIAL_NUMBER: "Your serial number: {serialNumber}", 

    INFO_DATA_SAVED: "保存されたデータ",
    INFO_REQUEST_SENT: "リクエストを送信しました",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "パスワードを変更できるのは、Manfrottoユーザー以外のみです。",
    INFO_USER_ACCOUNT_SAVED: "保存されたユーザーアカウント",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "メールを変更してもよろしいですか？",
    INFO_CONFIRM_REGISTRATION: "この製品の保証をとうろくしてもよろしいですか?",
    INFO_CONFIRM_SERIAL_NUMBER: "Are you sure that you want to register this serial number?",
    INFO_SERIAL_NUMBER_REGISTERED: "Serial number registered.",

    ERR_USER_ALREADY_EXIST: "ユーザーは既に存在します。",
    ERR_CHECK_PRIVACY: "プライバシーチェックボックスにチェックをしてください。",
    ERR_USER_NOT_FOUND: "ユーザーが見つかりません",
    ERR_PASSWORD_REQUIRED: "パスワードが必要",
    ERR_PASSWORD_MISMATCH: "パスワードが一致しません",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "パスワードを変更できません",
    ERR_OLDPASSWORD_REQUIRED: "古いパスワードが必要です",
    ERR_NEWPASSWORD_REQUIRED: "新しいパスワードが必要です",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "新しいパスワードの確認が必要です",
    ERR_CAPTCHA_ISREQUIRED: "キャプチャを解決してください",
    ERR_NEWEMAIL_REQUIRED: "新しいメールは無効です",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "新しいメールの確認は無効です",
    ERR_PASSWORD_NOT_VALID: "パスワードが無効です",
    ERR_EMAIL_DONOT_MATCH: "メールが一致しません",
    ERR_PRODUCT_NOT_FOUND: "製品が見つかりません",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "ユーザーはパスワード変更をできません。",
    ERR_RESET_PASSWORD_FAILED: "パスワードリセットに失敗しました。",
    ERR_UNABLE_TO_UPDATE_USER: "ユーザー情報を更新できません。",
    ERR_BRANDS_MANDATORY: "少なくとも1つのブランドを指定する必要があります。",
    ERR_INVALID_SERIAL_NUMBER: "Please specify valid serial number.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Serial number not found.",

    AVAILABLE_ONLY_ON: "のみ使用可能",
    GITZO_LEGENDE: "GITZO Légende", 
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "GITZO Légende, Join the campaign on Indiegogo",
    LEGENDE_TRIPOD_AND_BACKPACK: "Légende Tripod and Backpack",
    LEGENDE_UNLIMITED_WARRANTY: "Unlimited warranty",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "repair, or replace for endless adventure.",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "All Légende Tripods and Backpacks ordered from Indiegogo come with an unlimited warranty, under normal use. If you discover defects in material or workmanship, we will repair it, send a new part, or replace it, free of charge.",
    LEGENDE_READ_TC: "Read T&C",
    LEGENDE_TRIPOD: "Légende Tripod",
    LEGENDE_TRIPOD_SUB: "The best tripod we could make and forever repairable.",
    LEGENDE_100_PARTS_REPLACEABLE: "100% parts replaceable forever repairable",
    LEGENDE_70_REPLACEABLE: "70% of parts user replaceable",
    LEGENDE_SELF_SERVICEABLE: "Self serviceable",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "Self-serviceable, for optimum performance throughout its entire life.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Simple maintenance steps help keep the Légende in perfect shape no matter where you take it.",
    LEGENDE_BACKPACK: "Légende Backpack",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Expertly designed for the travel photographer, crafted for sustainability and the future.",
    LEGENDE_65_RECYCLED: "65% recycled fabrics",
    LEGENDE_ECOFRIENDLY_LEATHER: "Eco- friendly leather",
    LEGENDE_HOWTO_CARE_BACKPACK: "How to care for your Légende backpack.",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Follow these basic steps to care for your Légende Backpack.",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/jp-ja/customer-service/warranty-terms-and-conditions/"

}
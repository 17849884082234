import { useContext } from 'react';
import 'css/page/register_warranty.scss';
import { ProductSearch, LocateStore, DateAndReceipt, QrCode, RegistrationCompleted } from './components';
import { newRegistrationContext, Step } from './NewRegistrationContext';


var NewRegistration = () => {
    let service = useContext(newRegistrationContext);

    return (
        <div id="regw">
            {service.currentStep === Step.SearchProduct && <ProductSearch />}
            {service.currentStep === Step.DateAndReceipt && <DateAndReceipt />}
            {service.currentStep === Step.QrCode && <QrCode />}
            {service.currentStep === Step.LocateStore && <LocateStore />}
            {service.currentStep === Step.Success && <RegistrationCompleted />}
        </div>
    );
}

export default NewRegistration;
import DownloadAreaProvider from './DownloadAreaContext';
import DownloadArea from './DownloadArea';

let Index = () => {
    return (
        <DownloadAreaProvider>
            <DownloadArea />
        </DownloadAreaProvider>
    )
}

export default Index;
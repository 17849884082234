import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import axios from 'axios';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { applicationContext } from 'helpers/services';
import { toast } from 'react-toastify';


interface INewRegistrationContext {
    initialState: IRegistration
    registration: IRegistration
    isSaving: boolean
    isLoading: boolean
    currentStep: Step

    updateRegistration: (updatedReg: IRegistration) => void
    saveRegistration: () => Promise<void>
}

export interface IRegistration {
    qrCode: string
    warrantyExtensionMonths: number
    warrantyUpToMonths: number
    warrantyExpirationDate: moment.Moment
    unlimited: boolean
    sku: string
    productBrand: string
    shortDescription: string
    isQrCodeMandatory: boolean
    dealerId: string
    dealerName: string
    dealerCountryCode: string
    purchaseDate: Date
    publicUserGuid: string
    receiptId: string
    receiptFilename: string
    purchaseType: 'online' | 'retail'
    receiptDownloadUrl: string
    dealerAddress: string
}

export enum Step {
    SearchProduct = "searchproduct",
    WarrantyAvailable = "warrantyavailable",
    DateAndReceipt = "dateandreceipt",
    QrCode = "qrcode",
    LocateStore = "locatestore",
    Success = "success",
}

export let newRegistrationContext = React.createContext({} as INewRegistrationContext);

let { Provider } = newRegistrationContext;

let NewRegistrationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let history = useHistory();
    let { formatMessage } = useIntl();
    let { step } = useParams<{ step: Step }>();

    let appService = useContext(applicationContext);

    let initialState: IRegistration = {
        dealerCountryCode: appService.currentCountry !== 'XX' ? appService.currentCountry : '',
        dealerId: '',
        dealerName: '',
        isQrCodeMandatory: false,
        publicUserGuid: '',
        purchaseDate: new Date(),
        warrantyExpirationDate: moment(),
        unlimited: false, 
        purchaseType: 'retail',
        qrCode: '',
        productBrand: '',
        receiptId: '',
        receiptFilename: '',
        shortDescription: '',
        sku: '',
        receiptDownloadUrl: '',
        dealerAddress: '',
        warrantyExtensionMonths: 0,
        warrantyUpToMonths: 0
    };

    let [isSaving, setSaving] = useState(false);
    let [isLoading, setLoading] = useState(true);
    let [registration, setRegistration] = useState<IRegistration>(initialState);


    let getState = () => {
        return localStorage.getItem("warranty.registration");
    }

    let clearState = () => {
        let state = getState();
        if (state) {
            localStorage.removeItem("warranty.registration");
        }
    }

    let loadState = () => {
        let state = getState();
        if (state) {
            let reg: IRegistration = JSON.parse(state);

            if (appService.currentCountry !== 'XX') {
                reg = {
                    ...reg,
                    dealerCountryCode: appService.currentCountry
                };
            }

            setRegistration({
                ...registration,
                ...reg,
                purchaseDate: reg.purchaseDate ? new Date(reg.purchaseDate) : new Date()
            })
        }
    }

    useEffect(() => {
        const unblock = history.block((location, action): any => {
            // Se sto uscendo dalla registrazione oppure se sto iniziando pulisco lo state
            if (location.pathname.includes('/newregistration') === false || location.pathname === '/newregistration/searchproduct') {
                setRegistration(initialState);
                clearState();
            }

            return true;
        });

        return () => {
            unblock();
        };
    }, []);


    useEffect(() => {
        let currentState = getState();
        if (currentState) {
            loadState();
        } else {
            history.push('/newregistration/searchproduct');
        }

        setLoading(false);
    }, [appService.currentCountry])

    if (!step) {
        return <Redirect to={`/newregistration/searchproduct`} />;
    }

    let updateRegistration = (updatedReg: IRegistration) => {
        saveState(updatedReg);
        setRegistration(updatedReg);
    }

    let saveRegistration = async () => {
        if (validateRegistration() === false)
            return;

        if (window.confirm(formatMessage({ id: 'INFO_CONFIRM_REGISTRATION' }))) {
            try {
                setSaving(true);
                let res = await axios.post('/api/Product/SaveProduct', registration);

                clearState();

                setRegistration({
                    ...registration,
                    warrantyExpirationDate: res.data.warrantyExpirationDate,
                    // warrantyTitle: res.data.warrantyTitle,
                    // warrantyDescription: res.data.warrantyDescription,
                    // warrantyStatus: res.data.warrantyStatus,
                    dealerAddress: res.data.dealerAddress,
                    receiptDownloadUrl: res.data.receiptDownloadUrl
                })

                history.push('/newregistration/success');

            } catch (err) {
            } finally {
                setSaving(false);
            }
        }
    }

    let saveState = (reg: IRegistration) => {
        localStorage.setItem('warranty.registration', JSON.stringify(reg));
    }

    let validateRegistration = () => {
        if (registration.dealerId.trim() === '' && registration.dealerName.trim() === '') {
            toast.error(formatMessage({ id: 'DEALER_MANDATORY' }));
            return false;
        }

        return true;
    }


    return (
        <Provider value={{
            initialState,
            isLoading,
            registration,
            isSaving,
            currentStep: step,

            updateRegistration,
            saveRegistration
        }}>
            {children}
        </Provider>
    );
}

export default NewRegistrationProvider;
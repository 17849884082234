import React, { useContext, useRef } from 'react';
import axios from 'axios';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';
import { FormattedMessage } from 'react-intl';


interface IUploadReceiptProps {
    isUploading: boolean
    setUploading: React.Dispatch<React.SetStateAction<boolean>>
}

let UploadReceipt = ({ isUploading, setUploading }: IUploadReceiptProps) => {
    let service = useContext(newRegistrationContext);
    let fileInput = useRef<any>();

    let upload = async (e: any) => {
        try {
            setUploading(true);
            let formData = new FormData();
            formData.append('file', e.currentTarget.files[0]);

            let res = await axios.post('/api/Product/UploadReceipt', formData);
            if (res.data) {

                service.updateRegistration({
                    ...service.registration,
                    receiptId: res.data.id,
                    receiptFilename: fileInput.current.files[0].name || ''
                });
            }
        } catch (err) { }
        finally {
            setUploading(false);
        }
    }


    return (
        <div className="col-12 col-lg-6 pt-3 pt-lg-0">
            <div className="regw__form text-left">
                <span className="regw__form__input">
                    <input
                        type="file"
                        id="uploadReceipt"
                        placeholder="Upload recepit"
                        onChange={upload}
                        ref={fileInput}
                    />

                    {(!service.registration.receiptId && !isUploading) &&
                        <>
                            <img src="/images/icons/file-upload.svg" alt="" />
                            <label className="over-container" htmlFor="uploadReceipt">
                                <FormattedMessage id="UPLOAD_RECEIPT" />
                            </label>
                        </>
                    }
                    {isUploading &&
                        <>
                            <img src="/images/icons/spinner-third-light.svg" alt="" />
                            <label className="over-container" htmlFor="uploadReceipt">
                                <FormattedMessage id="UPLOADING" />
                            </label>
                        </>
                    }
                    {(isUploading === false && service.registration.receiptId) &&
                        <>
                            <img src="/images/icons/check.svg" alt="" />
                            <label className="over-container loaded" htmlFor="uploadReceipt">
                                {service.registration.receiptFilename}
                            </label>
                        </>
                    }
                </span>
            </div>
        </div>
    )
}

export default UploadReceipt;
import { authContext } from 'helpers/services';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

var Menu = () => {
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);


    return (
        <div className="footer-menu__container">
            <div className="footer-menu container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <header>
                            <h4>
                                <FormattedMessage id="NEED_HELP" />
                            </h4>
                        </header>
                        <p>
                            <FormattedMessage id="NEED_HELP_DESCRIPTION" /> <Link to="/support"><FormattedMessage id="SUPPORT" /></Link>
                        </p>
                        <header className="pt-3">
                            <h4><FormattedMessage id="SITE_TERMS" /></h4>
                        </header>
                        <ul>
                            <li>
                                <a rel="noopener noreferrer" target="_blank" href={formatMessage({ id: 'PRIVACY_URL' })} title="Privacy Policy">
                                    <FormattedMessage id="PRIVACY_POLICY" />
                                </a>
                            </li>
                            <li>
                                <Link to='/site-terms/cookies-policy' title={formatMessage({ id: 'COOKIES_POLICY' })}>
                                    <FormattedMessage id="COOKIES_POLICY" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="frame">
                            <header>
                                <h4><FormattedMessage id="MENU" /></h4>
                            </header>
                            <ul>
                                <li>
                                    <Link to={authService.isAuthenticated === true ? "/newregistration" : "/product/search"}>
                                        <FormattedMessage id="REGISTER_WARRANTY" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/myproducts">
                                        <FormattedMessage id="MY_PRODUCTS" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        <FormattedMessage id="FAQ" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/support">
                                        <FormattedMessage id="SUPPORT" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-6 col-12 order-md-2">
                        <div className="frame">
                            <div className="h4">Videndum Media Solutions</div>
                            <ul className="footer-brands">
                                <li className="logo-mnf-lg-w"><a rel="noopener noreferrer" href="https://www.manfrotto.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-jby-lg-w"><a rel="noopener noreferrer" href="https://joby.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-lwp-lg-w"><a rel="noopener noreferrer" href="https://www.lowepro.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-gtz-lg-w"><a rel="noopener noreferrer" href="https://www.gitzo.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-bsy-lg-w"><a rel="noopener noreferrer" href="https://syrp.co/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-ryc-lg-w"><a rel="noopener noreferrer" href="https://rycote.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-ave-lg-w"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/avenger-grip/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-col-lg-w"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/colorama/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-ng-lg-w"><a rel="noopener noreferrer" href="https://www.manfrotto.com/global/national-geographic/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-sav-lg-w"><a rel="noopener noreferrer" href="https://savageuniversal.com/" target="_blank" className="logo d-block"><span></span></a></li>
                                <li className="logo-aud-lg-w"><a rel="noopener noreferrer" href="https://audixusa.com/" target="_blank" className="logo d-block"><span></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
import SupportProvider from './SupportContext';
import Support from './Support';

let Index = () => {
    return (
        <SupportProvider>
            <Support />
        </SupportProvider>
    )
}

export default Index;
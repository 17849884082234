import { useIntl } from "react-intl";

let DownloadAreaOverview = () => {
    let { formatMessage } = useIntl();

    return (
        <div className="bg-light">
            <div className="container-fluid">
                <div className="pt-4 pb-4 pl-4">
                    <div className="row">
                        <div className="col">
                            <h1>{formatMessage({ id: "DOWNLOADABLE_CONTENTS" })}</h1>
                        </div>
                    </div>
                    <br />
                    <div className="row pb-4">
                        <div className="col-10">
                            <h4>{formatMessage({ id: "DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE" })}</h4>
                            <p className="card-text">
                                {formatMessage({ id: "DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE" })}
                                <br />
                                {formatMessage({ id: "DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1" })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadAreaOverview;
import { useContext } from "react";
import { useIntl } from "react-intl";
import { downloadAreaContext } from "../DownloadAreaContext";
import NewSerialNumber from "./NewSerialNumber";

let Header = () => {
    let { formatMessage } = useIntl();

    let { media, serialNumber } = useContext(downloadAreaContext);

    return (
        <div className="pt-4 pb-4 pl-4">
            <div className="row">
                <div className="col">
                    <h1>{formatMessage({ id: "DOWNLOADABLE_CONTENTS" })}</h1>
                </div>
            </div>
            <br />
            <div className="row pb-4">
                <div className="col-10">
                    <h4>{formatMessage({ id: "DOWNLOADABLE_CONTENTS_TITLE" })}</h4>
                    <p className="card-text">
                        {media.length === 0 &&
                            <>
                                {formatMessage({ id: "DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL" })}<br />
                                {formatMessage({ id: "DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL" })}
                            </>
                        }
                        {media.length > 0 &&
                            <>
                                {formatMessage({ id: "DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL" })}<br />
                                <small>{formatMessage({ id: "YOUR_SERIAL_NUMBER" }, { "serialNumber": serialNumber })}</small>
                            </>
                        }
                    </p>
                    {media.length === 0 &&
                        <div className="regw__proceed">
                            <NewSerialNumber />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header;
import Menu from './Menu';
import moment from 'moment';
import VitecSection from './VitecSection';

let Footer = () => {
    return (
        <footer>
            <Menu />

            <div className="align-items-center copyright__container container-fluid">
                <div className="row">
                    <div className="pl-2 pr-3">
                        <div className="logo logo-mnf-lg-w">
                            <a rel="noopener noreferrer" href="https://www.manfrotto.com/us-en/"><span></span></a>
                        </div>
                    </div>
                    <div className="col-md-7 col-12 align-items-center">
                        <div className="copyright">
                            <div className="copyright__text">
                                <p>© 1996-{moment().year()} Videndum Media Solutions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <VitecSection />
        </footer>
    );
}

export default Footer;
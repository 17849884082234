import React, { useContext } from 'react';
import ProductInfo from '../ProductInfo';
import Progress from '../Progress';
import QrCodeInfo from './QrCodeInfo';
import ReceiptInfo from './ReceiptInfo';
import SearchStore from './SearchStore';
import { newRegistrationContext } from '../../NewRegistrationContext';
import { Spinner } from 'components';
import { FormattedMessage } from 'react-intl';


let LocateStore = () => {
    let service = useContext(newRegistrationContext);

    return (
        <div className="text-center container pb-5" >
            <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>
            <p><FormattedMessage id="INSERT_PRODUCT_CODE" /></p>

            {service.isSaving && <Spinner />}

            <Progress />
            <ProductInfo />

            <hr className="mt-2 mb-2" />

            <ReceiptInfo />

            <hr className="mt-2 mb-2" />

            {service.registration.isQrCodeMandatory === true &&
                <QrCodeInfo qrCode={service.registration.qrCode} />
            }

            <SearchStore />

            <div className="regw__proceed">
                <button
                    type="button"
                    className="btn btn-success mx-auto"
                    onClick={service.saveRegistration}
                >
                    <FormattedMessage id="SAVE_AND_PROCEED" />
                </button>
            </div>
        </div>
    )
}

export default LocateStore;
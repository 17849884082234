import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { toast } from 'react-toastify';


interface IUserAccountContext {
    isLoading: boolean
    isSaving: boolean
    user: any
    userSchema: any

    saveUser: (user: any) => Promise<void>
}

export let userAccountContext = React.createContext({} as IUserAccountContext);

let { Provider } = userAccountContext;

let UserAccountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { formatMessage } = useIntl();

    let userSchema = yup.object({
        userId: yup.string().nullable().default(''),
        firstName: yup.string().nullable().default('').required(formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'FIRSTNAME' }) })),
        lastName: yup.string().nullable().default('').required(formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'LASTNAME' }) })),
        address: yup.string().nullable().default(''),
        city: yup.string().nullable().default(''),
        countryCode: yup.string().nullable().default('').required(formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'COUNTRY_REGION' }) })),
        zipCode: yup.string().nullable().default(''),
        state: yup.string().nullable().default(''),
        phoneNumber: yup.string().nullable().default(''),
        preferencePageUrl: yup.string().nullable().default('')
    });

    type User = yup.InferType<typeof userSchema>;

    let [isLoading, setLoading] = useState(true);
    let [isSaving, setSaving] = useState(false);
    let [user, setUser] = useState<User>(userSchema.getDefault());


    useEffect(() => {
        getUserDetails();
    }, [])


    let getUserDetails = async () => {
        try {
            setLoading(true);
            let res = await axios.get('/api/User/GetUser');
            setUser(res.data);
        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let saveUser = async (value: User) => {
        try {
            setSaving(true);
            await axios.post('/api/User/SaveUserProfile', value);
            toast.success(formatMessage({ id: 'INFO_DATA_SAVED' }));
        } catch (Err) {
        } finally {
            setSaving(false);
        }
    }

    return (
        <Provider
            value={{
                isLoading,
                isSaving,
                user,
                userSchema,

                saveUser
            }}
        >
            {children}
        </Provider>
    )
}

export default UserAccountProvider;
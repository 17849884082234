import { useContext } from 'react';
import { Media } from 'components';
import { useHistory } from 'react-router';
import { newRegistrationContext } from '../NewRegistrationContext';
import { FormattedMessage } from 'react-intl';


let ProductInfo = () => {
    let history = useHistory();

    let service = useContext(newRegistrationContext);


    let changeProduct = () => {
        service.updateRegistration(service.initialState);
        history.push('/newregistration/searchproduct');
    }


    return (
        <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-md-auto col-lg-3 order-1">
                <span className={"logo-" + service.registration.productBrand.toLowerCase() + "-lg-c logo-over-img"}>
                    <a><span></span></a>
                </span>
                <Media
                    searchBy="byProduct"
                    mediaKey={service.registration.sku}
                    height={150}
                    width={250}
                    applyClasses="d-inline-block mt-5"
                />
            </div>
            <div className="col-12 col-md order-3 order-md-2 text-left">
                <small>{service.registration.sku}</small>
                <h5 className="mb-0">{service.registration.shortDescription}</h5>
                <strong className="text-success">
                    {service.registration.unlimited && <FormattedMessage id="UNLIMITED_WARRANTY" />}
                    {service.registration.unlimited === false && <FormattedMessage id="EXTENSION_FOUND" values={{ months: service.registration.warrantyUpToMonths }} />}
                </strong>
                <br />
                <br />
                <button type="button" className="btn btn-link btn-sm" onClick={changeProduct}>
                    <img src="/images/icons/pencil.svg" alt="" height="15px" /> <u><FormattedMessage id="CHANGE_PRODUCT" /></u>
                </button>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 mx-auto order-1 order-md-4">
                <div className="row regw__blockyears pt-2 pb-2">
                    <div className="col active">
                        <button>
                            {service.registration.unlimited && <FormattedMessage id="UNLIMITED_WARRANTY" />}
                            {service.registration.unlimited === false &&
                                <strong>
                                    <span>{service.registration.warrantyUpToMonths}</span>
                                    <FormattedMessage id="WARRANTY_EXTENSION" />
                                </strong>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductInfo;
import { useContext } from 'react';
import { Switch, Redirect, Route } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import Localizations from 'helpers/localization';
import { applicationContext } from 'helpers/services';
import { Layout } from 'components';
import PrivateRoute from './PrivateRoute';
import {
    Login,
    ChangePassword,
    ForgotPassword,
    CompleteAccount,
    ResetPassword, 
    SignUp, 
    SignUpConfirm,
    SignUpConfirmed
} from 'pages/Account';
import {
    Faq,
    Home,
    MyProducts,
    NewRegistration,
    Support,
    GrayMarket,
    UserAccount,
    ProductSearch,
    CookiesPolicy,
    DownloadArea,
    DownloadAreaOverview
} from 'pages';


let Routes = () => {
    let appService = useContext(applicationContext);

    return (
        <IntlProvider locale="en" messages={Localizations[appService.currentCountry]}>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/product/:step?" component={ProductSearch} />
                    <Route exact path="/graymarket" component={GrayMarket} />
                
                    <Route exact path="/support" component={Support} />
                    <Route exact path="/site-terms/cookies-policy" component={CookiesPolicy} />

                    <PrivateRoute exact path="/newregistration/:step?" component={NewRegistration} />
                    <PrivateRoute exact path="/myproducts" component={MyProducts} />
                    <PrivateRoute exact path="/account" component={UserAccount} />
                    <PrivateRoute exact path="/download-area" component={DownloadArea} />
                    <Route exact path="/download-area-overview" component={DownloadAreaOverview} />
                    
                    <Route exact path="/account/complete" component={CompleteAccount} />
                    <Route exact path="/account/changepassword" component={ChangePassword} />
                    <Route exact path="/account/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/account/resetpassword" component={ResetPassword} />
                    <Route exact path="/account/login" component={Login} />
                    <Route exact path="/account/signup" component={SignUp} />
                    <Route exact path="/account/signupconfirm" component={SignUpConfirm} />
                    <Route exact path="/account/signupconfirmed" component={SignUpConfirmed} />

                    <Redirect to="/" />
                </Switch>
            </Layout>
        </IntlProvider>
    );
}

export default Routes;
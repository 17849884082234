import { FormattedMessage } from 'react-intl';
import { MyProfile } from './components';


let UserAccount = () => {
    return (
        <div className="main-desclaimer pt-5 pb-5">
            <div className="container text-center">
                <h1 className="hero-title">
                    <FormattedMessage id="ACCOUNT_TITLE" />
                </h1>
                <p className="lead">
                    <FormattedMessage id="ACCOUNT_SUBTITLE" />
                </p>
                <hr className="mt-4" />
            </div>
            <div className="container pb-5">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-8 mx-auto">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <button
                                    className="mr-1 btn btn-link nav-link active"
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    <FormattedMessage id="MYPROFILE" />
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" role="tabpanel">
                                <MyProfile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserAccount;
import React from 'react';
import NewRegistrationProvider from './NewRegistrationContext';
import NewRegistration from './NewRegistration';


let Index = () => {
    return (
        <NewRegistrationProvider>
            <NewRegistration />
        </NewRegistrationProvider>
    )
}

export default Index;
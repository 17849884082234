import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import 'css/main.scss';
import 'css/sites_cookiebot.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { Routes } from 'components';

import { ApplicationProvider, AuthenticationManager, AxiosProvider } from 'helpers/services';

let App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        autoClose={4000}
        draggable={false}
        limit={20}
        hideProgressBar={true}
        position="bottom-left"
        closeButton={false}
      />
      <ApplicationProvider>
        <AuthenticationManager>
          <AxiosProvider /> 
          <Routes />
        </AuthenticationManager>
      </ApplicationProvider>
    </BrowserRouter>
  );
}

export default App;
import { AU } from './AU';
import { IT } from './IT';
import { XX } from './XX';
import { DE } from './DE';
import { GB } from './GB';
import { US } from './US';
import { FR } from './FR';
import { JP } from './JP';
import { CN } from './CN';
import { HK } from './HK';
import { ES } from './ES';

let Localizations: any = {
    AU, 
    IT,
    XX,
    DE,
    GB,
    US,
    FR,
    JP,
    CN,
    HK,
    ES
}

export default Localizations;
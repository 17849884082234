import { useIntl, FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';


let SignUpConfirmed = () => {
    let { formatMessage } = useIntl();


    return (
        <div className="accountpanel">
            <div className="bg-white p-0 pt-5 pt-lg-0">
                <div className="container py-4 px-5">
                <div className="row">
                    <div className="col-12">
                        <h4>{formatMessage({ id: "SIGNUP_CONFIRMED" })}</h4>

                        <div className="pt-4 form-group">
                            <RouterLink to="/account/login">
                                <button className="btn btn-success btn-block">
                                    <FormattedMessage id="LOGIN" />
                                </button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SignUpConfirmed;
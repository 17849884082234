import { useContext, useState } from 'react';
import { applicationContext } from 'helpers/services';
import Autosuggest from 'react-autosuggest';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';
import { useIntl } from 'react-intl';
import _ from 'lodash';


let SearchStore = () => {
    let { formatMessage } = useIntl();

    let appService = useContext(applicationContext);
    let service = useContext(newRegistrationContext);

    let [isLoading, setLoading] = useState(false);
    let [searchText, setSearch] = useState('');
    let [suggestions, setSuggestions] = useState<any[]>([]);
    let [selected, setSelected] = useState(false);
    

    let loadSuggestions = async (value: string) => {
        setLoading(true);
        setSelected(false);

        try {
            let request = {
                name: value,
                city: '',
                state: '',
                countryCode: service.registration.dealerCountryCode,
                storeTypeSearch: service.registration.purchaseType
            };

            let res = await axios.get('/api/Dealer/GetDealer', { params: { ...request } });
            if (res.data && res.data.length > 0)
                setSuggestions(res.data);

        } catch (err) {
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    }

    let handleSearch = (event: any, { newValue }: any) => {
        setSearch(newValue);
        service.updateRegistration({
            ...service.registration,
            dealerName: newValue,
            dealerId: ''
        });
    };

    let onSuggestionsFetchRequested = _.debounce(({ value, reason }) => {
        if (reason === "input-changed")
            loadSuggestions(value);
    }, 500);

    let onSuggestionSelected = (event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any) => {
        service.updateRegistration({
            ...service.registration,
            dealerId: suggestion.id,
            dealerName: suggestion.name
        });
        setSelected(true);
    }

    let handleCountry = (e: any) => {
        service.updateRegistration({
            ...service.registration,
            dealerCountryCode: e.target.value
        });
        setSearch('');
        setSelected(false);
    }

    let handleStoreType = (e: any) => {
        service.updateRegistration({
            ...service.registration,
            purchaseType: e.target.value
        });
        setSearch('');
        setSelected(false);
    }

    let RenderIcon = () => {
        if (isLoading === true) {
            return <img src="/images/icons/spinner-third-light.svg" alt="" />;
        } else {
            if (selected === true) {
                return <img src="/images/icons/search.svg" alt="" />;
            } else {
                if (searchText.length > 0 && suggestions.length === 0)
                    return <img src="/images/icons/plus-circle-light.svg" alt="" />
                else
                    return <img src="/images/icons/search.svg" alt="" />
            }
        }
    }


    return (
        <div className="row justify-content-center pb-5">
            <div className="col-12 col-md-8 pt-5 pb-4">
                <h5 className="mb-0">
                    <FormattedMessage id="CHOOSE_COUNTRY_AND_STORE" />
                </h5>
                <p><FormattedMessage id="CHOOSE_COUNTRY_AND_STORE_DESCRIPTION" /></p>
            </div>
            <div className="col-12 col-lg-6">
                <div className="regw__form text-left">
                    <div className="regw__form__check"></div>
                    <span className="regw__form__input">
                        <img src="/images/icons/angle-down.svg" alt="" />
                        <select
                            className="form-control"
                            value={service.registration.dealerCountryCode}
                            onChange={handleCountry}
                        >
                            <option value=''>{formatMessage({ id: "CHOOSE_COUNTRY" })}</option>
                            {appService.countries.map(country =>
                                <option key={country.key} value={country.key}>{country.value}</option>
                            )}
                        </select>
                    </span>
                </div>
            </div>
            <div className="col-12 col-lg-6 pt-4 pt-lg-0">
                <div className="regw__form regw__form--stores text-left">
                    <div className="regw__form__check">
                        <div className={`form-check ${service.registration.purchaseType === 'retail' ? 'form-check--check' : ''}`}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="storeType"
                                id="retail"
                                onChange={handleStoreType}
                                value="retail"
                                checked={service.registration.purchaseType === "retail"}
                            />
                            <label className="form-check-label" htmlFor="retail">
                                <img src="/images/icons/bags-shopping-light.svg" alt="" />
                                <FormattedMessage id="BOUGHT_IN_STORE" />
                            </label>
                        </div>
                        <div className={`form-check ${service.registration.purchaseType === 'online' ? 'form-check--check' : ''}`}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="storeType"
                                id="online"
                                onChange={handleStoreType}
                                value="online"
                                checked={service.registration.purchaseType === "online"}
                            />
                            <label className="form-check-label" htmlFor="online">
                                <img src="/images/icons/globe-light.svg" alt="" />
                                <FormattedMessage id="BOUGHT_ONLINE" />
                            </label>
                        </div>
                    </div>
                    <span className="regw__form__input">
                        <RenderIcon />
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={() => setSuggestions([])}
                            getSuggestionValue={(suggestion: any) => suggestion.name}
                            renderSuggestion={(suggestion: any) => <span>{suggestion.name}</span>}
                            onSuggestionSelected={onSuggestionSelected}
                            inputProps={{
                                placeholder: "Store",
                                value: searchText,
                                onChange: handleSearch,
                                disabled: service.registration.dealerCountryCode === ''
                            }}
                        />
                    </span>
                    {(selected === false && isLoading === false && suggestions.length === 0 && searchText.length > 0) &&
                        <span className="react-autosuggest__no-suggestion">
                            <FormattedMessage id="NO_OFFICIAL_STORE_FOUND" />&nbsp;<u><FormattedMessage id="PROCEED_ANYWAY" /></u>
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchStore;
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Spinner } from 'components';
import { applicationContext } from 'helpers/services';
import { userAccountContext } from '../UserAccountContext';


let MyProfile = () => {
    let { formatMessage } = useIntl();

    let appService = useContext(applicationContext);
    let service = useContext(userAccountContext);


    return (
        <div className="mt-3">
            {service.isLoading && <Spinner />}
            {service.isLoading === false &&
                <Formik
                    enableReinitialize={true}
                    validationSchema={service.userSchema}
                    initialValues={service.user}
                    onSubmit={async (value, action) => {
                        await service.saveUser(value);
                        action.setSubmitting(false);
                    }}
                >
                    {form =>
                        <Form>
                            <div className="form-group row">
                                <div className="col">
                                    <label htmlFor="textinput">
                                        <FormattedMessage id="FIRSTNAME" /> *
                            </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                    />
                                    <ErrorMessage className="text-danger" name="firstName" component="span" />
                                </div>
                                <div className="col">
                                    <label htmlFor="textinput">
                                        <FormattedMessage id="LASTNAME" /> *
                                </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                    />
                                    <ErrorMessage className="text-danger" name="lastName" component="span" />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="textinput">
                                    <FormattedMessage id="ADDRESS" />
                                </label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="address"
                                />
                            </div>
                            <div className="form-group row">
                                <div className="col-3">
                                    <label htmlFor="textinput">
                                        <FormattedMessage id="ZIPCODE" />
                                    </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="zipCode"
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="textinput">
                                        <FormattedMessage id="CITY" />
                                    </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="city"
                                    />
                                </div>
                                <div className="col-3">
                                    <label htmlFor="textinput">
                                        <FormattedMessage id="STATE" />
                                    </label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        name="state"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="textinput">
                                    <FormattedMessage id="COUNTRY_REGION" /> *
                            </label>
                                <Field
                                    name="countryCode"
                                    component="select"
                                    disabled
                                    className="form-control"
                                >
                                    {appService.countries.map(country =>
                                        <option key={country.key} value={country.key}>{country.value}</option>
                                    )}
                                </Field>
                                <ErrorMessage className="text-danger" name="countryCode" component="span" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="textinput">
                                    <FormattedMessage id="PHONE" />
                                </label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="phoneNumber"
                                />
                            </div>
                            {form.values.preferencePageUrl &&
                                <div className="form-group">
                                    <FormattedMessage
                                        id="MANAGE_SUBSCRIPTIONS"
                                        values={{
                                            link: <a rel='noopener noreferrer' target='_blank' href={form.values.preferencePageUrl}><b>{formatMessage({ id: 'HERE' })}</b></a>
                                        }}
                                    />
                                </div>
                            }
                            {service.isSaving &&
                                <button className="btn btn-primary" type="button" disabled>
                                    <Spinner /><FormattedMessage id="SAVING" />...
                            </button>
                            }
                            {service.isSaving === false &&
                                <button type="submit" className="btn btn-primary">
                                    <FormattedMessage id="SAVE" />
                                </button>
                            }
                        </Form>
                    }
                </Formik>
            }
        </div>
    )
}

export default MyProfile;
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { applicationContext, authContext } from 'helpers/services';
import UserMenu from './UserMenu';
import NavLink from './NavLink';
import { FormattedMessage } from 'react-intl';


let MainNav = () => {
    let location = useLocation();

    let appService = useContext(applicationContext);
    let authService = useContext(authContext);

    let [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname])


    return (
        <div className="main-nav">
            <div className="col-md-12 d-flex align-items-center">
                <div className="logo pt-3">
                    <Link className="navbar-brand" to="/">
                        <img src="/images/Videndum_Media_Solutions_Logo.svg" alt="" />
                    </Link>
                </div>
                <div className={`main-menu__container ${showMenu ? 'active' : ''}`}>
                    <button
                        className="navbar-toggler d-block d-lg-none"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarsExampleDefault"
                        aria-controls="navbarsExampleDefault"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <span className="navbar-toggler-icon pt-1">
                            <img src="/images/icons/bars-solid.svg" alt="" width="20px" />
                        </span>
                    </button>
                    <nav className="collapse navbar-collapse" id="navbarsExampleDefault" role="navigation">
                        {authService.isLoading === false &&
                            <ul role="menubar" className="main-menu">
                                {authService.isAuthenticated === true &&
                                    <>
                                        <NavLink description={<FormattedMessage id="REGISTER_WARRANTY" />} url="/newregistration" />
                                        <NavLink description={<FormattedMessage id="MY_PRODUCTS" />} url="/myproducts" />
                                        <NavLink description={<FormattedMessage id="DOWNLOAD_AREA" />} url="/download-area" />
                                    </>
                                }

                                {authService.isAuthenticated === false &&
                                    <>
                                        <NavLink description={<FormattedMessage id="REGISTER_WARRANTY" />} url="/product/search" />
                                        <NavLink description={<FormattedMessage id="DOWNLOAD_AREA" />} url="/download-area-overview" />
                                    </>
                                }

                                {appService.currentCountry === "US" &&
                                    <NavLink description="Gray Market Info" url="/graymarket" />
                                }

                                <NavLink description={<FormattedMessage id="FAQ" />} url="/faq" />
                                <NavLink description={<FormattedMessage id="NAV_SUPPORT" />} url="/support" /> 
                            </ul>
                        }
                    </nav>
                </div>
                <UserMenu />
            </div>
        </div >
    );
}

export default MainNav;
import { useIntl } from 'react-intl';
import { Media } from 'components';
import { downloadAreaContext, IMedia } from '../DownloadAreaContext';
import { useContext } from 'react';


interface IProps {
    media: IMedia
}

let Content = ({ media }: IProps) => {
    let { formatMessage } = useIntl();

    let service = useContext(downloadAreaContext);

    return (
        <div className="card mb-3">
            <div className="row no-gutters">
                <div className="mx-auto text-center">
                    <Media
                        searchBy="byId"
                        mediaKey={media.backgroundId}
                        width={200}
                        height={200}
                        applyClasses="mx-100 mw-auto"
                    />
                </div>

                <div className="col-sm-12 col-md-12">
                    <div className="card-body">
                        <h5 className="card-title">{media.title}</h5>
                        <div className="row">
                            <div className="col">
                                <a href="#" className='btn btn-primary btn-sm' onClick={async () => await service.downloadMedia(media.mediaId)}>
                                    {formatMessage({ id: "DOWNLOAD" })}&nbsp;({media.fileSize}Mb)
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;
export let ES = {
    GENERAL_INFO: "Información GeneralGeneral Info",
    TITLE_1: "¿Qué información debo facilitar para registrar mi producto?",
    INFORMATION_NEEDED: "Esté preparado para proporcionar lo siguiente",
    PACKAGE: "Embalaje",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "Su dirección de correo electrónico y número de teléfono",
    SHIPPING_ADDRESS: "Tu dirección de envío",
    PRODUCT_NAME: "Nombre del producto",
    QRCODE: "Código QR",
    TITLE_2: "¿Qué pasa si no tengo el embalaje de mi producto o si he perdido mi código QR?",
    QRCODE_LOSS: "En caso de pérdida del QR ID, es posible solicitar una nueva etiqueta QR (+ QR ID)",
    GIVE_SOME_INFORMATIONS: "Por favor, facilítenos la siguiente información acompañada de la prueba de compra y generaremos un nuevo QR ID para su producto",
    PRODUCT_INFORMATIONS: "Información sobre el producto que está intentando registrar",
    BRAND: "Marca",
    PRODUCT_CODE: "Código del producto",
    SKU_EAN_UPC: "Código/EAN/UPC del producto",
    SERIAL_NUMBER_AVAILABLE: "Número de serie (si está disponible)",
    BUY_COUNTRY: "País de la compra",
    DEALER_NAME: "Nombre del vendedor",
    BUY_DATE: "Fecha de compra",
    PERSONAL_INFORMATIONS: "Tus datos personales",
    FIRSTNAME: "Nombre",
    LASTNAME: "Apellido",
    COUNTRY_RESIDENCE: "País de residencia",
    PROOF_OF_PURCHASE: "Prueba de compra",
    SALES_INVOICE: "Factura de venta",
    SECURITY_LABEL: "Etiqueta de seguridad",
    SUPPORT: "Soporte",
    TITLE_3: "¿Qué ocurre si se produce un problema o un error durante el proceso de registro?",
    SUPPORT_HOW_TO: "Si los problemas se producen en el momento del registro del producto o de la extensión de la garantía, facilítenos un resumen del problema y la siguiente información",
    PROBLEM_DESCRIPTION: "Descripción del problema",
    COPY_MESSAGE: "Copie íntegramente el mensaje de error que aparece en la página web",
    MODEL: "Modelo",
    REGISTER_WARRANTY: "Registro de la garantía",
    MY_PRODUCTS: "Mis Productos",
    FAQ: "FAQ",
    NAV_SUPPORT: "Soporte",
    DISCOVER_MORE: "Descubrir Más",
    LOGOUT: "Cerrar sesión",
    ACCOUNT: "Cuenta",
    LOGIN: "Iniciar sesión",
    REGISTER: "Registrarse",
    START_HERE: "¡Empieza aquí!", 
    COMPLETED: "Completado", 
    LOGIN_OR_REGISTER: "Inicie sesión o regístrese ahora para obtener la extensión de garantía de Videndum Media Solutions en sus productos",
    SUPPORT_SUBTITLE: 'Para cualquier pregunta, por favor haga clic en los botones de abajo para ponerse en contacto con nosotros. Un miembro de nuestro equipo local se pondrá en contacto con usted en breve.',
    NAME: 'Nombre',
    EMAIL: 'Correo electrónico',
    COUNTRY: 'País',
    REQUEST_TYPE: 'Tipo de Solicitud',
    REQUEST: 'Request',
    SEND_REQUEST: 'Enviar Solicitud',
    NEED_HELP: "¿Necesita ayuda?",
    NEED_HELP_DESCRIPTION: "Nuestro equipo de atención al cliente está a su disposición para ayudarle con cualquier duda o problema que le surja. Puede visitar nuestra sección de preguntas frecuentes o ponerse en contacto con nosotros directamente.",
    SITE_TERMS: "Condiciones de sitio",
    PRIVACY_POLICY: "Política de Privacidad",
    COOKIES_POLICY: "Política de Cookies",
    COOKIES_URL: "https://www.videndummediasolutions.com/en/cookie-policy/",
    MENU: "Menú",
    CUSTOMER_EXPERIENCE: "Su experiencia como cliente en Videndum Media Solutions",
    WELCOME_MESSAGE: "BIENVENIDO A LA PLATAFORMA DE REGISTRO DE PRODUCTOS EN LÍNEA",
    HOMEPAGE_MESSAGE: "Por favor, utilice este sitio para registrar su <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>National Geographic</strong> y <strong>Avenger</strong>.",
    DISCOVER: "Descubra la gama de servicios que ofrecemos a continuación.",
    REGISTER_PRODUCT: "Registrar producto",
    ACCOUNT_TITLE: 'Tu cuenta',
    ACCOUNT_SUBTITLE: 'Cambia aquí tu información básica y tus intereses.',
    MYPROFILE: 'Mi perfil',
    CHANGE_PASSWORD: 'Cambiar contraseña',
    CHANGE_EMAIL: 'Cambiar correo electrónico',
    ADDRESS: 'Dirección',
    CITY: 'Ciudad',
    COUNTRY_REGION: 'País',
    ZIPCODE: 'Códico Postal',
    STATE: 'Província',
    PHONE: 'Teléfono',
    SUBSCRIBE_TO_NEWSLETTER: 'Me suscribo para recibir boletines gratuitos y he leído, entendido y aceptado <link>el aviso de privacidad</link>',
    SAVE: 'Guardar',
    SAVING: 'Guardando datos',
    FIELD_REQUIRED: "{field} es obligatorio",
    NEWEMAIL: "Nuevo correo electrónico",
    NEWEMAIL_CONFIRMATION: "Confirmar nuevo correo electrónico",
    PASSWORD: "Contraseña",
    OLDPASSWORD: "Contraseña antigua",
    NEWPASSWORD: "Nueva Contraseña",
    NEWPASSWORD_CONFIRMATION: "Confirmar Nueva Contraseña",
    RECEIPT_UPLOADED: "Recibo cargado",
    RECEIPT_NOT_UPLOADED: "Recibo no cargado",
    CHOOSE_COUNTRY: "Elegir país",
    CHOOSE_BRAND: "Elegir marca",
    COMPLETE_ACCOUNT_TITLE: "Su cuenta no está completa",
    COMPLETE_ACCOUNT_SUBTITLE: "Por favor, complete su cuenta para proceder al registro de productos",
    BOUGHT_ONLINE: "Lo compré online",
    BOUGHT_IN_STORE: "Lo compré en una tienda",
    THANK_YOU: "Gracias",
    FOR_REGISTERING: "{firstName} {lastName} ¡por registrar tu producto!",
    PRODUCT_REGISTERED: "La garantía del producto {sku} se ha ampliado {months} meses",
    WARRANTY_EXTENSION: "meses de garantía",
    EXTENSION: "Extensión",
    NEW_EXPIRATION: "Nueva fecha de caducidad",
    PURCHASED_FROM: "Comprado a",
    TERMS_AND_CONDITIONS: "Ver todos los términos y condiciones",
    DOWNLOAD_WARRANTY: "Haga clic aquí para descargar la certificación de garantía",
    REGISTER_ANOTHER_PRODUCT: "Registrar otro producto",
    GOTO_PRODUCT_LIST: "Vaya a su lista de productos",
    FORGOT_PASSWORD: '¿Ha olvidado su contraseña?',
    NEW_ACCOUNT: "Nueva cuenta",
    WELCOME_USER: 'Bienvenid@ {name}',
    EDIT_ACCOUNT: "Editar cuenta",
    LOGIN_TO_WARRANTY: "Iniciar sesión en su garantía",
    CHANGE_PURCHASEDATE_RECEIPT: "Cambiar fecha de compra o subir otro recibo",
    PURCHASE_DATE: "Fecha de compra",
    REGISTER_PRODUCT_TO_EXTEND: "Registre su producto y amplíe su garantía",
    INSERT_PRODUCT_CODE: "Inserte su código de producto a continuación para iniciar el registro de su garantía",
    SAVE_AND_PROCEED: "Guardar y continuar",
    CHANGE_PRODUCT: "Cambiar producto", 
    DOWNLOAD_RECEIPT: "Descargar recibo",
    SERIAL_NUMBER: "Número de Serie",
    STORE: "Tienda",
    RECEIPT: "Recibo",
    REGISTERED_ON: "Registrado el", 
    PURCHASED_ON: "Comprado el", 
    YOUR_PRODUCT_LIST: "Su lista de productos", 
    YOUR_PRODUCT_LIST_DESCRIPTION1: "A continuación se muestran todos los productos que ha registrado y que puede modificar.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "Para registrar un nuevo producto, basta con iniciar el procedimiento de registro estándar.",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "Nota: asegúrese de conservar siempre los recibos y documentos originales como prueba de compra",
    ADD_PURCHASEDATE_AND_RECEIPT: "Agregar fecha de compra y recibo",
    PLEASE_UPLOAD_RECEIPT: "Por favor, añada la fecha de compra y el recibo.",
    UPLOAD_RECEIPT: "Subir recibo",
    PRIVACY_READ: "He leído <link>el Aviso de privacidad:</link>",
    PRIVACY_URL: "https://www.videndummediasolutions.com/en/privacy-policy/",
    UPLOADING: "Cargando",
    CHOOSE_COUNTRY_AND_STORE: "Seleccionar país y tienda de compra",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "Por favor, añada el nombre de la tienda y el país en el que ha comprado el producto.", 
    NO_OFFICIAL_STORE_FOUND: "No se ha encontrado ninguna tienda oficial.",
    PROCEED_ANYWAY: "Proceder de todos modos con el nombre de la tienda insertado.",
    EXTENSION_FOUND: "Ampliación de garantía de {months} meses",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "Inserte el Código/EAN/UPC del producto para descubrir qué garantía está disponible para este producto",
    DISCOVER_WARRANTY: "Descubra qué garantía está disponible para este producto",
    LOCATE_PRODUCT_CODE: "Localizar el código del producto",
    LOCATE_PRODUCT_CODE_CLICK: "Puede encontrar el código de producto cerca del código de barras en la parte inferior o en los bordes de la caja.",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "Encontrará el código del producto en el embalaje. Consulte la imagen de la izquierda para encontrar el código de producto en la esquina superior izquierda.", 
    LOCATING_EAN_UPC: "Localización del código de barras EAN o UPC", 
    LOCATING_EAN_UPC_DESCRIPTION: "Puede encontrar el código EAN o UPC en el envase. Consulte la imagen de la izquierda para encontrar el UPC/EAN en la esquina inferior izquierda.", 
    INSERT_PRODUCT_QR_CODE: "Inserte el código QR del producto", 
    LOCATE_PRODUCT_QR_CODE: "El código QR se encuentra en el embalaje de tu producto. Por favor, introduzca el código alfanumérico bajo el signo de código QR. Si no encuentra el código QR o ha sido manipulado, póngase en contacto con el servicio de atención al cliente.", 
    NORMAL_PRODUCT_QR_CODE: "Código QR normal",
    NORMAL_PRODUCT_QR_CODE_DESC: "El código QR debe estar intacto y sin marcas adicionales, con el código alfanumérico claramente visible",
    TAMPERED_PRODUCT_QR_CODE: "Códigos QR manipulados",
    TAMPERED_PRODUCT_QR_CODE_DESC: "Si le falta el código QR, tiene marcas adicionales o está borrado, utilice el siguiente enlace para que podamos ayudarle.",  
    PRODUCT_ELIGIBLE_FOR: "Producto elegible para", 
    NO_REGISTERED_PRODUCTS: "No tiene productos registrados",
    SUPPORTS: "Soportes",
    CAMERA_ACCESSORIES: "Accesorios de cámara",
    AUDIO_CAPTURE: "Captura de audio", 
    BAGS: "Bolsas", 
    MOBILE_POWER: "Alimentación móvil", 
    VIDEO_TRANSMISSION_SYSTEMS: "Sistemas de transmisión de vídeo", 
    PROMPTERS: "Prompters",
    MOTION_CONTROL: "Control de movimiento",
    DISTRIBUTION_RENTAL_SERVICES: "Distribución, alquiler y servicios",
    LIGHTING_AND_CONTROLS: "Iluminación y controles",
    ROBOTIC_CAMERA_SYSTEMS: "Sistemas de cámaras robóticas",
    MONITORS:"Monitores", 
    OUR_BRANDS: "Nuestras marcas", 
    DEALER_MANDATORY: "La tienda no ha sido seleccionada", 
    REGISTER_ACCOUNT_TITLE: "Registrar nueva cuenta",
    PASSWORD_CONFIRMATION: "Confirmar contraseña",
    SEND_CODE: "Enviar código",
    CODE: 'Código',
    RESET_PASSWORD: 'Restablecer contraseña',
    RESET_PASSWORD_SUBTITLE: 'Por favor, introduzca el código que le hemos enviado a su correo electrónico y la nueva contraseña',
    RESET_PASSWORD_NEEDED: "Necesita restablecer su contraseña para continuar", 
    SIGNUP_CONFIRM: 'Confirmar registro',
    NOT_REGISTERED: "¿No está registrado?",
    NOT_REGISTERED_SUBTITLE: "Haga clic en el botón de abajo para crear una nueva cuenta",
    SIGNUP_CONFIRMED: "Su cuenta ha sido verificada, haga clic en el botón para iniciar sesión",
    CONFIRM: "Confirmar",
    LOGIN_WITH_CREDENTIALS: "Inicie sesión con la cuenta de garantía de Videndum", 
    LOGIN_WITH_SOCIAL: "Iniciar sesión con redes sociales",
    UNLIMITED_WARRANTY: "Garantía ilimitada", 
    VERIFICATION_CODE_NOT_ARRIVED: "No ha llegado el código de verificación",
    CLICK_ON_BUTTON: "Haga clic en el botón de abajo para volver a enviar el código de verificación",
    SEND: "Enviar",
    PLEASE_CHOOSE_BRAND: "Por favor, elija al menos una marca que le guste",
    PROFILING: "Doy mi consentimiento a Videndum Media Solutions Spa para el tratamiento de mis datos personales y la utilización de herramientas de elaboración de perfiles para el envío de comunicaciones comerciales personalizadas, en los términos previstos en el aviso de privacidad",
    MANAGE_SUBSCRIPTIONS: "Para gestionar tus suscripciones haz clic en {link}",
    COOKIE_CONSENT: "{link} para ver este video",
    ACCEPT_STATISTICS_COOKIES: "Acepte 'cookies de estadísticas'",
    HERE: "aquí",
    QRCODE_NOT_VALID: "El código QR no es válido", 
    DOWNLOAD_AREA: "Área de descarga",
    DOWNLOADABLE_CONTENTS: "Material descargable", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Biblioteca de fondos digitales", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "Este portal de descargas está destinado a los usuarios del programa Savage Green Screen Wizard Lite para aplicaciones de croma. Incluido con nuestro software es el acceso exclusivo a nuestra amplia biblioteca de imágenes de 3.500 fondos digitales de alta calidad para su uso ilimitado.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Los usuarios pueden optar por descargar todas las categorías a su dispositivo a la vez, o volver a visitar esta página en el futuro simplemente iniciando sesión en este portal con sus credenciales de cuenta SavageUniversal.com.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Usuarios de Savage Green Screen Wizard Lite", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "Este portal de descargas está destinado a los propietarios del programa informático Savage Green Screen Wizard Lite para aplicaciones de croma. Con nuestro software se incluye un acceso exclusivo a nuestra amplia biblioteca de imágenes de 3.500 fondos digitales de alta calidad para su uso ilimitado.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Por favor, utilice el botón de abajo para registrar su número de serie único incluido en el paquete de su producto para obtener acceso a la biblioteca.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Aquí encontrarás los materiales descargables.", 
    ADD_SERIAL_NUMBER: "Añadir número de serie", 
    CANCEL: "Cancelar", 
    DOWNLOAD: "Descargar", 
    YOUR_SERIAL_NUMBER: "Tu número de serie: {serialNumber}", 
    
    INFO_DATA_SAVED: "Datos guardados",
    INFO_REQUEST_SENT: "Solicitud enviada",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "Sólo los usuarios que no son de Manfrotto pueden cambiar la contraseña",
    INFO_USER_ACCOUNT_SAVED: "Cuenta de usuario guardada",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "¿Está seguro de que desea cambiar su correo electrónico?",
    INFO_CONFIRM_REGISTRATION: "¿Está seguro de que desea registrar la garantía de este producto?",
    INFO_CONFIRM_SERIAL_NUMBER: "¿Seguro que deseas registrar este número de serie?",
    INFO_SERIAL_NUMBER_REGISTERED: "Número de serie registrado.",

    ERR_USER_ALREADY_EXIST: "El usuario ya existe",
    ERR_CHECK_PRIVACY: "Debe marcar la casilla de privacidad",
    ERR_USER_NOT_FOUND: "Usuario no encontrado",
    ERR_PASSWORD_REQUIRED: "Se requiere contraseña",
    ERR_PASSWORD_MISMATCH: "La contraseña no coincide",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "No se puede cambiar la contraseña",
    ERR_OLDPASSWORD_REQUIRED: "Se requiere la contraseña antigua",
    ERR_NEWPASSWORD_REQUIRED: "Se requiere nueva contraseña",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "Se requiere confirmación de nueva contraseña",
    ERR_CAPTCHA_ISREQUIRED: "Por favor, resuelva el captcha",
    ERR_NEWEMAIL_REQUIRED: "El nuevo correo electrónico no es válido",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "El nuevo email de confirmación no es válido",
    ERR_PASSWORD_NOT_VALID: "La contraseña no es válida",
    ERR_EMAIL_DONOT_MATCH: "El correo electrónico no coincide",
    ERR_PRODUCT_NOT_FOUND: "Producto no encontrado",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "Los usuarios de Videndum no pueden cambiar la contraseña",
    ERR_RESET_PASSWORD_FAILED: "No se ha podido restablecer la contraseña",
    ERR_UNABLE_TO_UPDATE_USER: "No se ha podido actualizar la información del usuario",
    ERR_BRANDS_MANDATORY: "Se debe especificar al menos una marca",
    ERR_INVALID_SERIAL_NUMBER: "Indica un número de serie válido.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Número de serie no encontrado.",

    AVAILABLE_ONLY_ON: "Disponible sólo en",
    GITZO_LEGENDE: "GITZO Légende", 
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "GITZO Légende, Únete a la campaña en Indiegogo",
    LEGENDE_TRIPOD_AND_BACKPACK: "Trípode y mochila Légende",
    LEGENDE_UNLIMITED_WARRANTY: "Garantía ilimitada",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "reparar o sustituir para una aventura sin fin",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "Todos los trípodes y mochilas Légende pedidos en Indiegogo tienen una garantía ilimitada, en condiciones normales de uso. Si descubre defectos en el material o en la mano de obra, lo repararemos, enviaremos una pieza nueva o lo sustituiremos, sin coste alguno",
    LEGENDE_READ_TC: "Leer T&C",
    LEGENDE_TRIPOD: "Trípode Légende",
    LEGENDE_TRIPOD_SUB: "El mejor trípode que hemos podido fabricar y reparable para siempre",
    LEGENDE_100_PARTS_REPLACEABLE: "100% piezas reemplazables y reparables para siempre",
    LEGENDE_70_REPLACEABLE: "70% de piezas reemplazables por el usuario",
    LEGENDE_SELF_SERVICEABLE: "Autoservicio",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "Autoservicio, para un rendimiento óptimo durante toda su vida útil",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Unos sencillos pasos de mantenimiento ayudan a mantener tu Légende en perfecto estado, vayas donde vayas",
    LEGENDE_BACKPACK: "Mochila Légende",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Diseñada por expertos para el fotógrafo de viajes, pensada para la sostenibilidad y el futuro",
    LEGENDE_65_RECYCLED: "65% de tejidos reciclados",
    LEGENDE_ECOFRIENDLY_LEATHER: "Piel respetuosa con el medio ambiente",
    LEGENDE_HOWTO_CARE_BACKPACK: "Cómo cuidar tu mochila Légende",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Sigue estos pasos básicos para cuidar tu mochila Légende",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/es-es/customer-service/garantia-terminos-y-condiciones/"
}
import { applicationContext } from 'helpers/services';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';
import moment from 'moment';

interface INewRegistrationContext {
    initialState: IRegistration
    registration: IRegistration
    currentStep: Step

    updateRegistration: (updatedReg: IRegistration) => void
}

export interface IRegistration {
    qrCode: string
    warrantyExtensionMonths: number
    warrantyUpToMonths: number
    warrantyExpirationDate: moment.Moment
    sku: string
    productBrand: string
    shortDescription: string
    isQrCodeMandatory: boolean
    dealerId: string
    dealerName: string
    dealerCountryCode: string
    purchaseDate: Date
    unlimited: boolean
    publicUserGuid: string
    receiptId: string
    receiptFilename: string
    purchaseType: string
    receiptDownloadUrl: string
    dealerAddress: string
}

export enum Step {
    Search = "search",
    WarrantyAvailable = "warrantyavailable"
}

export let productSearchContext = React.createContext({} as INewRegistrationContext);

let { Provider } = productSearchContext;

let NewRegistrationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { step } = useParams<{ step: Step }>();
    let history = useHistory();

    let appService = useContext(applicationContext);

    let initialState: IRegistration = {
        dealerCountryCode: appService.currentCountry !== 'XX' ? appService.currentCountry : '',
        dealerId: '',
        dealerName: '',
        isQrCodeMandatory: false,
        publicUserGuid: '',
        unlimited: false, 
        purchaseDate: new Date(),
        warrantyExpirationDate: moment(),
        purchaseType: '',
        qrCode: '',
        productBrand: '',
        receiptId: '',
        receiptFilename: '',
        shortDescription: '',
        sku: '',
        receiptDownloadUrl: '',
        dealerAddress: '',
        warrantyExtensionMonths: 0,
        warrantyUpToMonths: 0
    };

    let [registration, setRegistration] = useState<IRegistration>(initialState);


    let getState = () => {
        return localStorage.getItem("warranty.registration");
    }

    let loadState = () => {
        let state = getState();
        if (state) {
            let reg: IRegistration = JSON.parse(state);

            // (!reg.dealerCountryCode || reg.dealerCountryCode === '') &&
            if (appService.currentCountry !== 'XX') {
                reg = {
                    ...reg,
                    dealerCountryCode: appService.currentCountry
                };
            }

            setRegistration({
                ...registration,
                ...reg,
                purchaseDate: reg.purchaseDate ? new Date(reg.purchaseDate) : new Date()
            })
        }
    }

    useEffect(() => {
        let currentState = getState();
        if (currentState) {
            loadState();
        } else
            history.push('/product/search');
    }, [])

    if (!step)
        <Redirect to="/product/search" />;


    let updateRegistration = (updatedReg: IRegistration) => {
        saveState(updatedReg);
        setRegistration(updatedReg);
    }

    let saveState = (reg: IRegistration) => {
        localStorage.setItem('warranty.registration', JSON.stringify(reg));
    }


    return (
        <Provider value={{
            initialState,
            registration,
            currentStep: step,

            updateRegistration
        }}>
            {children}
        </Provider>
    );
}

export default NewRegistrationProvider;
import React, { useContext, useEffect, useState } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import AuthSettings from 'helpers/AuthSettings';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { applicationContext } from './ApplicationContext';

Amplify.configure(AuthSettings.CONFIG);

interface IAuthContext {
    currentUser?: User
    isLoading: boolean
    isAuthenticated: boolean

    federatedSignIn: (authority: 'Facebook' | 'Google') => Promise<void>
    signInWithCredentials: (clientId: string, clientSecret: string) => Promise<void>
    signInAndChangePassword: (clientId: string, clientSecret: string, newPassword: string) => Promise<boolean>
    forgotPassword: (email: string) => Promise<boolean>
    forgotPasswordSubmit: (clientId: string, code: string, password: string) => Promise<void>
    signUp: (email: string, countryCode: string, firstName: string, lastName: string, password: string) => Promise<void>
    resendConfirm: (username: string) => Promise<boolean>
    confirmSignUp: (username: string, code: string) => Promise<boolean>
    signOut: () => Promise<void>
    getToken: () => Promise<string | null>
    getUserProfile: (user: User) => Promise<boolean>
}

export let authContext = React.createContext({} as IAuthContext);

let { Provider } = authContext;

let AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let history = useHistory();

    let appService = useContext(applicationContext);

    let [isLoading, setLoading] = useState(true);
    let [isAuthenticated, setAuthenticated] = useState(false);

    let [currentUser, setCurrentUser] = useState<User>();

    useEffect(() => {
        getAuthenticatedUser()
            .then(user => {
                if (user !== undefined)
                    getUserProfile(user);
                else
                    setLoading(false);
            })
    }, [])


    Hub.listen("auth", async ({ payload: { event } }) => {
        switch (event) {
            case "signIn":
                {
                    let user = await getAuthenticatedUser();
                    if (user !== undefined) {
                        let res = await getUserProfile(user);
                        if (res === true)
                            history.push('/');
                    }
                }
                break;

            case 'oAuthSignOut':
                setAuthenticated(false);
                setLoading(false);
                break;

            case 'signIn_failure':
            case "signOut":
                setAuthenticated(false);
                setLoading(false);
                history.replace('/');
                break;

            case "signUp":
                break;

            default:
                setAuthenticated(false);
                setLoading(false);
                console.info(`[Warranty] Not managed event: ${event}`);
                break;
        }
    });

    let signUp = async (email: string, countryCode: string, firstName: string, lastName: string, password: string) => {
        try {
            await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    'given_name': firstName,
                    'family_name': lastName,
                    'custom:privacy': 'true'
                },
                clientMetadata: {
                    'warranty_country': countryCode
                }
            });

            toast.success('Verification code has been sent to your email');
            history.push('/account/signupconfirm', { email });

        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let confirmSignUp = async (username: string, code: string) => {
        try {
            await Auth.confirmSignUp(username, code);
            return true;

        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let resendConfirm = async (username: string) => {
        if (username === '') {
            toast.error("You need to provide valid email to send verification code");
            return false;
        }

        try {
            await Auth.resendSignUp(username, {
                'warranty_country': appService.currentCountry
            });
            toast.success("Verification code has been sent. Please check your email");
            return true;

        } catch (err: any) {
            toast.error(err.message);
            throw err;
        }
    }

    let signInWithCredentials = async (clientId: string, clientSecret: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);

            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                history.replace('/account/changepassword', { clientId, clientSecret });
            }

        } catch (err: any) {
            if (err.code === "UserNotConfirmedException")
                history.replace(`/account/signupconfirm`, { email: clientId });
            else
                toast.error(err.message);
        }
    }

    let federatedSignIn = async (provider: 'Facebook' | 'Google') => {
        try {
            //@ts-ignore
            await Auth.federatedSignIn({ provider });
        } catch (err: any) {
            toast.error(err.message);
        }
    }

    let signInAndChangePassword = async (clientId: string, clientSecret: string, newPassword: string) => {
        try {
            let cognitoUser = await Auth.signIn(clientId, clientSecret);
            await Auth.completeNewPassword(cognitoUser, newPassword);
            return true;

        } catch (err: any) {
            toast.error(err.message);
            return false;
        }
    }

    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    let forgotPassword = async (userId: string) => {
        try {
            await Auth.forgotPassword(userId, {
                'warranty_country': appService.currentCountry
            });
            toast.success('Verification code has been sent to your email');
            return true;

        } catch (err: any) {
            toast.error('Failed to sent verification code: ' + err.message);
            return false;
        }
    }

    // To verify attribute with the code
    let forgotPasswordSubmit = async (clientId: string, code: string, password: string) => {
        try {
            await Auth.forgotPasswordSubmit(clientId, code, password);
            toast.success('Password has been changed');
            history.replace('/account/forgotpasswordconfirmation');

        } catch (err: any) {
            toast.error('Failed to change the password: ' + err.message);
        }
    }

    let signOut = async () => {
        try {
            await Auth.signOut();
        } catch (err: any) {
            toast.error(err.message);
        }
    }

    let getToken = async () => {
        let session = await Auth.currentSession();

        if (session?.isValid())
            return session.getIdToken().getJwtToken();
        else
            return null;
    }


    let getAuthenticatedUser = async () => {
        try {
            setLoading(true);
            let cognitoUser = await Auth.currentAuthenticatedUser();
            let user = new User(cognitoUser);

            setAuthenticated(true);
            setCurrentUser(user);

            return user;

        } catch (e) {
            setCurrentUser(undefined);
            setAuthenticated(false);

            return undefined;
        }
    }

    let getUserProfile = async (user: User) => {
        if (!user) {
            console.error("User is not defined in getUserProfile");
            return false;
        }

        try {
            let res = await axios.get('/api/User/GetUserProfile');
            let { item } = res.data;

            let newUser = {
                ...user,
                firstname: item.firstname,
                lastname: item.lastname,
                countryCode: item.countryCode,
                isCompleted: item.isCompleted
            };

            if (newUser.countryCode && appService.currentCountry !== newUser.countryCode)
                appService.chooseCountry(newUser.countryCode);

            setCurrentUser(newUser);

            if (newUser.isCompleted === false) {
                history.replace('/account/complete');
                return false;
            } else {
                return true;
            }

        } catch (err) {
            setCurrentUser(undefined);
            setAuthenticated(false);
            return false;
        } finally {
            setLoading(false);
        }
    }


    return (
        <Provider
            value={{
                isLoading,
                isAuthenticated,
                currentUser,

                signUp,
                resendConfirm,
                signInAndChangePassword,
                federatedSignIn,
                confirmSignUp,
                signInWithCredentials,
                forgotPassword,
                forgotPasswordSubmit,
                signOut,
                getToken,
                getUserProfile
            }}
        >
            {children}
        </Provider>
    )
}

class User {
    readonly username: string;
    readonly email: string;
    readonly groups: string[];

    isCompleted = false;
    firstname = '';
    lastname = '';
    countryCode = 'XX';

    constructor(cognitoUser: any) {
        this.username = cognitoUser.username;
        this.email = (cognitoUser.signInUserSession.idToken.payload['email']).toString().toLowerCase();
        this.groups = cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
    }

    hasGroup = (group: string) => {
        return this.groups.indexOf(group) !== -1;
    }
}

export default AuthProvider;
import { useContext } from 'react';
import 'css/page/register_warranty.scss';
import { Search, WarrantyAvailable } from './components';
import { productSearchContext, Step } from './ProductSearchContext';


var NewRegistration = () => {
    let service = useContext(productSearchContext);

    return (
        <div id="regw">
            {service.currentStep === Step.Search && <Search />}
            {service.currentStep === Step.WarrantyAvailable && <WarrantyAvailable />}
        </div>
    );
}

export default NewRegistration;
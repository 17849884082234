
interface IMediaProps {
    searchBy?: "byId" | "byProduct",
    mediaKey: string,
    applyClasses?: string,
    height?: number,
    width?: number,
    srcSet?: string
}

let Media = (props: IMediaProps) => {
    let { mediaKey, searchBy, height, width, srcSet, applyClasses } = props;

    let forgeImgUrl = () => {
        let url = `/media/`;

        switch (searchBy) {
            case "byId":
                url += `${mediaKey}`;
                break;
            case "byProduct":
                url += `${searchBy}?sku=${mediaKey}`;
                break;
            default:
                url += `${mediaKey}`;
        }

        if (height !== undefined)
            url += searchBy === 'byId'
                ? `?height=${height}`
                : `&height=${height}`;

        if (width !== undefined)
            url += (searchBy === 'byId' && height == undefined)
                ? `?width=${width}`
                : `&width=${width}`;

        return url;
    }

    let forgeSrcSetUrl = () => {
        let url = `/media/`;

        switch (searchBy) {
            case "byId":
                url += `${mediaKey}`;
                break;
            case "byProduct":
                url += `${searchBy}?sku=${mediaKey}`;
                break;
            default:
                url += `${mediaKey}`;
        }

        if (height !== undefined)
            url += searchBy === 'byId'
                ? `?height=${height * 2}`
                : `&height=${height * 2}`;

        return url;
    }

    return (
        <img
            alt=""
            className={applyClasses}
            src={process.env.REACT_APP_MEDIA_CDN + forgeImgUrl()}
            srcSet={srcSet !== undefined ? process.env.REACT_APP_MEDIA_CDN + forgeSrcSetUrl() : ''}
        />
    );
}

export default Media;
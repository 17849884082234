import 'css/page/homepage.scss';
import { authContext } from 'helpers/services';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';


let Home = () => {
    let authService = useContext(authContext);


    return (
        <div className="homepage">
            <div className="container">
                <div className="row align-items-center pt-5 pb-5">
                    <div className="col-12 col-md-8">
                        <small className="text-uppercase text-light">
                            <strong><FormattedMessage id="CUSTOMER_EXPERIENCE" /></strong>
                        </small>
                        <h2 className="text-uppercase mt-0">
                            <strong><FormattedMessage id="WELCOME_MESSAGE" /></strong>
                        </h2>
                        <p className="pb-2 pt-2">
                            <FormattedMessage
                                id="HOMEPAGE_MESSAGE"
                                values={{ strong: (chunks: any) => <strong>{chunks}</strong> }}
                            />
                            <br />
                            <FormattedMessage id="DISCOVER" />
                        </p>

                        <Link to={authService.isAuthenticated === true ? "/newregistration" : "/product/search"} className="btn btn-primary text-uppercase pl-5 pr-5">
                            <strong><FormattedMessage id="REGISTER_PRODUCT" /></strong>
                        </Link>
                    </div>
                   
                </div>
            </div>
        </div>
    );
}

export default Home;
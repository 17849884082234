import 'css/page/account.scss';
import { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { authContext } from 'helpers/services';
import { Spinner } from 'components';
import axios from 'axios';


let SignUpConfirm = () => {
    let history = useHistory();
    let location = useLocation();
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let newPasswordSchema = yup.object({
        code: yup.string().required().label(formatMessage({ id: 'CODE' })).default(''),
        email: yup.string().required().label(formatMessage({ id: 'EMAIL' })).default((location.state as any)?.email || ''),
    })

    let [isLoading, setLoading] = useState(false);


    return (
        <Formik
            initialValues={newPasswordSchema.getDefault()}
            validationSchema={newPasswordSchema}
            onSubmit={async (values, action) => {
                try {
                    setLoading(true);
                    let res = await authService.confirmSignUp(values.email, values.code);
                    if (res === true) {
                        await axios.post('/api/User/ConfirmUserEmail', { email: values.email });
                        history.push('/account/signupconfirmed');
                    }

                } catch (err) {
                    setLoading(false);
                    action.setSubmitting(false);
                }
            }}
        >
            {form =>
                <div className="accountpanel">
                    <div className="bg-white p-0 pt-5 pt-lg-0">
                        <div className="container py-4 px-5">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="pb-4 pt-3">{formatMessage({ id: "SIGNUP_CONFIRM" })}</h5>
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="textinput"><FormattedMessage id="EMAIL" />&nbsp;*</label>
                                            <Field
                                                type="email"
                                                disabled={isLoading}
                                                className="form-control"
                                                name="email"
                                            />
                                            <ErrorMessage name="email" component="span">
                                                {message => <span className="text-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'EMAIL' }) })}</span>}
                                            </ErrorMessage>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="textinput"><FormattedMessage id="CODE" />&nbsp;*</label>
                                            <Field
                                                type="text"
                                                disabled={isLoading}
                                                className="form-control"
                                                name="code"
                                            />
                                            <ErrorMessage name="code" component="span">
                                                {message => <span className="text-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'CODE' }) })}</span>}
                                            </ErrorMessage>
                                        </div>

                                        {isLoading && <Spinner />}
                                        {isLoading === false &&
                                            <button
                                                className="btn btn-success btn-block mt-4"
                                                type="submit"
                                            >
                                                {formatMessage({ id: "CONFIRM" })}
                                            </button>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-4 p-5 bg-success text-white">
                            <div className="row">
                                <div className="col-12">
                                    <h5>{formatMessage({ id: "VERIFICATION_CODE_NOT_ARRIVED" })}</h5>
                                    <p>{formatMessage({ id: "CLICK_ON_BUTTON" })}</p>
                                    <button className="btn btn-light bg-white" onClick={() => authService.resendConfirm(form.values.email)}>
                                        {formatMessage({ id: "SEND" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Formik>

    );
}

export default SignUpConfirm;
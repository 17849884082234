import { useContext } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from 'react-router';
import { authContext } from 'helpers/services';
import Localizations from 'helpers/localization';


let AxiosHandlerProvider = () => {
    let history = useHistory();

    let authService = useContext(authContext);

    axios.interceptors.request.use(async config => {
        if (config.headers) {
            if (authService.isAuthenticated) {
                let token = await authService.getToken();
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            let country = localStorage.getItem("warranty.country");
            if (country)
                config.headers['WarrantyCountry'] = country;

        }

        return config;
    })

    axios.interceptors.response.use(
        response => response,

        error => {
            let { data, status } = error.response;

            switch (status) {
                case 400:
                    if (data.HasErrors) {
                        data.Errors.forEach((er: string) => {
                            let message = Localizations['XX'][er];
                            if (message)
                                toast.error(message);
                            else
                                toast.error(er);
                        })
                    } else {
                        for (let i in data.errors) {
                            let message = Localizations['XX'][data.errors[i]];
                            if (message)
                                toast.error(message);
                            else
                                toast.error(data.errors[i]);
                        }
                    }
                    break;

                case 401:
                    history.replace('/error401');
                    break;

                case 500:
                    toast.error('Internal server error');
                    break;

                default:
                    toast.error('Unable to connect to server, please check your internet connection and retry.');
                    break;
            }

            return Promise.reject({ ...data });
        }
    )

    return (null);
}

export default AxiosHandlerProvider;
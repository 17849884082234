import ProductSearchProvider from './ProductSearchContext';
import ProductSearch from './ProductSearch';


let Index = () => {
    return (
        <ProductSearchProvider>
            <ProductSearch />
        </ProductSearchProvider>
    )
}

export default Index;
export default {
    CONFIG: {
        Auth: {
            // NdN lasciare vuoto
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            // identityPoolId: '',// 'eu-west-1:fa4bbf96-3f42-4e59-868a-96af1290e85f',

            // REQUIRED - Amazon Cognito Region
            region: process.env.REACT_APP_AUTH_REGION,

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENTID,

            // OPTIONAL - customized storage object
            storage: localStorage,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_PASSWORD_AUTH',

            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            // clientMetadata: { myCustomKey: 'myCustomValue' },

            // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: process.env.REACT_APP_AUTH_DOMAIN,
                responseType: 'code',
                redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
                redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT
                // scope: ['email', 'openid'],//['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                // responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        }
    }
}
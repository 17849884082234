import { useContext, useEffect } from 'react';
import { Media } from 'components';
import Progress from './Progress';
import { productSearchContext } from '../ProductSearchContext';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { authContext } from 'helpers/services';


let WarrantyAvailable = () => {
    let history = useHistory();

    let service = useContext(productSearchContext);
    let authService = useContext(authContext);


    useEffect(() => {
        if (authService.isLoading === false && authService.isAuthenticated === true) {
            history.replace('/newregistration/dateandreceipt');
        }

    }, [authService.isLoading, authService.isAuthenticated])


    return (
        <>
            <div className="text-center container">
                <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>

                <Progress />
                <div className="row regw__blockyears">
                    <div className={`col mx-auto col-md-4 col-lg-3 active ${service.registration.warrantyUpToMonths === 0 ? 'd-none' : ''}`}>
                        <button>
                            <span className={"text-body font-weight-bolder"}>
                                <FormattedMessage id="PRODUCT_ELIGIBLE_FOR" />:
                            </span>
                            <br />
                            {service.registration.unlimited && <FormattedMessage id="UNLIMITED_WARRANTY" />}
                            {service.registration.unlimited === false &&
                                <>
                                    {service.registration.warrantyUpToMonths}&nbsp;<FormattedMessage id="WARRANTY_EXTENSION" />
                                </>
                            }
                        </button>
                    </div>
                </div>
                <div className="regw__form text-left">
                    <div>
                        <label className="w-100" htmlFor="example1">
                            <FormattedMessage id="INSERT_CODE_AND_DISCOVER_WARRANTY" />
                        </label>
                        <span className="regw__form__input">
                            <img src="/images/icons/check.svg" alt="" />
                            <input type="text" disabled placeholder="Product code" value={service.registration.sku} />
                        </span>
                    </div>
                </div>
                <div className="pt-4 pb-5 row justify-content-md-center align-items-center">
                    <div className="col-12 col-md-auto" >
                        <Media
                            searchBy="byProduct"
                            mediaKey={service.registration.sku}
                            width={100}
                            applyClasses="d-inline-block mr-3"
                        />
                    </div>
                    <div className="col-12 col-md-auto order-md-first" >
                        <span className={"logo-" + service.registration.productBrand.toLowerCase() + "-lg-c logo-over-img d-block position-static"}>
                            <a><span></span></a>
                        </span>
                    </div>
                    <div className="col-12 col-md-auto" >
                        <p className="d-inline-block">
                            <strong>{service.registration.shortDescription}</strong>
                            <a href="/newregistration" className={"font-sm font-weight-extrabold text-danger small d-inline pl-3"}>✕</a>
                        </p><br />
                    </div>
                </div>
            </div>
            <div className="container pb-5">
                <div className="row align-items-center text-center">
                    <div className="col-12 pb-5 pt-3">
                        <p><FormattedMessage id="LOGIN_OR_REGISTER" /></p>

                        <div className="d-block pt-3">
                            <RouterLink to="/account/login">
                                <button className="btn btn-dark btn-lg mr-2 px-5">
                                    <FormattedMessage id="LOGIN" />
                                </button>
                            </RouterLink>
                            <RouterLink to="/account/signup">
                                <button className="btn btn-success btn-lg ml-2 px-5">
                                    <FormattedMessage id="REGISTER" />
                                </button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light pb-5 d-none">
                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="col-12 col-md-8 mx-auto text-center pb-5">
                        </div>
                        <div className="col-md-6 col-12 pb-2 pt-5 pt-md-0">
                            <div className="card h-100">
                                <div className="card-body">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 pb-2 pt-5 pt-md-0">
                            <div className="card h-100">
                                <div className="card-body">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WarrantyAvailable;
import React from 'react';
import { Link } from 'react-router-dom';


interface IProp {
    url: string
    description: string | React.ReactNode
}

let MainLink = (props: IProp) => {
    return (
        <li className="main-menu__item">
            <Link className="main-menu__text" to={props.url}>
                {props.description}
            </Link>
        </li>
    )
}

export default MainLink;
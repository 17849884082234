import 'css/page/account.scss';
import { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { Spinner } from 'components';
import { authContext } from 'helpers/services';


let ChangePassword = () => {
    let location = useLocation();
    let history = useHistory();

    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let changePasswordSchema = yup.object({
        newPassword: yup.string().required().default(''),
        newPasswordConfirm: yup.string()
            .oneOf([yup.ref('newPassword'), ''], formatMessage({ id: "ERR_PASSWORD_MISMATCH" }))
            .required()
            .default('')
    });

    let [isLoading, setLoading] = useState(false);



    return (
        <div className="accountpanel">
            <div className="bg-white p-0">
                <div className="container py-4 px-5">
                <div className="row">
                        <div className="col-12">
                            <h4 className="pb-4">
                            {formatMessage({ id: "RESET_PASSWORD_NEEDED" })}
                        </h4>
                        <Formik
                            validationSchema={changePasswordSchema}
                            initialValues={changePasswordSchema.getDefault()}
                            onSubmit={async (values, action) => {
                                setLoading(true);
                                let state: any = location.state;
                                let res = await authService.signInAndChangePassword(state.clientId, state.clientSecret, values.newPassword);
                                if (res === true)
                                    history.push("/");
                                else {
                                    action.setSubmitting(false);
                                    setLoading(false);
                                }
                            }}
                        >
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="newpassword">{formatMessage({ id: "NEWPASSWORD" })}</label>
                                    <Field
                                        id="newpassword"
                                        className="form-control"
                                        disabled={isLoading}
                                        name="newPassword"
                                        type="password"
                                    />
                                    <ErrorMessage name="newPassword" component="span">
                                        {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'NEWPASSWORD' }) })}</span>}
                                    </ErrorMessage>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newpasswordconfirmation">{formatMessage({ id: "PASSWORD_CONFIRMATION" })}</label>
                                    <Field
                                        id="newpasswordconfirmation"
                                        className="form-control"
                                        disabled={isLoading}
                                        name="newPasswordConfirm"
                                        type="password"
                                    />
                                    <ErrorMessage name="newPasswordConfirm" component="span">
                                        {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'PASSWORD_CONFIRMATION' }) })}</span>}
                                    </ErrorMessage>
                                </div>

                                {isLoading && <Spinner />}
                                {isLoading === false &&
                                    <button
                                        style={{ float: 'right' }}
                                        className="btn btn-success btn-block"
                                        type="submit"
                                    >
                                        {formatMessage({ id: "RESET_PASSWORD" })}
                                    </button>
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
        </div>

    )
}

export default ChangePassword;
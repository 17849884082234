import React from 'react';


interface IQrCodeInfo {
    qrCode: string
}

let QrCodeInfo = ({ qrCode }: IQrCodeInfo) => {
    return (
        <>
            <div className="row justify-content-center text-left">
                <div className="col-3 text-center">
                    <img src="/images/icons/check.svg" alt="" height="30px" className="mt-3" />
                </div>
                <div className="col">
                    <p className="pt-3 pb-3 m-0 font-s-lg"><strong>QR code: </strong> {qrCode} </p>
                </div>
            </div>
            <hr className="mt-2 mb-2" />
        </>
    )
}

export default QrCodeInfo;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from 'react-toastify';

interface IContext {
    isLoading: boolean
    isSaving: boolean
    media: IMedia[]
    error: string
    serialNumber: string
    addSerialNumber: (serialNumber: string) => Promise<boolean>
    downloadMedia: (mediaId: string) => Promise<void>
}

export interface IMedia {
    mediaId: string
    backgroundId: string
    title: string
    fileSize: number
}

export let downloadAreaContext = React.createContext({} as IContext);

let { Provider } = downloadAreaContext;

let DownloadAreaProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let { formatMessage } = useIntl();

    let [isLoading, setLoading] = useState(true);
    let [isSaving, setSaving] = useState(false);
    let [serialNumber, setSerialNumber] = useState<string>('');
    let [media, setMedia] = useState<IMedia[]>([]);
    let [error, setError] = useState<string>('');


    useEffect(() => {
        getDownloadableContents();
    }, [])


    let getDownloadableContents = async () => {
        try {
            setLoading(true);

            // verifico se l'utente ha SerialNumber, se non ce l'ha deve inserirlo per vedere i contenuti scaricabili
            let res = await axios.get('/api/User/GetUserSerialNumber');

            if (res.data) {
                setSerialNumber(res.data);

                res = await axios.get('/api/SerialNumbers/GetDownloadableContents');
                setMedia(res.data.items);
            }

        } catch (err) { }
        finally {
            setLoading(false);
        }
    }

    let addSerialNumber = async (serialNumber: string) => {
        if (window.confirm(formatMessage({ id: "INFO_CONFIRM_SERIAL_NUMBER" })) === false)
            return false;

        try {
            setSaving(true);
            setError('');

            let res = await axios.post('/api/User/SaveUserSerialNumber', { serialNumber });

            if (res.data.hasErrors === true) {
                setError(res.data.errors)
                setSaving(false);
                return false;
            } else {
                toast.success(formatMessage({ id: 'INFO_SERIAL_NUMBER_REGISTERED' }));
                await getDownloadableContents();
                setSaving(false);
                return true;
            }

        } catch (err) {
            setSaving(false);
            return false;
        }
    }

    let downloadMedia = async (mediaId: string) => {
        try {
            let res = await axios.get('/api/SerialNumbers/DownloadBundle', { params: { mediaId } });

            let link = document.createElement("a");

            link.setAttribute("href", res.data);
            link.setAttribute("target", "_blank");
            link.setAttribute("download", mediaId);
            link.click();

        } catch (err) {

        }
    }



    return (
        <Provider
            value={{
                isLoading,
                isSaving,
                media,
                error, 
                serialNumber, 
                addSerialNumber,
                downloadMedia
            }}
        >
            {children}
        </Provider>
    );
}

export default DownloadAreaProvider;
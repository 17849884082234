import 'css/page/account.scss';
import { useContext, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { applicationContext, authContext } from 'helpers/services';
import { Spinner } from 'components';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';


let SignUp = () => {
    let { formatMessage } = useIntl();

    let userSchema = yup.object({
        email: yup.string().default('').required(),
        firstName: yup.string().default('').required(),
        lastName: yup.string().default('').required(),
        address: yup.string().default(''),
        city: yup.string().default(''),
        countryCode: yup.string().default('').required(),
        zipCode: yup.string().default(''),
        state: yup.string().default(''),
        phoneNumber: yup.string().default(''),
        password: yup.string().default('').required(),
        passwordConfirm: yup.string().default('').required(),
        signUpProfiling: yup.boolean().default(Boolean(false)),
        signUpNewsletter: yup.boolean().default(Boolean(false)),
        signUpBrandOfInterest: yup.array(yup.string().default(''))
            .when("signUpNewsletter", {
                is: true,
                then: (schema) => schema.default([]).min(1, formatMessage({ id: 'ERR_BRANDS_MANDATORY' })),
                otherwise: (schema) => schema.default([])
            })
    });

    type User = yup.InferType<typeof userSchema>;

    let appService = useContext(applicationContext);
    let authService = useContext(authContext);

    let [isSaving, setSaving] = useState(false);


    let saveUser = async (value: User) => {
        if (value.password !== value.passwordConfirm) {
            toast.error(formatMessage({ id: "ERR_PASSWORD_MISMATCH" }));
            return;
        }

        try {
            setSaving(true);
            let request = { ...value };

            // Registro l'utente con email non confermata in Warranty
            await axios.post('/api/User/RegisterUser', request);

            // Creo l'utente in Cognito e invio la mail col codice per confermare l'account
            await authService.signUp(value.email, value.countryCode, value.firstName, value.lastName, value.password);

        } catch (err) {
            setSaving(false);
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={userSchema}
            initialValues={{
                ...userSchema.getDefault(),
                countryCode: appService.currentCountry,
                signUpBrandOfInterest: appService.salesforceBrands.map(x => x.key)
            }}
            onSubmit={async (value, action) => {
                await saveUser(value);
                action.setSubmitting(false);
            }}
        >
            {form =>
                <div className="bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-6 mx-auto pt-5">
                                <h1>
                                    <FormattedMessage id="REGISTER_ACCOUNT_TITLE" />
                                </h1>
                                <p className="small">
                                    <FormattedMessage id="COMPLETE_ACCOUNT_SUBTITLE" />
                                </p>
                                <hr className="mt-4" />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-6 mx-auto pt-5 pt-lg-0">
                                <div className="tab-content pb-5">
                                    <div className="tab-pane fade show active pb-5" role="tabpanel">

                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="textinput">
                                                    <FormattedMessage id="EMAIL" /> *
                                                </label>
                                                <Field
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                />
                                                <ErrorMessage name="email" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'EMAIL' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="FIRSTNAME" /> *
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="firstName"
                                                    />
                                                    <ErrorMessage name="firstName" component="span">
                                                        {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'FIRSTNAME' }) })}</span>}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="LASTNAME" /> *
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="lastName"
                                                    />
                                                    <ErrorMessage name="lastName" component="span">
                                                        {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'LASTNAME' }) })}</span>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="textinput">
                                                    <FormattedMessage id="COUNTRY_REGION" /> *
                                                </label>
                                                <Field
                                                    name="countryCode"
                                                    component="select"
                                                    className="form-control"
                                                >
                                                    {appService.countries.map(country =>
                                                        <option key={country.key} value={country.key}>{country.value}</option>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="countryCode" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'COUNTRY_REGION' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-8">

                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="ADDRESS" />
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="address"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="ZIPCODE" />
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="zipCode"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="CITY" />
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="city"
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="textinput">
                                                        <FormattedMessage id="STATE" />
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name="state"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="textinput">
                                                    <FormattedMessage id="PHONE" />
                                                </label>
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    name="phoneNumber"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="textinput">
                                                    <FormattedMessage id="PASSWORD" />&nbsp;*
                                                </label>
                                                <Field
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                />
                                                <ErrorMessage name="password" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'PASSWORD' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="textinput">
                                                    <FormattedMessage id="PASSWORD_CONFIRMATION" />&nbsp;*
                                                </label>
                                                <Field
                                                    type="password"
                                                    className="form-control"
                                                    name="passwordConfirm"
                                                />
                                                <ErrorMessage name="passwordConfirm" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'PASSWORD_CONFIRMATION' }) })}</span>}
                                                </ErrorMessage>
                                            </div>

                                            <div className="mb-3">
                                                <FormattedMessage
                                                    id="PRIVACY_READ"
                                                    values={{
                                                        link: (chunks: any) => <a rel='noopener noreferrer' target='_blank' href={formatMessage({ id: 'PRIVACY_URL' })}><b>{chunks}</b></a>
                                                    }}
                                                />
                                            </div>

                                            <div className="form-check mb-3">
                                                <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="signUpNewsletter"
                                                    id="signUpNewsletter"
                                                />
                                                <label htmlFor="signUpNewsletter" className="form-check-label">
                                                    <FormattedMessage
                                                        id="SUBSCRIBE_TO_NEWSLETTER"
                                                        values={{
                                                            link: (chunks: any) => <a rel='noopener noreferrer' target='_blank' href={formatMessage({ id: 'PRIVACY_URL' })}><b>{chunks}</b></a>
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                            {form.values.signUpNewsletter === true &&
                                                <div className="card ml-3 mt-3 mb-3">
                                                    <div className="card-body">
                                                        <FormattedMessage id="PLEASE_CHOOSE_BRAND" /><br />
                                                        {appService.salesforceBrands.map(brand =>
                                                            <div className="form-check form-check-inline" key={brand.key}>
                                                                <Field
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name="signUpBrandOfInterest"
                                                                    id={brand.key}
                                                                    value={brand.key}
                                                                />
                                                                <label htmlFor={brand.key} className="form-check-label">
                                                                    {brand.value}
                                                                </label>
                                                            </div>
                                                        )}
                                                        <ErrorMessage name="signUpBrandOfInterest" component="span">
                                                            {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'BRAND' }) })}</span>}
                                                        </ErrorMessage>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-check">
                                                <Field
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="signUpProfiling"
                                                    id="signUpProfiling"
                                                />
                                                <label htmlFor="signUpProfiling" className="form-check-label">
                                                    <FormattedMessage id="PROFILING" />
                                                </label>
                                            </div>
                                            <div className="form-group mt-4">
                                                {isSaving &&
                                                    <button className="btn btn-success btn-block disabled" type="button" disabled>
                                                        <Spinner /><FormattedMessage id="SAVING" />...
                                                    </button>
                                                }
                                                {isSaving === false &&
                                                    <button type="submit" className="btn btn-success btn-block">
                                                        <FormattedMessage id="SAVE" />
                                                    </button>
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Formik>
    )
}

export default SignUp;
import React from 'react';
import UserAccountProvider from './UserAccountContext';
import UserAccount from './UserAccount';


let Index = () => {
    return (
        <UserAccountProvider>
            <UserAccount />
        </UserAccountProvider>
    )
}

export default Index;
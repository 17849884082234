import React, { useState, useEffect } from 'react';
import { KeyValuePair } from 'helpers';
import axios from 'axios';


interface IApplicationContext {
    warrantyCountries: KeyValuePair<string, string>[],
    salesforceBrands: KeyValuePair<string, string>[],
    countries: KeyValuePair<string, string>[],
    currentCountry: string,

    chooseCountry: (country: string) => void
}

export let applicationContext = React.createContext({} as IApplicationContext);

let { Provider } = applicationContext;

let ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let [countries, setCountries] = useState([] as KeyValuePair<string, string>[]);
    let [currentCountry, setCountry] = useState(localStorage.getItem("warranty.country") || 'XX');

    let warrantyCountries: KeyValuePair<string, string>[] = [
        new KeyValuePair<string, string>('XX', "Global"),
        new KeyValuePair<string, string>('AU', "Australia"),
        new KeyValuePair<string, string>('CN', "China"),
        new KeyValuePair<string, string>('FR', "France"),
        new KeyValuePair<string, string>('DE', "Germany"),
        new KeyValuePair<string, string>('HK', "Hong Kong"),
        new KeyValuePair<string, string>('IT', "Italy"),
        new KeyValuePair<string, string>('JP', "Japan"),
        new KeyValuePair<string, string>('ES', "Spain"),
        new KeyValuePair<string, string>('GB', "United Kingdom"),
        new KeyValuePair<string, string>('US', "United States")
    ];

    let salesforceBrands: KeyValuePair<string, string>[] = [
        new KeyValuePair<string, string>("Manfrotto", "Manfrotto"),
        new KeyValuePair<string, string>("JOBY", "JOBY"),
        new KeyValuePair<string, string>("Gitzo", "Gitzo"),
        new KeyValuePair<string, string>("Lowepro", "Lowepro"),
    ];


    useEffect(() => {
        prepareCountries();
    }, []);

    let prepareCountries = async () => {
        try {
            let res = await axios.get('/api/ReferenceData/GetCountries');
            setCountries(res.data.items);
            getApplicationData();
        } catch (err) { }
    }

    let getApplicationData = async () => {
        let storageCountry = localStorage.getItem("warranty.country");

        if (!storageCountry) {
            let headerCountry = window.navigator.language.substring(window.navigator.language.lastIndexOf('-') + 1);
            let country = countries.find(x => x.key === headerCountry);

            chooseCountry(country?.key || 'XX');
        } else {
            if (isValidCountry(storageCountry) === false)
                chooseCountry('XX');
        }
    }

    let chooseCountry = (country: string) => {
        if (isValidCountry(country) === false)
            country = 'XX';

        setCountry(country);
        localStorage.setItem("warranty.country", country);
    }

    let isValidCountry = (country: string) => {
        return warrantyCountries.findIndex(x => x.key === country) !== -1;
    }


    return (
        <Provider value={{
            currentCountry,
            countries,
            warrantyCountries,
            salesforceBrands, 

            chooseCountry
        }}>
            {children}
        </Provider >
    )
}

export default ApplicationProvider;
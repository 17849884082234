export let IT = {
    GENERAL_INFO: "Informazioni Generali",
    TITLE_1: "Quali informazioni sono necessarie per registrare il mio prodotto?",
    INFORMATION_NEEDED: "Preparati a fornire le seguenti informazioni",
    PACKAGE: "Confezione originale",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "Indirizzo email e numero di telefono",
    SHIPPING_ADDRESS: "Indirizzo di spedizione",
    PRODUCT_NAME: "Nome del prodotto",
    QRCODE: "Codice QR",
    TITLE_2: "Che cosa succede se non ho la confezione del prodotto o se ho perso il mio codice QR?",
    QRCODE_LOSS: "In caso di smarrimento del QR Id, è possibile richiedere una nuova etichetta QR (QR + ID).",
    GIVE_SOME_INFORMATIONS: "Si prega di fornire le seguenti informazioni accompagnate dalla prova di acquisto e genereremo un nuovo ID QR per il prodotto",
    PRODUCT_INFORMATIONS: "Informazioni sul prodotto che si cerca di registrare",
    BRAND: "Brand",
    PRODUCT_CODE: "Codice Prodotto",
    SKU_EAN_UPC: "Codice/EAN/UPC Prodotto",
    SERIAL_NUMBER_AVAILABLE: "Numero di serie (se disponibile)",
    BUY_COUNTRY: "Nazione di acquisto",
    DEALER_NAME: "Nome del Rivenditore",
    BUY_DATE: "Data di acquisto del prodotto",
    PERSONAL_INFORMATIONS: "Informazioni personali",
    FIRSTNAME: "Nome",
    LASTNAME: "Cognome",
    EMAIL: "email",
    COUNTRY_RESIDENCE: "Nazione di residenza",
    PROOF_OF_PURCHASE: "Prova d'acquisto",
    SALES_INVOICE: "Fattura di vendita",
    SECURITY_LABEL: "Etichetta di sicurezza",
    SUPPORT: "Supporto",
    TITLE_3: "Che cosa succede se si verifica un problema o un errore durante il processo di registrazione?",
    SUPPORT_HOW_TO: "Se i problemi si sono verificati al momento della registrazione del prodotto o dell'estensione della garanzia, fornisca una sintesi del problema e di seguito le informazioni nel seguente modo",
    REGISTER_WARRANTY: "Registra Garanzia",
    MY_PRODUCTS: "I miei prodotti",
    FORGOT_PASSWORD: 'Password dimenticata?',
    PROBLEM_DESCRIPTION: "Descrizione del problema",
    COPY_MESSAGE: "Copia completamente il messaggio di errore che vedi sul sito web",
    MODEL: "Modello",
    NEW_ACCOUNT: "Registrati",
    FAQ: "FAQ",
    NAV_SUPPORT: "Supporto",
    DISCOVER_MORE: "Scopri di più",
    LOGOUT: "Logout",
    ACCOUNT: "Account",
    LOGIN: "Login",
    START_HERE: "Inizio!",
    COMPLETED: "Completato",
    LOGIN_OR_REGISTER: "Effettua il login o registrati per ottenere l'estensione della garanzia Videndum Media Solutions per i tuoi prodotti",
    CHANGE_PRODUCT: "Cambia prodotto",
    REGISTER: "Register",
    LOGIN_TO_WARRANTY: "Entra in warranty",
    SUPPORT_SUBTITLE: 'Inviaci le tue domande cliccando sui pulsanti qua sotto. Il nostro servizio clienti sarà lieto di risponderti.',
    NAME: 'Nome',
    COUNTRY: 'Nazione',
    REQUEST_TYPE: 'Tipologia di richiesta',
    REQUEST: 'Richiesta',
    SEND_REQUEST: 'Invia richiesta',
    NEED_HELP: "Hai bisogno di aiuto?",
    NEED_HELP_DESCRIPTION: "Il nostro servizio clienti è qui per aiutarti con qualsiasi problema tu possa incontrare. Puoi visitare la nostra sezione FAQ o contattarci direttamente.",
    SITE_TERMS: "Termini del sito",
    PRIVACY_POLICY: "Privacy Policy",
    COOKIES_POLICY: "Cookies Policy",
    COOKIES_URL: "https://www.videndummediasolutions.com/it/cookie-policy/",
    MENU: "Menu",
    CUSTOMER_EXPERIENCE: "La tua Videndum Media Solutions Customer Experience",
    WELCOME_MESSAGE: "BENVENUTO NEL MODULO ONLINE DI REGISTRAZIONE PRODOTTI",
    HOMEPAGE_MESSAGE: "Usa questo sito per registrare i tuoi prodotti <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>Colorama</strong>, <strong>National Geographic</strong> e <strong>Avenger</strong>.",
    DISCOVER: "Scopri tutti i servizi che offriamo.",
    REGISTER_PRODUCT: "Registra prodotto",
    ACCOUNT_TITLE: 'Il tuo account',
    ACCOUNT_SUBTITLE: 'Clicca qui per modificare le informazioni di base e gli interessi.',
    MYPROFILE: 'Il mio profilo',
    CHANGE_PASSWORD: 'Cambia password',
    CHANGE_EMAIL: 'Cambia email',
    ADDRESS: 'Indirizzo',
    CITY: 'Città',
    COUNTRY_REGION: 'Nazione',
    ZIPCODE: 'CAP',
    STATE: 'Provincia',
    PHONE: 'Telefono',
    SUBSCRIBE_TO_NEWSLETTER: "Mi iscrivo per ricevere newsletters gratuite, comunicazioni commerciali e di marketing ed ho letto, compreso ed accettato la <link>l'informativa sui dati personali.</link>",
    PRIVACY_READ: "Letta <link>l'informativa sui dati personali:</link>",
    PRIVACY_URL: "https://www.videndummediasolutions.com/it/privacy-policy/",
    SAVE: 'Salva',
    SAVING: 'Salvataggio in corso',
    FIELD_REQUIRED: "Il campo {field} è obbligatorio",
    NEWEMAIL: "Nuova email",
    NEWEMAIL_CONFIRMATION: "Conferma la nuova email",
    PASSWORD: "Password",
    OLDPASSWORD: "Vecchia password",
    NEWPASSWORD: "Nuova password",
    NEWPASSWORD_CONFIRMATION: "Conferma nuova password",
    RECEIPT_UPLOADED: "Ricevuta caricata",
    RECEIPT_NOT_UPLOADED: "Ricevuta non caricata",
    CHOOSE_COUNTRY: "Seleziona una nazione",
    CHOOSE_BRAND: "Seleziona un brand",
    COMPLETE_ACCOUNT_TITLE: "Il tuo account non è completo",
    COMPLETE_ACCOUNT_SUBTITLE: "Per favore inserire i dati obbligatori per procedere alla registrazione dei prodotti.",
    BOUGHT_ONLINE: "Ho comprato il prodotto online",
    BOUGHT_IN_STORE: "Ho comprato il prodotto presso un negozio",
    THANK_YOU: "Grazie",
    FOR_REGISTERING: "{firstName} {lastName} per aver registrato il tuo prodotto!",
    PRODUCT_REGISTERED: "La garanzia del prodotto {sku} è stata estesa di {months} mesi",
    WARRANTY_EXTENSION: "mesi di garanzia",
    EXTENSION: "Estensione",
    NEW_EXPIRATION: "Nuova data di scadenza",
    PURCHASED_FROM: "Acquistato da",
    TERMS_AND_CONDITIONS: "Vedi tutti i Termini e Condizioni",
    DOWNLOAD_WARRANTY: "Clicca qui per scaricare la certificazione della garanzia",
    REGISTER_ANOTHER_PRODUCT: "Registra un altro prodotto",
    GOTO_PRODUCT_LIST: "Vai alla tua lista prodotti",
    WELCOME_USER: 'Benvenuto {name}',
    EDIT_ACCOUNT: "Modifica account",
    CHANGE_PURCHASEDATE_RECEIPT: "Modifica la data di acquisto o carica un'altra ricevuta",
    PURCHASE_DATE: "Data di acquisto",
    REGISTER_PRODUCT_TO_EXTEND: "Registra il prodotto ed estendi la garanzia",
    INSERT_PRODUCT_CODE: "Inserisci il codice del tuo prodotto per iniziare la registrazione",
    SAVE_AND_PROCEED: "Salva e procedi",
    DOWNLOAD_RECEIPT: "Scarica la ricevuta",
    SERIAL_NUMBER: "Numero di serie",
    STORE: "Negozio",
    RECEIPT: "Ricevuta",
    REGISTERED_ON: "Registrato il",
    PURCHASED_ON: "Comprato il",
    YOUR_PRODUCT_LIST: "La tua lista di prodotti",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "Tutti i prodotti che hai registrato sono visualizzati qui.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "Per registrare un nuovo prodotto, inizia semplicemente la procedura standard di registrazione.",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "Nota: assicurati di conservare sempre le ricevute e i documenti originali come prova d'acquisto",
    ADD_PURCHASEDATE_AND_RECEIPT: "Aggiungi la data di acquisto e l'eventuale ricevuta",
    PLEASE_UPLOAD_RECEIPT: "Carica la tua ricevuta per richedere l'estensione garanzia.",
    UPLOAD_RECEIPT: "Carica la ricevuta",
    UPLOADING: "Caricamento",
    CHOOSE_COUNTRY_AND_STORE: "Seleziona la nazione e il negozio di acquisto",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "Per favore, aggiungi il nome del negozio e il paese in cui hai acquistato il tuo prodotto.",
    NO_OFFICIAL_STORE_FOUND: "Nessun negozio ufficiale trovato.",
    PROCEED_ANYWAY: "Procedi comunque con il nome del negozio inserito.",
    EXTENSION_FOUND: "{months} mesi di estensione garanzia",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "Inserisci il codice del prodotto e scopri quale garanzia è disponibile",
    DISCOVER_WARRANTY: "Scopri quale garanzia è disponibile",
    LOCATE_PRODUCT_CODE: "Individuare il codice prodotto",
    LOCATE_PRODUCT_CODE_CLICK: "Clicca qui per leggere come recuperare il codice prodotto",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "Puoi trovare il Codice del tuo prodotto sulla confezione. Guarda l'immagine a sinistra per capire dove si trova.",
    LOCATING_EAN_UPC: "Individuazione del codice a barre EAN o UPC",
    LOCATING_EAN_UPC_DESCRIPTION: "Puoi trovare il codice EAN sulla confezione. Guarda l'immagine a sinistra per capire dove si trova.",
    INSERT_PRODUCT_QR_CODE: "Inserisci il QR Code del prodotto",
    LOCATE_PRODUCT_QR_CODE: "Il codice QR si trova sulla confezione del prodotto. Inserisci il codice alfanumerico sotto il segno del codice QR. Se il tuo codice QR manca o è stato manomesso, contatta l'assistenza clienti",
    NORMAL_PRODUCT_QR_CODE: "Normal QR code",
    NORMAL_PRODUCT_QR_CODE_DESC: "QR Code should be in-tact and free of extra markings with the alphanumeric code clearly visible.",
    TAMPERED_PRODUCT_QR_CODE: "Tampered with QR codes",
    TAMPERED_PRODUCT_QR_CODE_DESC: "If your QR Code is missing, has extra markings or is rubbed out, please use the link below so that we can assist you.",
    PRODUCT_ELIGIBLE_FOR: "Il prodotto permette",
    NO_REGISTERED_PRODUCTS: "Non hai ancora registrato nessun prodotto",
    SUPPORTS: "Supporti",
    CAMERA_ACCESSORIES: "Accessori per la tua fotocamera",
    AUDIO_CAPTURE: "Dispositivi Audio",
    BAGS: "Borse",
    MOBILE_POWER: "Mobile Power",
    VIDEO_TRANSMISSION_SYSTEMS: "Sistemi di trasmissione video",
    PROMPTERS: "Prompters",
    MOTION_CONTROL: "Motion Control",
    DISTRIBUTION_RENTAL_SERVICES: "Distribuzione, noleggio e servizi",
    LIGHTING_AND_CONTROLS: "Luci e strumenti controllo luce",
    ROBOTIC_CAMERA_SYSTEMS: "Robotic camera systems",
    MONITORS: "Monitor",
    OUR_BRANDS: "I nostri brand",
    DEALER_MANDATORY: "Il negozio non è stato selezionato",
    REGISTER_ACCOUNT_TITLE: "Registra un nuovo account",
    PASSWORD_CONFIRMATION: "Conferma la password",
    SEND_CODE: 'Invia codice',
    CODE: 'Codice',
    RESET_PASSWORD: 'Reimposta la password',
    RESET_PASSWORD_SUBTITLE: 'Per favore inserire il codice ricevuto via mail e la nuova password',
    RESET_PASSWORD_NEEDED: "E' necessario reimpostare la password prima di procedere",
    SIGNUP_CONFIRM: 'Conferma registrazione',
    NOT_REGISTERED: "Non registrato?",
    NOT_REGISTERED_SUBTITLE: "Clicca sul pulsante sotto per creare un nuovo account.",
    SIGNUP_CONFIRMED: "Il tuo account è ora valido, clicca su login per accedere al tuo account",
    CONFIRM: "Conferma",
    LOGIN_WITH_CREDENTIALS: "Accedi con il tuo account Garanzia Videndum",
    LOGIN_WITH_SOCIAL: "Accedi con un profilo social",
    UNLIMITED_WARRANTY: "Garanzia illimitata",
    VERIFICATION_CODE_NOT_ARRIVED: "Il codice di verifica non è arrivato?",
    CLICK_ON_BUTTON: "Clicca sul pulsante sotto per inviarne uno nuovo.",
    SEND: "Invia codice",
    PLEASE_CHOOSE_BRAND: "Selezionare almeno un brand di preferenza.",
    PROFILING: "Mi iscrivo per ricevere comunicazioni relative a news ed offerte sui prodotti personalizzate sulla base dei miei interessi tratti dalle mie abitudini d'acquisto, dalle mie preferenze e dalla navigazione sul sito.",
    MANAGE_SUBSCRIPTIONS: "Clicca {link} per gestire le tue sottoscrizioni",
    COOKIE_CONSENT: "Si prega di {link} per vedere questo video",
    ACCEPT_STATISTICS_COOKIES: "accettare i cookie di statistica",
    HERE: "qui",
    QRCODE_NOT_VALID: "QR Code non valido",
    DOWNLOAD_AREA: "Download area",
    DOWNLOADABLE_CONTENTS: "Contenuti scaricabili", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Libreria di Sfondi Digitali", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "Questo portale di download è riservato ai clienti che hanno acquistato il programma informatico Savage Green Screen Wizard Lite per applicazioni di chroma key. Incluso nel nostro software c'è l'accesso esclusivo alla nostra robusta libreria di immagini digitali di alta qualità che conta 3.500 sfondi per l'uso illimitato.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Gli utenti possono scegliere di scaricare tutte le categorie sul proprio dispositivo in una volta sola, o di tornare su questa pagina in futuro semplicemente effettuando l'accesso a questo portale con le loro credenziali di account SavageUniversal.com.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Utilizzatori di Savage Green Screen Wizard Lite", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "Questo portale di download è riservato ai clienti che hanno acquistato il programma informatico Savage Green Screen Wizard Lite per applicazioni di chroma key. Incluso nel nostro software c'è l'accesso esclusivo alla nostra robusta libreria di immagini digitali di alta qualità che conta 3.500 sfondi per l'uso illimitato.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Per favore, utilizza il pulsante qui sotto per registrare il tuo numero di serie unico incluso nel pacchetto di prodotto per avere accesso alla libreria.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Qui puoi trovare i tuoi contenuti scaricabili.", 
    ADD_SERIAL_NUMBER: "Aggiungi il numero di serie", 
    CANCEL: "Annulla", 
    DOWNLOAD: "Download", 
    YOUR_SERIAL_NUMBER: "Il tuo numero di serie: {serialNumber}", 

    INFO_DATA_SAVED: "Dati salvati",
    INFO_REQUEST_SENT: "Richiesta inviata",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "Solo gli utenti non Manfrotto possono cambiare la password.",
    INFO_USER_ACCOUNT_SAVED: "Dai utente salvati",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "Sicuro di voler modificare la tua email?",
    INFO_CONFIRM_REGISTRATION: "Confermi di voler registrare questo prodotto?",
    INFO_CONFIRM_SERIAL_NUMBER: "Confermi di voler registrare questa serial number?",
    INFO_SERIAL_NUMBER_REGISTERED: "Numero di serie registrato.",

    ERR_USER_ALREADY_EXIST: "Utente già registrato",
    ERR_CHECK_PRIVACY: "Devi selezionare il pulsante Privacy",
    ERR_USER_NOT_FOUND: "Utente non trovato",
    ERR_PASSWORD_REQUIRED: "Password richiesta",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "Errore durante il cambio password",
    ERR_PASSWORD_MISMATCH: "Le password indicate non coincidono",
    ERR_OLDPASSWORD_REQUIRED: "Indicare la vecchia password",
    ERR_NEWPASSWORD_REQUIRED: "Indicare la nuova password",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "Indicare la conferma della nuova password",
    ERR_CAPTCHA_ISREQUIRED: "Per favore inserire il captcha",
    ERR_NEWEMAIL_REQUIRED: "La nuova email non è valida",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "La conferma della nuova email non è valida",
    ERR_PASSWORD_NOT_VALID: "Password non valida",
    ERR_EMAIL_DONOT_MATCH: "Le email non coincidono",
    ERR_PRODUCT_NOT_FOUND: "Prodotto non trovato",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "Gli utenti Videndum non possono cambiare la password",
    ERR_RESET_PASSWORD_FAILED: "Impossibile resettare la password",
    ERR_UNABLE_TO_UPDATE_USER: "Impossibile aggiornare i dati utente.",
    ERR_BRANDS_MANDATORY: "Almeno un brand dev'essere specificato.",
    ERR_INVALID_SERIAL_NUMBER: "Specificare un numero di serie valido.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Numero di serie non trovato.",

    AVAILABLE_ONLY_ON: "Disponibile solo su",
    GITZO_LEGENDE: "GITZO Légende",
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "Gitzo Légende, Iscriviti all'offerta esclusiva su Indiegogo",
    LEGENDE_TRIPOD_AND_BACKPACK: "Treppiede e zaino Légende",
    LEGENDE_UNLIMITED_WARRANTY: "Garanzia illimitata",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "riparazione o sostituzione per un'avventura senza fine.",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "Tutti i treppiedi e gli zaini Légende ordinati su Indiegogo hanno garanzia illimitata, in condizioni di uso normale. Se si riscontrano difetti di materiale o nella lavorazione, lo ripareremo, invieremo una nuova unità o la sostituiremo, gratuitamente.",
    LEGENDE_READ_TC: "Termini e Condizioni",
    LEGENDE_TRIPOD: "Treppiede Légende",
    LEGENDE_TRIPOD_SUB: "Il miglior treppiede di tutti i tempi, sempre riparabile.",
    LEGENDE_100_PARTS_REPLACEABLE: "100% delle parti sostituibili sempre riparabile",
    LEGENDE_70_REPLACEABLE: "70% delle parti sostituibili dall’utente",
    LEGENDE_SELF_SERVICEABLE: "Riparabile dall’utente",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "Riparabile dall'utente, per prestazioni ottimali per tutta la sua durata.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Semplici operazioni di manutenzione aiutano a mantenere Légende in perfetta forma, ovunque lo porti.",
    LEGENDE_BACKPACK: "Zaino Légende",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Progettato da mani esperte per il fotografo di viaggi, realizzato ad arte per un futuro sostenibile.",
    LEGENDE_65_RECYCLED: "65% di materiali riciclati",
    LEGENDE_ECOFRIENDLY_LEATHER: "Eco-pelle",
    LEGENDE_HOWTO_CARE_BACKPACK: "Come prendersi cura dello zaino Légende.",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Segui questi passi fondamentali per la cura dello zaino Légende.",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/it-it/customer-service/garanzia-termini-condizioni/"
}
import { FormattedMessage } from 'react-intl';

let Faq = () => {
    return (
        <div className="bg-white pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 col-lg-8 mx-auto">
                        <div className="card w-100 mb-5">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted">
                                    <FormattedMessage id="GENERAL_INFO" />
                                </h6>
                                <h5 className="card-title mb-2 ">
                                    <FormattedMessage id="TITLE_1" />
                                </h5>
                                <p><FormattedMessage id="INFORMATION_NEEDED" /></p>
                                <ul>
                                    <li><FormattedMessage id="PACKAGE" /></li>
                                    <li><FormattedMessage id="PROOF_OF_PURCHASE" /></li>
                                    <li><FormattedMessage id="EMAIL_ADDRESS_AND_PHONE_NUMBER" /></li>
                                    <li><FormattedMessage id="SHIPPING_ADDRESS" /></li>
                                    <li><FormattedMessage id="PRODUCT_NAME" /></li>
                                </ul>
                            </div>
                        </div>

                        <div className="card w-100 mb-5">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted"><FormattedMessage id="QRCODE" /></h6>
                                <h5 className="card-title mb-2 ">
                                    <FormattedMessage id="TITLE_2" />
                                </h5>
                                <p className="card-text"><FormattedMessage id="QRCODE_LOSS" /></p>
                                <p><FormattedMessage id="GIVE_SOME_INFORMATIONS" /></p>
                                <p><strong><FormattedMessage id="PRODUCT_INFORMATIONS" /></strong></p>
                                <ul>
                                    <li><FormattedMessage id="BRAND" /></li>
                                    <li><FormattedMessage id="PRODUCT_CODE" /></li>
                                    <li><FormattedMessage id="SERIAL_NUMBER_AVAILABLE" /></li>
                                    <li><FormattedMessage id="BUY_COUNTRY" /></li>
                                    <li><FormattedMessage id="DEALER_NAME" /></li>
                                    <li><FormattedMessage id="BUY_DATE" /></li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong><FormattedMessage id="PERSONAL_INFORMATIONS" /></strong></p>
                                <ul>
                                    <li><FormattedMessage id="FIRSTNAME" /></li>
                                    <li><FormattedMessage id="LASTNAME" /></li>
                                    <li><FormattedMessage id="EMAIL" /></li>
                                    <li><FormattedMessage id="COUNTRY_RESIDENCE" /></li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong><FormattedMessage id="PROOF_OF_PURCHASE" /></strong></p>
                                <ul>
                                    <li><FormattedMessage id="SALES_INVOICE" /></li>
                                    <li><FormattedMessage id="SECURITY_LABEL" /></li>
                                </ul>
                            </div>
                        </div>

                        <div className="card w-100 mb-5">
                            <div className="card-body">
                                <h6 className="card-subtitle text-muted"><FormattedMessage id="SUPPORT" /></h6>
                                <h5 className="card-title mb-2 ">
                                    <FormattedMessage id="TITLE_3" />
                                </h5>
                                <p><FormattedMessage id="SUPPORT_HOW_TO" /></p>
                                <p><strong><FormattedMessage id="PROBLEM_DESCRIPTION" /></strong></p>
                                <p><FormattedMessage id="COPY_MESSAGE" /></p>
                                <p>&nbsp;</p>
                                <p><strong><FormattedMessage id="PRODUCT_INFORMATIONS" /></strong></p>
                                <ul>
                                    <li><FormattedMessage id="BRAND" /></li>
                                    <li><FormattedMessage id="MODEL" /></li>
                                    <li><FormattedMessage id="SERIAL_NUMBER_AVAILABLE" /></li>
                                    <li><FormattedMessage id="QRCODE" /></li>
                                    <li><FormattedMessage id="BUY_COUNTRY" /></li>
                                    <li><FormattedMessage id="DEALER_NAME" /></li>
                                    <li><FormattedMessage id="BUY_DATE" /></li>
                                </ul>
                                <p>&nbsp;</p>
                                <p><strong><FormattedMessage id="PERSONAL_INFORMATIONS" /></strong></p>
                                <ul>
                                    <li><FormattedMessage id="FIRSTNAME" /></li>
                                    <li><FormattedMessage id="LASTNAME" /></li>
                                    <li><FormattedMessage id="EMAIL" /></li>
                                    <li><FormattedMessage id="COUNTRY_RESIDENCE" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
import React from 'react';
import { FormattedMessage } from 'react-intl';


let LocateQrCode = () => {
    return (
        <div className="card mt-2 mb-5 border-warning">
            <div className="card-body">
                <div className="container-fluid pt-4 pb-4 ">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <img src="/images/product-packaging/QR-code-good-example.jpg" className="img-fluid" alt="" />
                                </div>
                                <div className="col-8">
                                    <strong><FormattedMessage id="NORMAL_PRODUCT_QR_CODE" /></strong>
                                    <p><FormattedMessage id="NORMAL_PRODUCT_QR_CODE_DESC" /></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <img src="/images/product-packaging/QR-code-bad-example.jpg" className="img-fluid" alt="" />
                                </div>
                                <div className="col-8">
                                    <strong><FormattedMessage id="TAMPERED_PRODUCT_QR_CODE" /></strong>
                                    <p><FormattedMessage id="TAMPERED_PRODUCT_QR_CODE_DESC" /> <a href="/support"><u><FormattedMessage id="NAV_SUPPORT" /></u></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocateQrCode;

import 'css/page/account.scss';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { authContext } from 'helpers/services';
import * as yup from 'yup';
import { Formik, Field, ErrorMessage, Form } from 'formik';


let ForgotPassword = () => {
    let history = useHistory();

    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let formSchema = yup.object({
        email: yup.string().default('').required()
    })


    return (
        <div className="accountpanel">
            <div className="bg-white p-0">
                <div className="container py-4 px-5">
                    <div className="row">
                        <div className="col-12 pb-4">
                            <h4 className="pb-4">{formatMessage({ id: "FORGOT_PASSWORD" })}</h4>
                            <Formik
                                validationSchema={formSchema}
                                initialValues={formSchema.getDefault()}
                                onSubmit={async (values) => {
                                    let res = await authService.forgotPassword(values.email);
                                    if (res === true)
                                        history.push('/account/resetpassword', { email: values.email });
                                }}
                            >
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="textinput">{formatMessage({ id: "EMAIL" })}</label>
                                        <Field
                                            className="form-control"
                                            disabled={authService.isLoading}
                                            type="email"
                                            name="email"
                                        />
                                        <ErrorMessage name="email" component="span">
                                            {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'EMAIL' }) })}</span>}
                                        </ErrorMessage>
                                    </div>

                                    <button
                                        style={{ float: 'right' }}
                                        className="btn btn-success btn-block"
                                        type="submit"
                                    >
                                        {formatMessage({ id: "SEND_CODE" })}
                                    </button>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ForgotPassword;
import { useContext } from 'react';
import { useHistory } from 'react-router';
import Moment from 'react-moment';
import { Media, Spinner } from 'components';
import { FormattedMessage } from 'react-intl';
import { newRegistrationContext } from '../NewRegistrationContext';
import { authContext } from 'helpers/services';


let RegistrationCompleted = () => {
    let history = useHistory();

    let authService = useContext(authContext);
    let service = useContext(newRegistrationContext);


    return (
        <div id="regw">
            {service.isLoading && <Spinner />}
            {service.isLoading === false &&
                <div className="text-center container pb-5">
                    <div className="loader">
                        <span style={{ width: '100%' }}>
                            <span><i></i><FormattedMessage id="COMPLETED" />!</span>
                        </span>
                    </div>
                    <h1 className="hero-title pt-5">
                        <FormattedMessage id="THANK_YOU" />
                    </h1>
                    <h2>
                        <FormattedMessage
                            id="FOR_REGISTERING"
                            values={{ firstName: authService.currentUser?.firstname, lastName: authService.currentUser?.lastname }} />
                    </h2>
                    <strong className="text-success">
                        {service.registration.unlimited === true && <FormattedMessage id="UNLIMITED_WARRANTY" />}
                        {service.registration.unlimited === false &&
                            <FormattedMessage
                                id="PRODUCT_REGISTERED"
                                values={{ sku: service.registration.sku, months: service.registration.warrantyUpToMonths }} />
                        }
                    </strong>
                    <hr className="mt-5" />
                    <div className="row align-items-top">
                        <div className="col-12 col-lg-3 order-1">
                            <span className={"logo-" + service.registration.productBrand.toLowerCase() + "-lg-c logo-over-img"}>
                                <a><span></span></a>
                            </span>
                            <Media
                                searchBy="byProduct"
                                mediaKey={service.registration.sku}
                                height={150}
                                width={250}
                                applyClasses="d-inline-block mt-5"
                            />
                            <div className="row regw__blockyears pt-2 pb-2">
                                <div className="col active">
                                    <button>
                                        {service.registration.unlimited === true && <span><FormattedMessage id="UNLIMITED_WARRANTY" /></span>}
                                        {service.registration.unlimited === false &&
                                            <>
                                                <span>{service.registration.warrantyUpToMonths}</span>&nbsp;
                                                <FormattedMessage id="WARRANTY_EXTENSION" />
                                            </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col order-3 text-left pt-5">
                            <p>
                                {service.registration.sku}
                                <h3 className="mb-0">{service.registration.shortDescription}</h3>
                                <p className="text-success">
                                    <strong>
                                        {service.registration.unlimited === true && <FormattedMessage id="UNLIMITED_WARRANTY" />}
                                        {service.registration.unlimited === false &&
                                            <>
                                                <span>{service.registration.warrantyUpToMonths}</span>&nbsp;
                                                <FormattedMessage id="WARRANTY_EXTENSION" />
                                            </>
                                        }
                                    </strong>
                                </p>
                            </p>
                            <div className="table-responsive">
                                <table className="table table-borderless table-lg">
                                    {service.registration.unlimited === false &&
                                        <tr>
                                            <td className="pl-0">
                                                <FormattedMessage id="NEW_EXPIRATION" />:
                                            </td>
                                            <td>
                                                <strong>
                                                    <Moment date={service.registration.warrantyExpirationDate} format={"LL"} />
                                                </strong>
                                            </td>
                                        </tr>
                                    }
                                    {service.registration.isQrCodeMandatory &&
                                        <tr>
                                            <td className="pl-0"><FormattedMessage id="QRCODE" />:</td>
                                            <td>{service.registration.qrCode}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className="pl-0">
                                            <FormattedMessage id="PURCHASED_FROM" />:
                                        </td>
                                        <td>
                                            <strong>{service.registration.dealerName}</strong>
                                            <br />
                                            {service.registration.dealerAddress}
                                        </td>
                                    </tr>
                                    {service.registration.receiptId !== '' &&
                                        <tr>
                                            <td className="pl-0"><FormattedMessage id="RECEIPT_UPLOADED" />:</td>
                                            <td>
                                                <a href={service.registration.receiptDownloadUrl}>
                                                    <FormattedMessage id="DOWNLOAD_RECEIPT" />
                                                </a>
                                            </td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-top text-left pb-5">
                        <div className="col-lg-4 col-12 offset-lg-3">
                            <div className="regw__proceed">
                                <button
                                    className="btn btn-block btn-light"
                                    onClick={() => history.replace('/newregistration')} >
                                    <FormattedMessage id="REGISTER_ANOTHER_PRODUCT" />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 pt-lg-0">
                            <div className="regw__proceed">
                                <button
                                    className="btn btn-block btn-success"
                                    onClick={() => history.replace('/myproducts')} >
                                    <FormattedMessage id="GOTO_PRODUCT_LIST" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default RegistrationCompleted;
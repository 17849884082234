import { useContext, useState } from 'react';
import axios from 'axios';
import Progress from './Progress';
import { useHistory } from 'react-router';
import { newRegistrationContext } from '../NewRegistrationContext';
import { Spinner } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { authContext } from 'helpers/services';
import { Dictionary } from 'lodash';


interface IProductResponse {
    sku: string
    brandCode: string
    brand: string
    shortDescription: string

    mainImage: string
    isQrPresent: boolean
    isQrMandatory: boolean
    warrantyKey: string
    unlimited: boolean
    warrantyExtensionMonths: number
    warrantyLegalMonths: number
    warrantyUpToMonths: number

    countrySpecificRules: IWarrantyException[]
    warrantyTitle: string
    warrantyDescription: string
    blockUs: boolean
}

interface IWarrantyException {
    countryCode: string
    warrantyExtensionMonths: number
    warrantyLegalMonths: number
}

let ProductSearch = () => {
    let history = useHistory();
    let { formatMessage } = useIntl();

    let { currentUser } = useContext(authContext);
    let service = useContext(newRegistrationContext);

    let [isSearching, setSearching] = useState(false);
    let [search, setSearch] = useState('');


    let searchProduct = async () => {
        try {
            setSearching(true);
            let res = await axios.get<IProductResponse>('/api/Product/Search', { params: { search } });

            if (res.data) {
                let specificRuleIdx = res.data.countrySpecificRules.findIndex(x => x.countryCode === currentUser?.countryCode);

                service.updateRegistration({
                    ...service.registration,
                    sku: res.data.sku,
                    shortDescription: res.data.shortDescription,
                    isQrCodeMandatory: res.data.isQrMandatory,
                    unlimited: res.data.unlimited,

                    warrantyExtensionMonths: specificRuleIdx !== -1 && res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths
                        ? res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths
                        : res.data.warrantyExtensionMonths,

                    warrantyUpToMonths: specificRuleIdx !== -1 && (res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths || res.data.countrySpecificRules[specificRuleIdx].warrantyLegalMonths)
                        ? res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths + res.data.countrySpecificRules[specificRuleIdx].warrantyLegalMonths
                        : res.data.warrantyUpToMonths,

                    productBrand: res.data.brandCode
                });

                history.push('/newregistration/dateandreceipt');
            }
        } catch (err) {
            setSearching(false);
        }
    }


    return (
        <div className="text-center container pb-5">
            <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>
            <p><FormattedMessage id="INSERT_PRODUCT_CODE" /></p>

            <Progress />

            <form onSubmit={(e) => e.preventDefault()}>
                <div className={`regw__form text-left ${isSearching && 'loading'}`}>
                    {isSearching && <Spinner />}
                    <div className="regw regw__blockyears">
                        <label className="w-100" htmlFor="example1">
                            <FormattedMessage id="INSERT_CODE_AND_DISCOVER_WARRANTY" />
                        </label>
                        <span className="regw__form__input">
                            <img src="/images/icons/pencil.svg" alt="" />
                            <input type="text" placeholder={formatMessage({ id: "SKU_EAN_UPC" })} value={search} onChange={(e) => setSearch(e.target.value)} />
                        </span>
                    </div>
                    <div className="container-fluid pt-5 pb-5">
                        <div className="row align-items-center">
                            <div className="col-2">
                                <img src="/images/product-packaging/product-code-example.jpg" className="img-fluid" alt="" />
                            </div>
                            <div className="col ver">
                                <a href="/#">
                                    <strong>
                                        <FormattedMessage id="LOCATE_PRODUCT_CODE" />
                                    </strong>
                                    <br />
                                    <FormattedMessage id="LOCATE_PRODUCT_CODE_CLICK" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="regw__proceed">
                    <button type="submit" className="btn btn-success mx-auto" onClick={searchProduct}>
                        <FormattedMessage id="DISCOVER_WARRANTY" />
                    </button>
                </div>
            </form>
            <hr className="mt-5 mb-5" />
        </div>
    );
}

export default ProductSearch;
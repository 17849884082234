import React, { useContext } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';


let ReceiptInfo = () => {
    let service = useContext(newRegistrationContext);

    let history = useHistory();

    return (
        <div className="row justify-content-center text-left">
            <div className="col-3 text-center">
                <img src="/images/icons/check.svg" alt="" height="30px" className="mt-3" />
            </div>
            <div className="col">
                <p className="pt-3 pb-3 m-0 font-s-lg">
                    <strong><FormattedMessage id="PURCHASE_DATE" />: </strong>
                    <Moment date={service.registration.purchaseDate} format="LL" />
                    {service.registration.receiptId &&
                        <strong className="pl-lg-5 d-block d-md-inline-block text-success">
                            <FormattedMessage id="RECEIPT_UPLOADED" />
                        </strong>
                    }
                    {!service.registration.receiptId &&
                        <strong className="pl-lg-5 d-block d-md-inline-block text-warning">
                            <FormattedMessage id="RECEIPT_NOT_UPLOADED" />
                        </strong>
                    }
                </p>
            </div>
            <div className="col-2">
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => history.goBack()}
                ><FormattedMessage id="CHANGE_PURCHASEDATE_RECEIPT" /></button>
            </div>
        </div>
    )
}

export default ReceiptInfo;
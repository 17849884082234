import { useEffect, useRef } from "react";

let CookiesPolicy = () => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/0f1d463f-880d-487c-9681-3af9b0056379/cd.js";
    script.async = true;
    script.setAttribute('data-framework', 'TCFv2.2');

    let divref = useRef<any>();

    useEffect(() => {
        if (divref.current)
            divref.current.appendChild(script);
    }, [divref.current])

    return (
        <div ref={divref}></div>
    )
}

export default CookiesPolicy;
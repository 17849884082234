export let FR = {
    GENERAL_INFO: "Informations générales",
    TITLE_1: "De quelles informations ai-je besoin pour enregistrer mon produit?",
    INFORMATION_NEEDED: "Veuillez préparer les éléments suivants avant de procéder à l'enregistrement de votre produit",
    PACKAGE: "L'emballage d'origine",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "Votre adresse mail et votre numéro de téléphone",
    SHIPPING_ADDRESS: "Votre adresse postale",
    PRODUCT_NAME: "Le nom du produit",
    QRCODE: "Le code QR",
    TITLE_2: "Comment faire si je n'ai plus l'emballage du produit ou si j'ai perdu le QR code?",
    QRCODE_LOSS: "En cas de perte du QR Code, il est possible de faire la demande d'un nouveau code.(qui fournit une nouvelle identité QR).",
    GIVE_SOME_INFORMATIONS: "Veuillez nous fournir les informations suivantes ainsi que la preuve d'achat pour que nous puissions générer un nouveau QR Code pour votre produit",
    PRODUCT_INFORMATIONS: "Informations sur le produit que vous souhaitez enregistrer",
    BRAND: "Marque",
    PRODUCT_CODE: "Référence du produit",
    SERIAL_NUMBER_AVAILABLE: "Numéro de série (s'il est disponible)",
    BUY_COUNTRY: "Pays d'achat",
    DEALER_NAME: "Nom du revendeur",
    BUY_DATE: "Date d'achat",
    PERSONAL_INFORMATIONS: "Informations personnelles",
    FIRSTNAME: 'Prénom',
    LASTNAME: 'Nom',
    EMAIL: "Adresse mail",
    COUNTRY_RESIDENCE: "Pays de résidence",
    PROOF_OF_PURCHASE: "Preuve d'achat",
    SALES_INVOICE: "Facture de vente",
    SECURITY_LABEL: "étiquette de sécurité",
    SKU_EAN_UPC: "Product Code/EAN/UPC",
    SUPPORT: "Soutien",
    TITLE_3: "Comment faire si un problème se pose lors du processus d'enregistrement?",
    SUPPORT_HOW_TO: "Si un problème se pose lors du processus d'enregistrement ou lors de la mise en place de l'extension de garantie, veuillez nous transmettre un récapitulatif du problème rencontré ainsi que les informations suivantes",
    PROBLEM_DESCRIPTION: "Description du problème",
    COPY_MESSAGE: "Veuillez copier entièrement le message d'erreur que vous voyez sur le site Web",
    MODEL: "modèle",
    REGISTER_WARRANTY: "Extension de garantie",
    MY_PRODUCTS: "Mes produits",
    FAQ: "FAQ",
    NAV_SUPPORT: "Support",
    DISCOVER_MORE: "En savoir plus",
    LOGOUT: "Déconnexion",
    ACCOUNT: "Compte",
    LOGIN: "Se connecter",
    REGISTER: "Register",
    START_HERE: "Start here!",
    COMPLETED: "Terminé",
    LOGIN_OR_REGISTER: "Log-in or register now to obtain the Videndum Media Solutions warranty extension on your products",
    CHANGE_PRODUCT: "Changer de produit",
    SUPPORT_SUBTITLE: 'Pour toute question, veuillez cliquer sur le bouton ci-dessous pour nous contacter. Un membre de notre équipe locale vous répondra le plus rapidement possible.',
    NAME: 'Nom',
    COUNTRY: 'Pays',
    REQUEST_TYPE: 'type de requête',
    REQUEST: 'requête',
    SEND_REQUEST: 'Envoyer une requête',
    NEED_HELP: "Avez vous besoin d'aide?",
    NEED_HELP_DESCRIPTION: "Notre équipe de service à la clientèle est là pour vous aider avec toutes les questions que vous pourriez rencontrer.  Vous pouvez soit visiter notre section FAQ soit nous contacter directement.",
    SITE_TERMS: "Conditions du site",
    PRIVACY_POLICY: "Politique de confidentialité",
    PRIVACY_READ: "J'ai lu <link>la note informative sur le traitement des données</link> à caractère personnel:",
    PRIVACY_URL: "https://www.videndummediasolutions.com/fr/politique-de-confidentialite/",
    COOKIES_POLICY: "Politique des cookies",
    COOKIES_URL: "https://www.videndummediasolutions.com/fr/politique-sur-les-cookies/",
    MENU: "Menu",
    CUSTOMER_EXPERIENCE: "Votre expérience client chez Videndum Media Solutions. ",
    WELCOME_MESSAGE: "BIENVENUE SUR LA PLATEFORME EN LIGNE D’ENREGISTREMENT DE PRODUITS",
    HOMEPAGE_MESSAGE: "Veuillez utiliser ce site pour enregistrer vos produits <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>Colorama</strong>, <strong>National Geographic</strong> et <strong>Avenger</strong>.",
    DISCOVER: "Découvrez la gamme de services que nous offrons ci-dessous.",
    REGISTER_PRODUCT: "Enregistrer un product",
    ACCOUNT_TITLE: 'Votre compte',
    ACCOUNT_SUBTITLE: 'Modifier les informations ici.',
    MYPROFILE: 'Mon compte',
    CHANGE_PASSWORD: 'Changer de mot de passe',
    CHANGE_EMAIL: "Changer l'email",
    ADDRESS: 'Adresse',
    CITY: 'Ville',
    COUNTRY_REGION: 'Pays',
    ZIPCODE: 'Code postal',
    STATE: 'Région',
    PHONE: 'Téléphone',
    SUBSCRIBE_TO_NEWSLETTER: "Je m'inscris pour recevoir les newslettes gratuites, des communications commerciales et de marketing et j'ai lu, compris et accepté <link>la note informative sur le traitement des données.</link>",
    PROFILING: "Je m'inscris pour recevoir des informations et des offres personnalisées sur des produits en accord avec mes intérêts, mes préférences et mes habitudes de navigation sur votre site Web.",
    SAVE: 'Sauvegarder',
    SAVING: 'La sauvegarde des données',
    FIELD_REQUIRED: "{field} est obligatoire",
    NEWEMAIL: "Nouvelle adresse email",
    NEWEMAIL_CONFIRMATION: "Confirmer votre nouvelle adresse email",
    PASSWORD: "Mot de passe",
    OLDPASSWORD: "Ancien mot de passe",
    NEWPASSWORD: "Nouveau mot de passe",
    NEWPASSWORD_CONFIRMATION: "Confirmer le nouveau mot de passe",
    RECEIPT_UPLOADED: "Reçu téléchargé",
    RECEIPT_NOT_UPLOADED: "Reçu non téléchargé",
    CHOOSE_COUNTRY: "Choisissez un pays",
    CHOOSE_BRAND: "Choisissez la marque",
    COMPLETE_ACCOUNT_TITLE: "Votre compte n'est pas complet",
    COMPLETE_ACCOUNT_SUBTITLE: "Veuillez compléter votre compte pour procéder à l'enregistrement des produits.",
    BOUGHT_ONLINE: "Je l'ai acheté en ligne. ",
    BOUGHT_IN_STORE: "Je l'ai acheté dans un magasin. ",
    THANK_YOU: "Merci",
    FOR_REGISTERING: "{firstName} {lastName} pour l'enregistrement de votre poduit!",
    PRODUCT_REGISTERED: " la garantie  du produit {sku} a été prolongée de {months} mois",
    WARRANTY_EXTENSION: "mois de garantie",
    EXTENSION: "Extension",
    NEW_EXPIRATION: "Nouvelle date d'expiration",
    PURCHASED_FROM: "Acheté chez",
    TERMS_AND_CONDITIONS: "Voir la version intégrale des conditions générales",
    DOWNLOAD_WARRANTY: "Cliquez ici pour télécharger la certification de garantie ",
    REGISTER_ANOTHER_PRODUCT: "Enregistrer un autre produit",
    GOTO_PRODUCT_LIST: "Accédez à votre liste de produit",
    FORGOT_PASSWORD: 'Mot de passe oublié?',
    NEW_ACCOUNT: "Nouveau compte",
    WELCOME_USER: 'Bienvenu (e) {name}',
    EDIT_ACCOUNT: "Modifier le compte",
    LOGIN_TO_WARRANTY: "Connectez vous à votre garantie",
    WELCOME_USER_SUBTITLE: "",
    CHANGE_PURCHASEDATE_RECEIPT: "Modifier la date d'achat ou tèlécharger un autre reçu",
    PURCHASE_DATE: "Date de l'achat",
    REGISTER_PRODUCT_TO_EXTEND: "Enregistrez votre produit et prolongez votre garantie",
    INSERT_PRODUCT_CODE: "Insérez votre code de produit ci-dessous pour commencer l'extension de la  garantie",
    SAVE_AND_PROCEED: "Enregistrez et procédez",
    DOWNLOAD_RECEIPT: "Téléchargez le reçu",
    SERIAL_NUMBER: "Numéro de série",
    STORE: "Magasin",
    RECEIPT: "Reçu",
    REGISTERED_ON: "Enregistré le",
    PURCHASED_ON: "Acheté le ",
    YOUR_PRODUCT_LIST: "Votre liste de produits",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "Tous les produits que vous avez enregistrés sont affichés et peuvent être modifiés ci-dessous.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "Pour enregistrer un nouveau produit, il suffit de recommencer la procédure d’enregistrement standard.",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "Remarque : Veuillez toujours conserver vos reçus et documents originaux comme  preuve d’achat.",
    ADD_PURCHASEDATE_AND_RECEIPT: "Ajouter la date d'achat et le reçu",
    PLEASE_UPLOAD_RECEIPT: "S’il vous plaît télécharger votre reçu de réclamation en utilisant votre garantie.",
    UPLOAD_RECEIPT: "Téléchargez votre reçu",
    UPLOADING: "Téléchargement",
    CHOOSE_COUNTRY_AND_STORE: "Sélectionner le pays et le magasin d’achat ",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "Veuillez indiquer le nom du magasin et le pays où vous avez acheté votre produit.",
    NO_OFFICIAL_STORE_FOUND: "Aucun magasin officiel trouvé.",
    PROCEED_ANYWAY: "Procéder de toute façon en insérant le nom du magasin.",
    EXTENSION_FOUND: "{months} mois d'extension de la garantie",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "Insérez le code produit pour découvrir quelle garantie est disponible pour ce produit.",
    DISCOVER_WARRANTY: "Découvrez quelle garantie est disponible pour ce produit.",
    LOCATE_PRODUCT_CODE: "Localisation du code produit.",
    LOCATE_PRODUCT_CODE_CLICK: "cliquez ici pour lire le code produit. ",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "Vous pouvez trouver le code de produit sur l’emballage. Veuillez consulter l’image sur la gauche pour trouver le code du produit, précisement  dans le coin supérieur gauche.",
    LOCATING_EAN_UPC: "Localisation du code  EAN ou  du bar code UPC. ",
    LOCATING_EAN_UPC_DESCRIPTION: "Vous pouvez trouver le code EAN ou UPC sur l'emballage. Veuillez consulter l'image à gauche pour trouver l'UPC / EAN prècisement sur le coin inférieur gauche.",
    INSERT_PRODUCT_QR_CODE: "Insérez le code QR du produit.",
    LOCATE_PRODUCT_QR_CODE: "Le code QR se trouve sur l’emballage de votre produit. Veuillez entrer le code alphanumérique sous le signe du code QR. Si votre code QR est manquant ou a été altéré, veuillez contacter le service client.",
    PRODUCT_ELIGIBLE_FOR: "produit admissible à",
    NO_REGISTERED_PRODUCTS: "Vous n’avez aucun produit enregistré.",
    SUPPORTS: "Supports",
    CAMERA_ACCESSORIES: "Accessoires pour appareil photo. ",
    AUDIO_CAPTURE: "Saisie audio",
    BAGS: "Bags",
    MOBILE_POWER: "Alimentation mobile",
    VIDEO_TRANSMISSION_SYSTEMS: "systèmes de transmissions vidéo.",
    PROMPTERS: "Souffleurs",
    MOTION_CONTROL: "Contrôle du mouvement ",
    DISTRIBUTION_RENTAL_SERVICES: "Distribution, location & services",
    LIGHTING_AND_CONTROLS: "éclairage et commandes",
    ROBOTIC_CAMERA_SYSTEMS: "Systèmes de caméra robotisés",
    MONITORS: "Moniteurs",
    OUR_BRANDS: "Nos marques",
    DEALER_MANDATORY: "Le magasin n'a pas été sélectionné",
    REGISTER_ACCOUNT_TITLE: "Créer un nouveau compte",
    PASSWORD_CONFIRMATION: "Confirmez le mot de passe",
    SEND_CODE: 'Send code',
    CODE: 'Code',
    RESET_PASSWORD: 'Réinitialiser le mot de passe',
    RESET_PASSWORD_SUBTITLE: 'Veuillez insérer le code que nous avons envoyé à votre courriel et à votre nouveau mot de passe.',
    RESET_PASSWORD_NEEDED: "Vous devez réinitialiser votre mot de passe pour continuer",
    SIGNUP_CONFIRM: 'Confirmez votre inscription',
    NOT_REGISTERED: "Pas encore inscrit?",
    NOT_REGISTERED_SUBTITLE: "Cliquez sur le bouton ci-dessous pour créer un nouveau compte.",
    SIGNUP_CONFIRMED: "Votre compte a été vérifié, cliquez sur le bouton de connexion pour vous connecter.",
    CONFIRM: "Confirmer",
    LOGIN_WITH_CREDENTIALS: "Connectez-vous avec le compte de garantie Videndum",
    LOGIN_WITH_SOCIAL: "Connexion avec un compte social",
    UNLIMITED_WARRANTY: "Garantie illimitée",
    VERIFICATION_CODE_NOT_ARRIVED: "Le code de vérification n'est pas arrivé?",
    CLICK_ON_BUTTON: "Cliquez sur le bouton ci-dessous pour renvoyer le code de vérification.",
    SEND: "Envoyer",
    PLEASE_CHOOSE_BRAND: "Veuillez choisir au moins une marque que vous aimez.",
    MANAGE_SUBSCRIPTIONS: "Pour gérer vos abonnements cliquez {link}",
    COOKIE_CONSENT: "Veuillez {link} pour regarder cette vidéo",
    ACCEPT_STATISTICS_COOKIES: "accepter les 'cookies de statistiques'",
    HERE: "ici",
    QRCODE_NOT_VALID: "Le code QR n'est pas valide", 
    DOWNLOADABLE_CONTENTS: "Downloadable contents", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Digital Background Library", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Users may choose to download all categories to your device at once, or revisit this page in the future by simply logging into this portal with your SavageUniversal.com account credentials.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Savage Green Screen Wizard Lite Users", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Please use the button below to register your unique serial number included in your product package to gain access to the library.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Here you can find your downloadable contents.", 
    ADD_SERIAL_NUMBER: "Add serial number", 
    CANCEL: "Cancel", 
    DOWNLOAD: "Download", 
    YOUR_SERIAL_NUMBER: "Your serial number: {serialNumber}", 

    INFO_DATA_SAVED: "Données sauvegardées",
    INFO_REQUEST_SENT: "Demande envoyée",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "Seuls les utilisateurs non Manfrotto peuvent changer de mot de passe .",
    INFO_USER_ACCOUNT_SAVED: "Compte utilisateur enregistré",
    INFO_CONFIRM_REGISTRATION: "Êtes-vous sûr que vous voulez enregistrer la garantie pour ce produit? ",
    INFO_CONFIRM_SERIAL_NUMBER: "Are you sure that you want to register this serial number?",
    INFO_SERIAL_NUMBER_REGISTERED: "Serial number registered.",

    ERR_USER_ALREADY_EXIST: "L'utilisateur existe déjà",
    ERR_CHECK_PRIVACY: "Vous devez cocher la case de confidentialité",
    ERR_USER_NOT_FOUND: "Utilisateur introuvable",
    ERR_PASSWORD_REQUIRED: "Mot de passe requis.",
    ERR_PASSWORD_MISMATCH: "Les mots de passe ne correspondent pas. ",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "Impossible de changer le mot de passe. ",
    ERR_OLDPASSWORD_REQUIRED: "L'ancien mot de passe est requis. ",
    ERR_NEWPASSWORD_REQUIRED: "Un nouveau mot de passe est requis.",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "Une nouvelle confirmation de mot de passe est requise",
    ERR_CAPTCHA_ISREQUIRED: "Veuillez résoudre le captcha",
    ERR_NEWEMAIL_REQUIRED: "Le nouvel e-mail n'est pas valide ",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "La nouvelle confirmation par e-mail n'est pas valide",
    ERR_PASSWORD_NOT_VALID: "Le mot de passe n'est pas valide. ",
    ERR_EMAIL_DONOT_MATCH: "Les courriels ne correspondent pas.",
    ERR_PRODUCT_NOT_FOUND: "Produit non trouvé",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "Les utilisateurs de Videndum ne peuvent pas changer de mot de passe.",
    ERR_RESET_PASSWORD_FAILED: "Impossible de réinitialiser votre mot de passe.",
    ERR_UNABLE_TO_UPDATE_USER: "Impossible de mettre à jour les informations de l'utilisateur.",
    ERR_BRANDS_MANDATORY: "Au moins une marque doit être spécifiée.",
    ERR_INVALID_SERIAL_NUMBER: "Please specify valid serial number.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Serial number not found.",

    AVAILABLE_ONLY_ON: "Disponible seulement sur",
    GITZO_LEGENDE: "GITZO Légende",
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "Légende GITZO : abonnez-vous pour bénéficier d’une offre exclusive sur Indiegogo",
    LEGENDE_TRIPOD_AND_BACKPACK: "Trépied et sac à dos Légende",
    LEGENDE_UNLIMITED_WARRANTY: "Garantie illimitée",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "réparation ou remplacement pour que l’aventure ne prenne jamais fin",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "Tous les trépieds et sacs à dos commandés via Indiegogo bénéficient d'une garantie illimitée, dans le cadre d’une utilisation normale. Si vous découvrez un défaut au niveau d’un matériau ou de la confection, nous nous engageons à envoyer une nouvelle pièce, à réparer ou remplacer le produit, gratuitement.",
    LEGENDE_READ_TC: "Lire les conditions",
    LEGENDE_TRIPOD: "Trépied Légende",
    LEGENDE_TRIPOD_SUB: "Notre meilleur trépied, réparable à l’infini",
    LEGENDE_100_PARTS_REPLACEABLE: "100 % des pièces remplaçables, réparable à l’infini",
    LEGENDE_70_REPLACEABLE: "70 % des pièces remplaçables par l’utilisateur",
    LEGENDE_SELF_SERVICEABLE: "Utilisateur autonome",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "La possibilité de réparer l’équipement garantit une performance optimale tout au long de sa durée de vie.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Un entretien simple mais régulier permet de maintenir le trépied Légende en parfait état, peu importe où vous l’emmenez.",
    LEGENDE_BACKPACK: "Sac à dos Légende",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Pensé pour les photographes de voyage, conçu dans le respect de la planète et pour l’avenir",
    LEGENDE_65_RECYCLED: "65 % de tissus recyclés",
    LEGENDE_ECOFRIENDLY_LEATHER: "Éco-cuir",
    LEGENDE_HOWTO_CARE_BACKPACK: "Comment entretenir son sac à dos Légende :",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Suivez ces étapes simples pour prendre soin de votre sac à dos Légende.",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/fr-fr/customer-service/extension-de-garantie/"
}
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { downloadAreaContext } from "../DownloadAreaContext";

let NewSerialNumber = () => {
    let { formatMessage } = useIntl();

    let { isSaving, error, addSerialNumber } = useContext(downloadAreaContext);

    let [isOpen, setOpen] = useState(false);
    let [search, setSearch] = useState('');


    let addSerial = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let res = await addSerialNumber(search);
        if (res === true) {
            cancel();
        }
    }

    let cancel = () => {
        setOpen(false);
        setSearch('');
    }


    return (
        <div>
            {isOpen === false &&
                <button type="button" className="btn btn-lg pr-5 pl-5 btn-success" onClick={() => setOpen(true)}>
                    {formatMessage({ id: 'ADD_SERIAL_NUMBER' })}
                </button>
            }

            {isOpen === true &&
                <form className="mt-3" onSubmit={async (e) => await addSerial(e)}>
                    <div className="row">
                        <div className="col-4">
                            <input type="text" className="form-control" placeholder={formatMessage({ id: "SERIAL_NUMBER" })}
                                value={search} onChange={(e) => setSearch(e.target.value)} disabled={isSaving}
                            />
                            {error !== '' &&
                                <span className="badge badge-pill badge-danger">{formatMessage({ id: error })}</span>
                            }
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-lg mr-2 ml-2 btn-primary" disabled={isSaving} onClick={cancel}>
                                {formatMessage({ id: 'CANCEL' })}
                            </button>
                            <button type="submit" className="btn btn-lg mr-2 ml-2 btn-success" disabled={isSaving}>
                                {formatMessage({ id: 'CONFIRM' })}
                            </button>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default NewSerialNumber;
import React from 'react';

interface IProps {
    children: React.ReactNode
}

let List = ({ children }: IProps) => {
    return (
        <ul className="list-group list-group-flush">
            {children}
        </ul>
    )
}

export default List;
import 'css/page/support.scss';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { supportContext } from './SupportContext';
import { authContext } from 'helpers/services';


let SupportRequest = () => {
    let authService = useContext(authContext);
    let service = useContext(supportContext);


    return (
        <div className="pt-5 pb-5">
            <div className="container text-center">
                <h1>
                    <FormattedMessage id="NEED_HELP" />
                </h1>
                <p className="lead">
                    <FormattedMessage id="SUPPORT_SUBTITLE" />
                </p>
                <hr className="mt-4" />
            </div>
            {service.isLoading === false &&
                <>
                    {authService.isAuthenticated === false &&
                        <div className="container pb-5 pt-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5><strong><FormattedMessage id="CHOOSE_COUNTRY" /></strong></h5>
                                </div>
                                <div className="card-body pb-3 pt-3">
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label"><FormattedMessage id="COUNTRY" /></label>
                                        <div className="col-sm-10">
                                            <select
                                                className="form-control"
                                                value={service.selectedCountry}
                                                onChange={(e: any) => service.updateCurrentCountry(e.target.value)}
                                            >
                                                {service.liveHelpCountries
                                                    .map(country => <option key={country.key} value={country.key}>{country.value}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="container pb-5">
                        <div className="card">
                            <div className="card-header">
                                <h5><strong><FormattedMessage id="CHOOSE_BRAND" /></strong></h5>
                            </div>
                            <div className="card-body pb-3 pt-3 ">
                                {service.liveHelpLinks
                                    .filter(x => x.country === service.selectedCountry)
                                    .map(liveHelp =>
                                        <div className="justify-content-center row" key={liveHelp.country}>
                                            {liveHelp.links
                                                .map(link =>
                                                    <div key={link.vitecBrand} className={`col-4 col-lg-2 col-md-3 logo-${link.vitecBrand.toLowerCase()}-lg-c`}>
                                                        <a href={link.url} target='_blank' rel="noreferrer">
                                                            <span></span>
                                                        </a>
                                                    </div>
                                                )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default SupportRequest;
import React from 'react';

interface IProps {
    children: React.ReactNode
}

let ListItem = ({ children }: IProps) => {
    return (
        <li className="list-group-item">
            {children}
        </li>
    )
}

export default ListItem;
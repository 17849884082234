import MyProductsProvider from './MyProductsContext';
import MyProducts from './MyProducts';

let Index = () => {
    return (
        <MyProductsProvider>
            <MyProducts />
        </MyProductsProvider>
    )
}

export default Index;
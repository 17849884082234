import React from 'react';
import 'css/page/graymarket.scss';

let GrayMarket = () => {
    return (
        <>
            {/*<style>
            \n.gray-market-page .marquee p {\n font-size: 1em !important;\n\tline-height: 1.4em !important;\n}\n#qr-code {\n\tbackground-color: #ededed;\n}\n.gray-market-page .border-gray {\n\tbackground-color: #fff;\n}\n-->
            </style>*/}
            <div className="gray-market-page">
                <div className="bg-light pt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                            <img src="/images/icons/hand-paper-light.svg" alt="" height="100px" />

                            </div>
                            <div className="col">
                                <h1 className="pt-4 pb-4">What are <u>gray</u> <u>market</u> products?</h1>
                                <p className="">Gray market products are imported and/or sold by anyone other than Manfrotto or a Manfrotto Authorized Dealer.</p>
                                <p className="">If you are purchasing a product from anyone other than Manfrotto or a Manfrotto Authorized Dealer, we cannot guarantee that you are getting Genuine Manfrotto Products.</p>
                                <p className="p-b-40">You have spent a lot on your camera equipment, don&rsquo;t take a chance with anything other than Genuine Manfrotto Product.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <section className="bg-white text-center p-t-40 p-b-40">
                        <div className="container">
                            <h2 className="separator">How do I know if I am in danger of purchasing gray market products?</h2>
                            <h4 className="text-uppercase p-t-20 p-b-40">
                                <img className="p-r-20" src="//warranty.videndummediasolutions.com/Content/img/gray-market/flag.png" alt="" /> look for these red flags</h4>
                            <p>Any warranty that is not a manufacturer warranty could be suspect. <br />Example flagged terms below:</p>
                            <div className="row p-t-40 text-red">
                                <div className="col-md-4 col-sm-6 p-b-20">Warranty via seller</div>
                                <div className="col-md-4 col-sm-6 p-b-20">USA Seller Warranty</div>
                                <div className="col-md-4 col-sm-6 p-b-20">USA Coverage by Seller</div>
                                <div className="col-md-4 col-sm-6 p-b-20">Import Model</div>
                                <div className="col-md-4 col-sm-6 p-b-20">Seller Warranty</div>
                                <div className="col-md-4 col-sm-6 p-b-20">Off Market</div>
                            </div>
                        </div>
                    </section>
                    <section id="qr-code" className="bg-white p-b-60">
                        <div className="container">
                            <h2 className="text-center p-t-40">How can I tell if I bought Gray Market Product?</h2>
                            <p className="text-center p-b-40">Check the box for our QR Code. Often times, the QR code has been tampered with, removed or covered.</p>
                            <div className="border-gray text-center">
                                <div className="row">
                                    <div className="col-md-3 bg-gray-light p-b-40">
                                        <h3 className="text-white p-b-10">
                                            <span style={{ textDecoration: 'underline' }}>Normal</span>
                                        </h3>
                                        <img className="img-responsive center-block" src="//warranty.videndummediasolutions.com/Content/img/gray-market/qr-ok.jpg" alt="" />
                                    </div>
                                    <div className="col-md-9 bg-white">
                                        <h3>Examples of Tampered with QR Codes</h3>
                                        <div className="row p-r-20 p-l-20 p-t-10">
                                            <div className="col-sm-4">
                                                <img className="img-responsive center-block" src="//warranty.videndummediasolutions.com/Content/img/gray-market/qr-no-1.jpg" alt="" />
                                            </div>
                                            <div className="col-sm-4">
                                                <img className="img-responsive center-block" src="//warranty.videndummediasolutions.com/Content/img/gray-market/qr-no-2.jpg" alt="" />
                                            </div>
                                            <div className="col-sm-4">
                                                <img className="img-responsive center-block" src="//warranty.videndummediasolutions.com/Content/img/gray-market/qr-no-3.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-gray-light p-t-40  p-b-40">
                        <div className="container">
                            <h2 className="text-white text-center p-b-40">It seems like I can save a lot of money purchasing from non-authorized reseller, why does it matter if it is gray market?</h2>
                            <div className="panel p-t-40 p-l-40 p-r-40 p-b-40">
                                <div className="panel-body">
                                    <ul>
                                        <li className="p-b-20">If you are purchasing products from an unauthorized reseller, you do not know exactly what you are getting. The product could be counterfeit, refurbished selling as new or simply not the item you thought you were getting.</li>
                                        <li className="p-b-20">Manfrotto cannot guarantee the integrity of Gray Market Product and therefore, it will be ineligible for our extended warranty.</li>
                                        <li className="p-b-20">Gray Market Products are ineligible for Manfrotto Authorized repair service.</li>
                                        <li>Gray Market Products are ineligible for Manfrotto Mail-In Rebate and possible other promotional rebates.</li>
                                    </ul>
                                    <h3 className="text-uppercase text-center p-t-40">If the deal seems too good to be true, it probably is....</h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-red text-center p-t-40 p-b-60">
                        <div className="container text-white">
                            <h2 className="text-white p-b-40">Uh-oh, I think I purchased a Gray Market Product, now what do I do?</h2>
                            <p className="alert-text">Return it to the seller and we&rsquo;ll give you discount to purchase the same item directly from Manfrotto.*
                                <br />
                                <br /> <span style={{ fontSize: '.7em', lineHeight: '.8em' }}>*Discount Eligibility Requirements: Customer must provide copy of original receipt showing seller&rsquo;s name, model number, purchase date and amount as well as return confirmation/credit from original seller. Purchase and subsequent return must be within 90 days. Discount amount is at the sole discretion of Manfrotto Distribution US. Please send all materials via <a className="text-white" style={{ textDecoration: 'underline' }} href="mailto:info@manfrotto.us">email</a>. Manfrotto retains the right to terminate program at any time.</span></p>
                        </div>
                    </section>
                    <section className="bg-white p-t-40 p-b-60">
                        <div className="container text-center">
                            <h2>A note about the Amazon Marketplace.</h2>
                            <img className="img-responsive center-block p-t-20 p-b-20" src="//warranty.videndummediasolutions.com/Content/img/gray-market/amazon-marketplace.jpg" alt="" />
                            <p className="p-b-20">Manfrotto has Authorized Dealers that sell on the Amazon Marketplace and are Fulfilled by Amazon.</p>
                            <p className="p-b-20">However, there are some bad apples there. We have purchased several Gray Market Manfrotto products from the Amazon Marketplace</p>
                            <p className="p-b-20">We highly suggest that you purchase your items only from a <a className="text-red" style={{ textDecoration: 'underline' }} href="https://www.manfrotto.us/find-a-store" target="_blank" rel="noopener noreferrer">Manfrotto Authorized Dealer</a>, many of whom do legitimate business on the Amazon Marketplace.</p>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default GrayMarket;
import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface IProps {
    children: React.ReactNode
}

let Layout = ({ children }: IProps) => {
    return (
        <div className="container-flud">
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout;
import { useContext } from 'react';
import Moment from 'react-moment';
import { Media, Spinner } from 'components';
import { FormattedMessage } from 'react-intl';
import { myProductsContext } from './MyProductsContext';
import { List, ListItem } from './components';
import { Link as RouterLink } from 'react-router-dom';


let MyProducts = () => {
    let service = useContext(myProductsContext);

    return (
        <div>
            <div className="bg-light">
                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="col">
                            <h1 className="hero-title">
                                <FormattedMessage id="YOUR_PRODUCT_LIST" />
                            </h1>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-10">
                            <p className="card-text">
                                <FormattedMessage id="YOUR_PRODUCT_LIST_DESCRIPTION1" /><br />
                                <FormattedMessage id="YOUR_PRODUCT_LIST_DESCRIPTION2" /><br />
                                <small><FormattedMessage id="YOUR_PRODUCT_LIST_DESCRIPTION3" /></small>
                            </p>
                            <div className="regw__proceed">
                                <RouterLink to="/newregistration" className="btn btn-lg pr-5 pl-5 btn-success">
                                    <FormattedMessage id="REGISTER_PRODUCT" />
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                {service.isLoading === true && <Spinner />}
                {service.isLoading === false &&
                    <>
                        {service.products.map(product =>
                            <div className="card mb-3" key={product.registrationId}>
                                <div className="row no-gutters">
                                    <div className="col-sm-4 col-md-3 text-center">
                                        <Media
                                            searchBy="byProduct"
                                            mediaKey={product.sku}
                                            width={200}
                                            height={200}
                                        />
                                    </div>
                                    <div className="col-sm col-md">
                                        <div className="card-body">
                                            <h5 className="card-title">{product.sku}</h5>
                                            <div className="row">
                                                <div className="col">
                                                    <List>
                                                        <ListItem>
                                                            <div className="row">
                                                                <div className="col"><FormattedMessage id="SERIAL_NUMBER" /></div>
                                                                <div className="col-7 text-right">{product.serialNumber}</div>
                                                            </div>
                                                        </ListItem>
                                                        {product.isQrCodeMandatory &&
                                                            <ListItem>
                                                                <div className="row">
                                                                    <div className="col">QR code</div>
                                                                    <div className="col-7 text-right">{product.qrCode}</div>
                                                                </div>
                                                            </ListItem>
                                                        }
                                                        <ListItem>
                                                            <div className="row">
                                                                <div className="col"><FormattedMessage id="STORE" /></div>
                                                                <div className="col-7 text-right">
                                                                    {product.storeName}<br />
                                                                    {product.storeCountry}
                                                                </div>
                                                            </div>
                                                        </ListItem>
                                                        {product.receiptUploaded &&
                                                            <ListItem>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <FormattedMessage id="RECEIPT" />
                                                                    </div>
                                                                    <div className="col-7 text-right">
                                                                        <a href={product.receiptDownloadUrl}>
                                                                            <FormattedMessage id="DOWNLOAD_RECEIPT" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        }
                                                    </List>
                                                </div>
                                                <div className="col">
                                                    <List>
                                                        <ListItem>
                                                            <div className="row">
                                                                {product.unlimited && <div className="col bg-success font-weight-bold rounded text-white"><FormattedMessage id="UNLIMITED_WARRANTY" /></div>}
                                                                {product.unlimited === false &&
                                                                    <>
                                                                        <div className="col">
                                                                            <FormattedMessage id="EXTENSION" />
                                                                        </div>
                                                                        <div className="col-7 text-right">
                                                                            <Moment format="ll" date={product.extensionDate} />
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </ListItem>
                                                        <ListItem>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <FormattedMessage id="REGISTERED_ON" />
                                                                </div>
                                                                <div className="col-7 text-right">
                                                                    <Moment format="ll" date={product.registeredOn} />
                                                                </div>
                                                            </div>
                                                        </ListItem>
                                                        <ListItem>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <FormattedMessage id="PURCHASED_ON" />
                                                                </div>
                                                                <div className="col-7 text-right">
                                                                    <Moment format="ll" date={product.purchaseDate} />
                                                                </div>
                                                            </div>
                                                        </ListItem>
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {service.products.length === 0 && <p><FormattedMessage id="NO_REGISTERED_PRODUCTS" /></p>}
                    </>
                }
            </div>
        </div>
    );
}

export default MyProducts;
import { Country } from 'components';

let Topbar = () => {
    return (
        <div className="top-bar">
            <div className="d-flex align-items-center">
                <div className="col-md-12 d-flex align-items-center">
                    <div className="vitec-brands">
                        <div className="vitec-brands__item">
                            <ul className="head-brands">
                                <li className="logo-jby-lg-k"><a rel="noopener noreferrer" href="https://joby.com/it-it/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-lwp-lg-k"><a rel="noopener noreferrer" href="https://www.lowepro.com/it-it/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-gtz-lg-k"><a rel="noopener noreferrer" href="https://www.gitzo.com/it-it/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-bsy-lg-k"><a rel="noopener noreferrer" href="https://syrp.co/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-ryc-lg-k"><a rel="noopener noreferrer" href="https://rycote.com/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-ave-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/it-it/avenger-grip/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-col-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/it-it/colorama/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-ng-lg-k"><a rel="noopener noreferrer" href="https://www.manfrotto.com/it-it/national-geographic/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-sav-lg-k"><a rel="noopener noreferrer" href="https://savageuniversal.com/" target="_blank" className="logo"><span></span></a></li>
                                <li className="logo-aud-lg-k"><a rel="noopener noreferrer" href="https://audixusa.com/" target="_blank" className="logo"><span></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-auto position-absolute d-flex align-items-end">
                    <div className="row no-gutters w-100">
                        <div className="col-sm-auto p-0 d-flex">
                            <Country />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
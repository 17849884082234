import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Step } from '../ProductSearchContext';


let Progress = () => {
    let { step } = useParams<{ step: Step }>();
    let { formatMessage } = useIntl();

    /* Steps:
        1. /searchproduct
        2. /warrantyavailable
    */
    let [progress, setProgress] = useState({
        pct: 0,
        text: formatMessage({ id: 'START_HERE' })
    });

    useEffect(() => {
        switch (step) {
            case Step.WarrantyAvailable:
                setProgress({
                    pct: 25,
                    text: `25% ${formatMessage({ id: 'COMPLETED' })}`
                });
                break;
                
            default:
                setProgress({
                    pct: 0,
                    text: formatMessage({ id: 'START_HERE' })
                });
                break;
        }
    }, [step])

    return (
        <div className="loader">
            <span style={{ width: `${progress.pct}%` }}><span>
                <i></i>{progress.text}</span>
            </span>
        </div>
    );
}

export default Progress;
import { Spinner } from "components";
import { useContext } from "react";
import { Header, Content } from "./components";
import { downloadAreaContext } from "./DownloadAreaContext";

let DownloadArea = () => {

    let { isLoading, media } = useContext(downloadAreaContext);

    return (
        <>
            {isLoading && <Spinner />}
            {isLoading === false &&
                <>
                    <div className="bg-light">
                        <div className="container-fluid">
                            <Header />
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row text-center pt-5 pb-5">
                            {media.map(x =>
                                <div className="col-12 col-md-6 col-lg-3 col-xl-2" key={x.mediaId}>
                                    <Content media={x} />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default DownloadArea;
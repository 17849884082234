import React, { useContext } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';

var PurchaseDate = () => {
    let service = useContext(newRegistrationContext);

    let handlePurchaseDate = (date: any) => {
        service.updateRegistration({
            ...service.registration,
            purchaseDate: date
        })
    }


    return (
        <div className="col-12 col-lg-6">
            <div className="regw__form text-left">
                <span className="regw__form__input">
                    <img src="/images/icons/calendar-alt.svg" alt="" />
                    <DatePicker
                        selected={service.registration.purchaseDate}
                        onChange={handlePurchaseDate}
                        dateFormat={'yyyy/MM/dd'}
                        placeholderText="Purchase date"
                        maxDate={new Date()}
                    />
                </span>
            </div>
        </div>
    );
}

export default PurchaseDate;
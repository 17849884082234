import { useState, useContext } from 'react';
import PurchaseDate from './PurchaseDate';
import UploadReceipt from './UploadReceipt';
import ProductInfo from '../ProductInfo';
import Progress from '../Progress';
import { useHistory } from 'react-router';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';


let DateAndReceipt = () => {
    let history = useHistory();

    let service = useContext(newRegistrationContext);
    let [isUploading, setUploading] = useState(false);

    let nextStep = () => {
        if (service.registration.purchaseDate) {
            if (service.registration.isQrCodeMandatory)
                history.push('/newregistration/qrcode');
            else
                history.push('/newregistration/locatestore');
        } else {
            toast.error("Please set purchase date");
        }
    }


    return (
        <div className="text-center container pb-5">
            <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>
            <p><FormattedMessage id="INSERT_PRODUCT_CODE" /></p>

            <Progress />

            <ProductInfo />
            <hr className="mt-2 mb-2" />
            <div className="row justify-content-center pb-5">
                <div className="col-12 col-md-8 pt-5 pb-4">
                    <h5 className="mb-0">
                        <FormattedMessage id="ADD_PURCHASEDATE_AND_RECEIPT" />
                    </h5>
                    <p><FormattedMessage id="PLEASE_UPLOAD_RECEIPT" /></p>
                </div>
                <PurchaseDate />
                <UploadReceipt
                    isUploading={isUploading}
                    setUploading={setUploading}
                />
            </div>

            <div className="regw__proceed">
                <button
                    className="btn btn-success mx-auto"
                    disabled={isUploading}
                    onClick={nextStep}
                >
                    <FormattedMessage id="SAVE_AND_PROCEED" />
                </button>
            </div>
            <hr className="mt-5 mb-5" />
        </div>
    )
}

export default DateAndReceipt;
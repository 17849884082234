import { useContext, useState } from 'react';
import axios from 'axios';
import Progress from './Progress';
import { useHistory } from 'react-router';
import { productSearchContext } from '../ProductSearchContext';
import { Spinner } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { applicationContext } from 'helpers/services';


let Search = () => {
    let history = useHistory();
    let { formatMessage } = useIntl();

    let { currentCountry } = useContext(applicationContext);
    let service = useContext(productSearchContext);

    let [isSearching, setSearching] = useState(false);
    let [search, setSearch] = useState('');


    let searchProduct = async () => {
        try {
            let res = await axios.get('/api/Product/Search', { params: { search } });

            if (res.data) {
                let specificRuleIdx = res.data.countrySpecificRules.findIndex((x: any) => x.countryCode === currentCountry);

                service.updateRegistration({
                    ...service.registration,
                    sku: res.data.sku,
                    shortDescription: res.data.shortDescription,
                    unlimited: res.data.unlimited,
                    isQrCodeMandatory: res.data.isQrMandatory,
                    warrantyExtensionMonths: specificRuleIdx === -1
                        ? res.data.warrantyExtensionMonths
                        : res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths,

                    warrantyUpToMonths: specificRuleIdx === -1
                        ? res.data.warrantyUpToMonths
                        : res.data.countrySpecificRules[specificRuleIdx].warrantyExtensionMonths + res.data.countrySpecificRules[specificRuleIdx].warrantyLegalMonths,

                    productBrand: res.data.brandCode
                });

                history.push('/product/warrantyavailable');
            }
        } catch (err) {
            setSearching(false);
        }
    }


    return (
        <div className="text-center container pb-5">
            <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>
            <p><FormattedMessage id="INSERT_PRODUCT_CODE" /></p>

            <Progress />

            <form onSubmit={(e) => e.preventDefault()}>
                <div className={`regw__form text-left ${isSearching && 'loading'}`}>
                    {isSearching && <Spinner />}
                    <div className="regw regw__blockyears">
                        <label className="w-100" htmlFor="example1">
                            <FormattedMessage id="INSERT_CODE_AND_DISCOVER_WARRANTY" />
                        </label>
                        <span className="regw__form__input">
                            <img src="/images/icons/pencil.svg" alt="" />
                            <input type="text" value={search}
                                placeholder={formatMessage({ id: "SKU_EAN_UPC" })}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className="container-fluid pt-5 pb-5">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 pb-5 pb-md-0">

                                <div className="row">
                                    <div className="col-4">
                                        <img src="/images/product-packaging/product-code-example.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-8 ver">
                                        <p>
                                            <strong>
                                                <FormattedMessage id="LOCATE_PRODUCT_CODE" />
                                            </strong>
                                            <br />
                                            <FormattedMessage id="LOCATE_PRODUCT_CODE_CLICK" />
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-md-6 pb-5 pb-md-0">

                                <div className="row">
                                    <div className="col-4">
                                        <img src="/images/product-packaging/ean-upc-example.jpg" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-8 ver">
                                        <p>
                                            <strong>
                                                <FormattedMessage id="LOCATING_EAN_UPC" />
                                            </strong>
                                            <br />
                                            <FormattedMessage id="LOCATING_EAN_UPC_DESCRIPTION" />
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="regw__proceed">
                    <button type="submit" className="btn btn-success mx-auto" onClick={searchProduct}>
                        <FormattedMessage id="DISCOVER_WARRANTY" />
                    </button>
                </div>
                <hr className="mt-5 mb-5" />
            </form>
        </div>
    );
}

export default Search;
export let CN = {
    GENERAL_INFO: "基本信息",
    TITLE_1: "要在线注册保修该准备哪些信息？",
    INFORMATION_NEEDED: "请按以下信息准备",
    PACKAGE: "产品外包装",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "您的电子邮件地址和联系电话",
    SHIPPING_ADDRESS: "您的收货地址",
    PRODUCT_NAME: "产品名称",
    QRCODE: "二维码",
    TITLE_2: "如果丢弃了产品包装或遗失了二维码编码该如何处理？",
    QRCODE_LOSS: "如果您遗失了二维码标签，可以申请新的二维码编码。",
    GIVE_SOME_INFORMATIONS: "请向我们提供以下信息和购买证明，以便我们为您的产品登记新的二维码编码。",
    PRODUCT_INFORMATIONS: "产品信息",
    BRAND: "产品品牌",
    PRODUCT_CODE: "型号",
    SKU_EAN_UPC: "输入产品型号或EAN/UPC码以查询保修",
    SERIAL_NUMBER_AVAILABLE: "序列号（如果有）",
    BUY_COUNTRY: "购买地国家",
    DEALER_NAME: "经销商名称",
    BUY_DATE: "购买日期",
    PERSONAL_INFORMATIONS: "个人信息",
    FIRSTNAME: "名",
    LASTNAME: "姓",
    EMAIL: "电子邮箱",
    COUNTRY_RESIDENCE: "居住地国家",
    PROOF_OF_PURCHASE: "购买证明",
    SALES_INVOICE: "正规的销售发票",
    SECURITY_LABEL: "产品防伪标签",
    SUPPORT: "客户支持",
    TITLE_3: "如果注册过程中发生错误该如何处理？",
    SUPPORT_HOW_TO: "如果您在注册产品过程中发生了错误，请您提交问题描述和以下信息。",
    PROBLEM_DESCRIPTION: "问题描述",
    COPY_MESSAGE: "请完整复制您在网站上看到的错误提示文字",
    MODEL: "型号",
    REGISTER_WARRANTY: "注册保修",
    MY_PRODUCTS: "我的产品",
    FAQ: "常见问题",
    NAV_SUPPORT: "客户支持",
    DISCOVER_MORE: "了解更多",
    LOGOUT: "退出登录",
    ACCOUNT: "账户",
    LOGIN: "登录",
    REGISTER: "登記",
    START_HERE: "由此开始！",
    COMPLETED: "已完成",
    LOGIN_OR_REGISTER: "登录账户或立即注册以获得威登达影音解决方案的产品延长保修服务。",
    CHANGE_PRODUCT: "更改产品",
    SUPPORT_SUBTITLE: '如果您有任何问题，请点击下方按钮联系我们，客服人员将尽快与您联系。',
    NAME: '名',
    COUNTRY: '国家',
    REQUEST_TYPE: '需求类型',
    REQUEST: '需求',
    SEND_REQUEST: '发送需求',
    NEED_HELP: "需要帮忙？",
    NEED_HELP_DESCRIPTION: "我们的客户服务团队将为您解决可能遇到的问题。您可以查看“常见问题”或与我们直接联系。",
    SITE_TERMS: "网站条款",
    PRIVACY_POLICY: "隐私政策",
    COOKIES_POLICY: "Cookies政策",
    COOKIES_URL: "https://www.videndummediasolutions.com/en/cookie-policy/",
    MENU: "菜单",
    CUSTOMER_EXPERIENCE: "您的威登达影音解决方案用户体验",
    WELCOME_MESSAGE: "欢迎来到产品注册和保修支持平台",
    HOMEPAGE_MESSAGE: "请使用此网站注册您的 <strong>曼富图</strong>、<strong>JOBY</strong>、<strong>乐摄宝</strong>、<strong>捷信</strong>、<strong>Colorama</strong>、<strong>国家地理</strong>和<strong>Avenger</strong>产品。",
    DISCOVER: "了解我们提供的服务。",
    REGISTER_PRODUCT: "注册产品",
    ACCOUNT_TITLE: '您的账户',
    ACCOUNT_SUBTITLE: '在此修改您的信息和兴趣爱好。',
    MYPROFILE: '我的资料',
    CHANGE_PASSWORD: '修改密码',
    CHANGE_EMAIL: '修改邮箱',
    ADDRESS: '地址',
    CITY: '城市',
    COUNTRY_REGION: '国家/地区',
    ZIPCODE: '邮编',
    STATE: '省',
    PHONE: '电话',
    SUBSCRIBE_TO_NEWSLETTER: '我希望订阅免费电子报、产品广告和推广信息。我已阅读、理解并接受<link>隐私条款。</link>',
    PROFILING: "我希望订阅新闻以及基于个人兴趣和网站浏览习惯的定制化优惠信息。",
    SAVE: '保存',
    SAVING: '保存数据',
    FIELD_REQUIRED: "{field}是必填项",
    NEWEMAIL: "新邮箱",
    NEWEMAIL_CONFIRMATION: "确认新邮箱",
    PASSWORD: "密码",
    OLDPASSWORD: "旧密码",
    NEWPASSWORD: "新密码",
    NEWPASSWORD_CONFIRMATION: "确认新密码",
    RECEIPT_UPLOADED: "票据已上传",
    RECEIPT_NOT_UPLOADED: "票据未上传",
    CHOOSE_COUNTRY: "选择国家",
    CHOOSE_BRAND: "选择品牌",
    COMPLETE_ACCOUNT_TITLE: "您的帐户尚未完成",
    COMPLETE_ACCOUNT_SUBTITLE: "请完成您的帐户以进行产品注册",
    BOUGHT_ONLINE: "我在线下店铺购买",
    BOUGHT_IN_STORE: "我在网上购买",
    THANK_YOU: "非常感谢",
    FOR_REGISTERING: "{lastName}{firstName}，您的产品已完成注册！",
    PRODUCT_REGISTERED: "您的产品{sku}保修期已延长{months}个月。",
    WARRANTY_EXTENSION: "个月保修",
    EXTENSION_FOUND: "{months}个月延长保修",
    EXTENSION: "延长",
    NEW_EXPIRATION: "新的保修期限",
    PURCHASED_FROM: "购买自",
    TERMS_AND_CONDITIONS: "查看完整的条件和条款",
    DOWNLOAD_WARRANTY: "点击这里下载保修证书",
    REGISTER_ANOTHER_PRODUCT: "再注册一个产品",
    GOTO_PRODUCT_LIST: "查看您的产品列表",
    FORGOT_PASSWORD: '忘记密码？',
    NEW_ACCOUNT: "新账户",
    WELCOME_USER: '欢迎您 {name}',
    EDIT_ACCOUNT: "编辑账户",
    LOGIN_TO_WARRANTY: "登入您的保修信息",
    CHANGE_PURCHASEDATE_RECEIPT: "修改购买日期或上传另一张票据",
    PURCHASE_DATE: "购买日期",
    REGISTER_PRODUCT_TO_EXTEND: "注册您的产品并延长保修期",
    INSERT_PRODUCT_CODE: "在下方输入产品型号以便开始注册产品",
    SAVE_AND_PROCEED: "保存并继续",
    DOWNLOAD_RECEIPT: "下载收据",
    SERIAL_NUMBER: "序列号",
    STORE: "店铺",
    RECEIPT: "票据",
    REGISTERED_ON: "注册于",
    PURCHASED_ON: "购买于",
    PRIVACY_READ: "我已阅读 <link>隐私条款:</link>",
    PRIVACY_URL: "https://www.videndummediasolutions.com/en/privacy-policy/",
    YOUR_PRODUCT_LIST: "您的产品列表",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "您已注册的所有产品可在下方修改。",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "要注册新产品，只需开始标准注册流程。",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "注意：请确保保存您的原始发票或其它购买凭证。",
    ADD_PURCHASEDATE_AND_RECEIPT: "增加购买日期和发票",
    PLEASE_UPLOAD_RECEIPT: "请上传您的发票以申请产品保修。",
    UPLOAD_RECEIPT: "上传发票",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "输入产品型号或EAN/UPC码以查询保修：",
    UPLOADING: "上传中",
    CHOOSE_COUNTRY_AND_STORE: "选择购买国家和店铺",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "请添加您购买产品的店铺和所在国家。",
    NO_OFFICIAL_STORE_FOUND: "找不到授权店铺。",
    PROCEED_ANYWAY: "填写店铺名并继续。",
    DISCOVER_WARRANTY: "查看适用的保修期",
    LOCATE_PRODUCT_CODE: "查找产品型号",
    LOCATE_PRODUCT_CODE_CLICK: "点击这里阅读查找产品代码",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "您可以在产品外包装上找到产品型号。请参考左图在左上方找到产品型号。",
    LOCATING_EAN_UPC: "查找EAN/UPC条码",
    LOCATING_EAN_UPC_DESCRIPTION: "您可以在产品外包装上找到EAN或UPC码。请参考左图在左下方找到EAN/UPC码。",
    INSERT_PRODUCT_QR_CODE: "输入产品二维码编码",
    LOCATE_PRODUCT_QR_CODE: "二维码编码在产品外包装上。请您输入二维码下方的字符编码。若您的二维码编码遗失或破损，请联系客服获取帮助。",
    NORMAL_PRODUCT_QR_CODE: "正常的二维码编码",
    NORMAL_PRODUCT_QR_CODE_DESC: "二维码编码应是完整无缺的清晰字符且无多余记号。",
    TAMPERED_PRODUCT_QR_CODE: "二维码编码缺失",
    TAMPERED_PRODUCT_QR_CODE_DESC: "若您所购买的产品上的二维码编码丢失、磨损，或有多余记号，请通过下方链接寻求帮助。",
    PRODUCT_ELIGIBLE_FOR: "产品符合",
    NO_REGISTERED_PRODUCTS: "您没有注册过的产品",
    SUPPORTS: "支撑产品",
    CAMERA_ACCESSORIES: "相机配件",
    AUDIO_CAPTURE: "录音设备",
    BAGS: "摄影包",
    MOBILE_POWER: "移动电源",
    VIDEO_TRANSMISSION_SYSTEMS: "视频传输系统",
    PROMPTERS: "提词器",
    MOTION_CONTROL: "运动控制",
    DISTRIBUTION_RENTAL_SERVICES: "分销，租赁和服务",
    LIGHTING_AND_CONTROLS: "灯光和照明控制",
    ROBOTIC_CAMERA_SYSTEMS: "机器人摄像系统",
    MONITORS: "监视器",
    OUR_BRANDS: "我们的品牌",
    DEALER_MANDATORY: "尚未選擇商店",
    REGISTER_ACCOUNT_TITLE: "注册新账户",
    PASSWORD_CONFIRMATION: "确认密码",
    SEND_CODE: '发送验证码',
    CODE: '验证码',
    RESET_PASSWORD: '重置密码',
    RESET_PASSWORD_SUBTITLE: '请填写我们发送到您邮箱的验证码和新密码',
    RESET_PASSWORD_NEEDED: "您需要重置密码以继续",
    SIGNUP_CONFIRM: '确认注册',
    NOT_REGISTERED: "还未注册？",
    NOT_REGISTERED_SUBTITLE: "点击下方按钮创建账户。",
    SIGNUP_CONFIRMED: "您的账户已确认，请点击登录按钮进入账户。",
    CONFIRM: "确认",
    LOGIN_WITH_CREDENTIALS: "使用威登达保修账户登录",
    LOGIN_WITH_SOCIAL: "使用社交媒体账户登录",
    UNLIMITED_WARRANTY: "無限保修",
    VERIFICATION_CODE_NOT_ARRIVED: "验证码未到？",
    CLICK_ON_BUTTON: "点击下方按钮重新发送验证码。",
    SEND: "发送",
    PLEASE_CHOOSE_BRAND: "请至少选择一个您喜欢的品牌。",
    MANAGE_SUBSCRIPTIONS: "要管理您的訂閱，請單擊 {link}",
    COOKIE_CONSENT: "請接受{link}以觀看此視頻",
    ACCEPT_STATISTICS_COOKIES: "'統計 cookie'",
    HERE: "這裡",
    QRCODE_NOT_VALID: "二維碼無效", 
    DOWNLOAD_AREA: "下载区", 
    DOWNLOADABLE_CONTENTS: "Downloadable contents", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Digital Background Library", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Users may choose to download all categories to your device at once, or revisit this page in the future by simply logging into this portal with your SavageUniversal.com account credentials.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Savage Green Screen Wizard Lite Users", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Please use the button below to register your unique serial number included in your product package to gain access to the library.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Here you can find your downloadable contents.", 
    ADD_SERIAL_NUMBER: "Add serial number", 
    CANCEL: "Cancel", 
    DOWNLOAD: "Download", 
    YOUR_SERIAL_NUMBER: "Your serial number: {serialNumber}", 

    INFO_DATA_SAVED: "数据已保存",
    INFO_REQUEST_SENT: "需求已发送",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "只有非曼富图用户才能更改密码。",
    INFO_USER_ACCOUNT_SAVED: "用户账户已保存",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "确定要修改您的邮件地址吗？",
    INFO_CONFIRM_REGISTRATION: "确定要为该产品注册保修吗？",
    INFO_CONFIRM_SERIAL_NUMBER: "Are you sure that you want to register this serial number?",
    INFO_SERIAL_NUMBER_REGISTERED: "Serial number registered.",

    ERR_USER_ALREADY_EXIST: "用户已存在",
    ERR_CHECK_PRIVACY: "您需要选中隐私复选框",
    ERR_USER_NOT_FOUND: "找不到用户",
    ERR_PASSWORD_REQUIRED: "请填写密码",
    ERR_PASSWORD_MISMATCH: "密码不匹配",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "无法更改密码",
    ERR_OLDPASSWORD_REQUIRED: "请填写旧密码",
    ERR_NEWPASSWORD_REQUIRED: "请填写新密码",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "请确认新密码",
    ERR_CAPTCHA_ISREQUIRED: "请填写验证码",
    ERR_NEWEMAIL_REQUIRED: "新电子邮件无效",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "新的电子邮件确认无效",
    ERR_PASSWORD_NOT_VALID: "密码无效",
    ERR_EMAIL_DONOT_MATCH: "电子邮件不符",
    ERR_PRODUCT_NOT_FOUND: "找不到该产品",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "威泰克用户不能修改密码",
    ERR_RESET_PASSWORD_FAILED: "无法重置您的密码。",
    ERR_UNABLE_TO_UPDATE_USER: "无法更新您的信息。",
    ERR_BRANDS_MANDATORY: "必須至少指定一個品牌。",
    ERR_INVALID_SERIAL_NUMBER: "Please specify valid serial number.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Serial number not found.",

    AVAILABLE_ONLY_ON: "仅发售于",
    GITZO_LEGENDE: "捷信LÉGENDE",
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "捷信Légende，Indiegogo众筹项目",
    LEGENDE_TRIPOD_AND_BACKPACK: "Légende三脚架和双肩包",
    LEGENDE_UNLIMITED_WARRANTY: "无限保修",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "为无止尽的冒险提供修理或更换服务。",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "通过Indiegogo网站订购的Légende三脚架和和背包只要正常使用，都享有无限保修服务。如果您发现产品在材料或工艺上有缺陷，我们将免费修理、配新零件或提供更换。",
    LEGENDE_READ_TC: "阅读条款和条件",
    LEGENDE_TRIPOD: "Légende三脚架",
    LEGENDE_TRIPOD_SUB: "捷信迄今为止最优秀的三脚架，并提供终身保修服务。",
    LEGENDE_100_PARTS_REPLACEABLE: "100%零件可更换",
    LEGENDE_70_REPLACEABLE: "70%零件",
    LEGENDE_SELF_SERVICEABLE: "自助服务",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "自助式维护，在使用寿命内实现脚架最佳性能。",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "简单的保养步骤有助于Légende随时随地保持完美。",
    LEGENDE_BACKPACK: "Légende摄影背包",
    LEGENDE_BACKPACK_DESIGNED_FOR: "专为旅行摄影师设计，为可持续发展的未来而精心打造。",
    LEGENDE_65_RECYCLED: "65%再生面料",
    LEGENDE_ECOFRIENDLY_LEATHER: "环保皮革",
    LEGENDE_HOWTO_CARE_BACKPACK: "如何保养您的Légende摄影包。",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "请参照这些基本步骤来保养您的Légende背包。",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.youku.com/embed/XNTEwOTc0NjQxMg==&#39;",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.youku.com/embed/XNTEwOTc0NDc0MA==&#39",
    LEGENDE_URL_TC: "https://www.gitzo.com/cn-zh/customer-service/warranty-terms-and-conditions/"
}
import { useEffect, RefObject } from "react";

let UseOutsideClick = (ref: RefObject<any>, callback: () => void) => {
    let handleClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export default UseOutsideClick;
import { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOutSideClick } from 'helpers';
import { useHistory } from 'react-router';
import { applicationContext, authContext } from 'helpers/services';


let UserMenu = () => {
    let history = useHistory();
    let { formatMessage } = useIntl();

    let appService = useContext(applicationContext);
    let authService = useContext(authContext);

    let [showUserMenu, setShowUserMenu] = useState(false);

    let myRef = useRef<HTMLInputElement>(null);

    useOutSideClick(myRef, () => {
        if (showUserMenu === true)
            setShowUserMenu(false);
    });

    let handleLogout = () => {
        setShowUserMenu(false);
        authService.signOut();
    }

    let handleLogin = () => {
        setShowUserMenu(false);
        history.push('/account/login');
    }

    // let handleForgot = () => {
    //     setShowUserMenu(false);
    //     instance.loginRedirect({
    //         ...baseLoginRequest,
    //         authority: b2cPolicies.authorities.forgotPassword.authority
    //     });
    // }

    let handleEdit = () => {
        setShowUserMenu(false);
        history.push('/account');
    }


    return (
        <div className="actions-menu">
            {authService.isLoading === false &&
                <>
                    {authService.isAuthenticated === false &&
                        <button
                            type="button"
                            className="btn btn-block btn-outlined-primary"
                            onClick={handleLogin}
                            title={formatMessage({ id: "LOGIN" })}
                        >
                            <img src="/images/icons/user-alt.svg" alt="" width="15px" />
                        </button>
                    }
                    {authService.isAuthenticated === true &&
                        <>
                            <button
                                className="btn btn-link"
                                onClick={() => setShowUserMenu(true)}
                            >
                                <img src="/images/icons/user-alt.svg" alt="" width="15px" />
                            </button>

                            <div
                                id="user-menu"
                                className={`user-menu ${showUserMenu ? 'user-menu--active' : ''} shadow-lg`}
                                ref={myRef}
                            >
                                <strong className="border-bottom pb-2 pt-2 pl-3 w-100 ">
                                    <FormattedMessage id="WELCOME_USER" values={{ name: `${authService.currentUser?.firstname} ${authService.currentUser?.lastname}` }} />
                                    <br />
                                    <i style={{ fontWeight: 'lighter' }}>{authService.currentUser?.email}</i>
                                </strong>
                                <form className="p-3 w-100" action="#">
                                    <fieldset className="">
                                        <button
                                            className="btn btn-block btn-primary"
                                            onClick={handleEdit}
                                            type="button"
                                        >
                                            <FormattedMessage id="EDIT_ACCOUNT" />
                                        </button>
                                    </fieldset>
                                </form>
                                <div className="user-menu__new p-3 w-100">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-dark"
                                        onClick={handleLogout}
                                    >
                                        <FormattedMessage id="LOGOUT" />
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
}

export default UserMenu;
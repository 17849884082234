export let DE = {
    GENERAL_INFO: "Allgemeine Information",
    TITLE_1: "Welche Informationen werden zur Registrierung meines Produktes benötigt?",
    INFORMATION_NEEDED: "Für diesen Vorgang werden folgende Informationen benötigt",
    PACKAGE: "Verpackung",
    EMAIL_ADDRESS_AND_PHONE_NUMBER: "Ihre Email-Adresse und Telefonnummer",
    SHIPPING_ADDRESS: "Die Adresse, an die Ihr Produkt geliefert wurde",
    PRODUCT_NAME: "Name des Produktes",
    QRCODE: "QR code",
    TITLE_2: "Was ist in dem Fall zu tun, wenn ich meine Produktverpackung nicht mehr habe oder meinen QR Code verloren habe?",
    QRCODE_LOSS: "Im Fall des Verlusts der QR ID ist es m&ouml;glich, ein neues QR Label zu beantragen (+ QR ID).",
    GIVE_SOME_INFORMATIONS: "Bitte teilen Sie uns untenstehende Informationen mit, die Ihren Kauf dokumentieren.",
    PRODUCT_INFORMATIONS: "Dann werden wir Ihnen schnellstm&ouml;glich eine neue QR ID f&uuml;r Ihr Produkt zukommen lassen",
    BRAND: "Markenname",
    PRODUCT_CODE: "Teilenummer",
    SKU_EAN_UPC: "Product Code/EAN/UPC",
    SERIAL_NUMBER_AVAILABLE: "Seriennummer (falls vorhanden)",
    BUY_COUNTRY: "Einkaufsland",
    DEALER_NAME: "Hündlername",
    BUY_DATE: "Einkaufsdatum",
    PERSONAL_INFORMATIONS: "Ihre persönlichen Daten",
    FIRSTNAME: "Vorname",
    LASTNAME: "Nachname",
    EMAIL: "Email-Adresse",
    COUNTRY_RESIDENCE: "Land",
    PROOF_OF_PURCHASE: "Kaufnachweis",
    SALES_INVOICE: "Quittung",
    SECURITY_LABEL: "Sicherheitsetikett",
    SUPPORT: "Unterstützung",
    TITLE_3: "Was ist in dem Fall zu tun, wenn ein Problem oder ein Fehler während des Produktregistrierungsprozesses auftritt?",
    SUPPORT_HOW_TO: "Wenn die Probleme bei der Produktregistrierung auftauchen, schildern Sie uns bitte kurz, welcher Fehler bei Ihnen auftrat, und geben Sie uns dar&uuml;ber hinaus folgende Informationen bekannt",
    REGISTER_WARRANTY: "Garantieregistrierung",
    MY_PRODUCTS: "Meine Produkte",
    FAQ: "FAQ",
    NAV_SUPPORT: "Support",
    DISCOVER_MORE: "Mehr erfahren",
    LOGOUT: "Logout",
    ACCOUNT: "Konto",
    LOGIN: "Log in",
    REGISTER: "Register",
    START_HERE: "Start here!",
    COMPLETED: "Abgeschlossen",
    LOGIN_OR_REGISTER: "Log-in or register now to obtain the Videndum Media Solutions warranty extension on your products",
    CHANGE_PRODUCT: "Produkt wechseln", 
    SUPPORT_SUBTITLE: 'Bei Fragen klicken Sie bitte auf die Schaltflächen unten. Ein Mitarbeiter wird sich schnellstmöglich um Ihr Anliegen kümmern.',
    NAME: 'Nicht ich',
    COUNTRY: 'Land',
    REQUEST_TYPE: 'Art der Anfrage',
    REQUEST: 'Anfrage',
    PROBLEM_DESCRIPTION: "Beschreibung des Problems",
    COPY_MESSAGE: "Bitte kopieren Sie die Fehlermeldung, die Sie auf der Website sehen, vollständig",
    MODEL: "Modell-",
    SEND_REQUEST: 'Anfrage senden',
    NEED_HELP: "Brauche Hilfe?",
    NEED_HELP_DESCRIPTION: "Unser Kundendienstteam hilft Ihnen bei allen Fragen zu Problemen. Sie können entweder unseren FAQ-Bereich besuchen oder uns direkt kontaktieren.",
    SITE_TERMS: "Nutzungsbedingungen",
    PRIVACY_POLICY: "Datenschutz-Bestimmungen",
    PRIVACY_URL: "https://www.videndummediasolutions.com/de/datenschutz-bestimmungen/",
    COOKIES_POLICY: "Cookie-Richtlinie",
    COOKIES_URL: "https://www.videndummediasolutions.com/de/cookie-richtlinie/",
    MENU: "Speisekarte",
    CUSTOMER_EXPERIENCE: "Ihr Videndum Media Solutions Service",
    WELCOME_MESSAGE: "WILLKOMMEN BEI UNSERER ONLINE-REGISTRIERUNG",
    HOMEPAGE_MESSAGE: "Bitte nutzen Sie diese Website, um Ihre <strong>Manfrotto</strong>, <strong>JOBY</strong>, <strong>Lowepro</strong>, <strong>Gitzo</strong>, <strong>Colorama</strong>, <strong>National Geographic</strong> und <strong>Avenger</strong> Produkte zu registrieren.",
    PRIVACY_READ: "Lesen Sie <link>die Informationen zu persönlichen Daten:</link>",
    DISCOVER: "Entdecken Sie im Folgenden unser Serviceangebot.",
    REGISTER_PRODUCT: "Produktregistrierung",
    ACCOUNT_TITLE: 'Ihr Account',
    ACCOUNT_SUBTITLE: 'Ändern Sie hier Ihre Basisinformationen und Interessen.',
    MYPROFILE: 'Mein Profil',
    CHANGE_PASSWORD: 'Passwort ändern',
    CHANGE_EMAIL: "Ändern Sie die E-Mail",
    ADDRESS: 'Adresse',
    CITY: 'Stadt',
    COUNTRY_REGION: 'Land',
    ZIPCODE: 'Postleitzahl',
    STATE: 'Bundesland',
    PHONE: 'Telephone',
    SUBSCRIBE_TO_NEWSLETTER: "Ich melde mich an, um kostenlose Newsletter, Werbe- und Marketingmitteilungen zu erhalten, und ich habe die Datenschutzbestimmungen gelesen, verstanden und akzeptiert. <link>Persönlichen Daten.</link>",
    PROFILING: "Ich melde mich an, um Mitteilungen über Neuigkeiten und Angebote zu Produkten zu erhalten, die auf meine Interessen zugeschnitten sind, basierend auf meinen Kaufgewohnheiten, meinen Vorlieben und der Navigation auf der Website.",
    SAVE: 'Speichern',
    SAVING: 'Daten speichern',
    FIELD_REQUIRED: "{field} ist obligatorisch",
    NEWEMAIL: "Neue e-mail",
    NEWEMAIL_CONFIRMATION: "Bestätige neue E-Mail",
    PASSWORD: "Passwort",
    OLDPASSWORD: "Altes Passwort",
    NEWPASSWORD: "Neues Passwort",
    NEWPASSWORD_CONFIRMATION: "Neues Passwort bestätigen",
    RECEIPT_UPLOADED: "Quittung hochgeladen",
    RECEIPT_NOT_UPLOADED: "Quittung nicht hochgeladen",
    CHOOSE_COUNTRY: "Land auswählen",
    CHOOSE_BRAND: "Wählen Sie die Marke",
    COMPLETE_ACCOUNT_TITLE: "Ihr Konto ist nicht vollständig",
    COMPLETE_ACCOUNT_SUBTITLE: "Bitte vervollständigen Sie Ihr Konto, um mit der Produktregistrierung fortzufahren",
    BOUGHT_ONLINE: "Onlinekauf",
    BOUGHT_IN_STORE: "Ich habe es im Geschäft gekauft",
    THANK_YOU: "Danke",
    FOR_REGISTERING: "{Vorname} {Nachname} für die Produktregistrierung!",
    PRODUCT_REGISTERED: "Die Garantie für {sku} wurde um {months} months verlängert",
    WARRANTY_EXTENSION: "Monate Garantie",
    EXTENSION: "Erweiterung",
    NEW_EXPIRATION: "Neues Ablaufdatum",
    PURCHASED_FROM: "Gekauft bei",
    TERMS_AND_CONDITIONS: "Gesamte Geschäftsbedingungen anzeigen",
    DOWNLOAD_WARRANTY: "Hier klicken, um die Geschäftsbedingungen runterzuladen",
    REGISTER_ANOTHER_PRODUCT: "Ein weiteres Produkt registrieren",
    GOTO_PRODUCT_LIST: "Produktliste anzeigen",
    FORGOT_PASSWORD: 'Passwort vrgessen?',
    NEW_ACCOUNT: "Neuer Account",
    WELCOME_USER: 'Wilkommen {name}',
    EDIT_ACCOUNT: "Account bearbeiten",
    LOGIN_TO_WARRANTY: "Einloggen",
    WELCOME_USER_SUBTITLE: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris commodo lacinia libero vel porta.",
    CHANGE_PURCHASEDATE_RECEIPT: "Rechnungsdatum ändern oder andere Rechnung hochladen",
    PURCHASE_DATE: "Kaufdatum",
    REGISTER_PRODUCT_TO_EXTEND: "Registrieren Sie Ihr Produkt, um die Garantie zu verlängern",
    INSERT_PRODUCT_CODE: "Geben Sie den Produktcode unten ein, um Ihre Garantieregistrierung zu starten",
    SAVE_AND_PROCEED: "Speichern und fortfahren",
    DOWNLOAD_RECEIPT: "Beleg runterladen",
    SERIAL_NUMBER: "Seriennummer",
    STORE: "Geschäft",
    RECEIPT: "Beleg",
    REGISTERED_ON: "Registriert am",
    PURCHASED_ON: "Gekauft am",
    YOUR_PRODUCT_LIST: "Ihre Produktliste",
    YOUR_PRODUCT_LIST_DESCRIPTION1: "Alle Produkte, die Sie registriert haben, werden unten angezeigt und können geändert werden.",
    YOUR_PRODUCT_LIST_DESCRIPTION2: "Um ein neues Produkt zu registrieren, starten Sie einfach das standardmäßige Registrierungsverfahren.",
    YOUR_PRODUCT_LIST_DESCRIPTION3: "Hinweis: Bitte achten Sie darauf, dass Sie Ihre Originalbelege und Dokumente zum Nachweis des Kaufs immer aufbewahren",
    ADD_PURCHASEDATE_AND_RECEIPT: "Kaufdatum und Quittung hinzufügen",
    PLEASE_UPLOAD_RECEIPT: "Bitte laden Sie Ihren Kaufbeleg hoch, um Ihre Garantie in Anspruch zu nehmen.",
    UPLOAD_RECEIPT: "Kaufbeleg hochladen",
    UPLOADING: "Lädt hoch",
    CHOOSE_COUNTRY_AND_STORE: "Wählen Sie Land und Shop",
    CHOOSE_COUNTRY_AND_STORE_DESCRIPTION: "Bitte nennen Sie den Namen des Shops und LAndes wo das Produkt gekauft wurde.",
    NO_OFFICIAL_STORE_FOUND: "Kein offizieller Shop gefunden.",
    PROCEED_ANYWAY: "Trotzdem fortfahren.",
    EXTENSION_FOUND: "{months} Monate Garantieerweiterung",
    INSERT_CODE_AND_DISCOVER_WARRANTY: "Fügen Sie die Artikelnummer ein, um die mögliche Garantie zu sehen",
    DISCOVER_WARRANTY: "Finden Sie heraus, welche Garantie zu Verfügung steht",
    LOCATE_PRODUCT_CODE: "Artikelnummer finden",
    LOCATE_PRODUCT_CODE_CLICK: "Hier klicken, um die Artikelnummer zu sehen",
    LOCATING_PRODUCT_CODE_DESCRIPTION: "Sie finden die Artikelnummer auf der Verpackung. Die Artikelnummer finden Sie auf dem Bild links in der oberen Ecke.",
    LOCATING_EAN_UPC: "Suchen nach EAN oder UPC bar code",
    LOCATING_EAN_UPC_DESCRIPTION: "Sie finden den EAN- oder UPC-Code auf der Verpackung. Auf dem Bild links finden Sie den UPC/EAN in der unteren linken Ecke.",
    INSERT_PRODUCT_QR_CODE: "Geben Sie den Produkt-QR-Code ein",
    LOCATE_PRODUCT_QR_CODE: "Der QR-Code befindet sich auf der Verpackung Ihres Produkts. Bitte geben Sie den alphanumerischen Code unter dem QR-Code-Zeichen ein. Wenn Ihr QR-Code fehlt oder manipuliert wurde, wenden Sie sich bitte an den Kundensupport.",
    PRODUCT_ELIGIBLE_FOR: "Produkt geeignet für",
    NO_REGISTERED_PRODUCTS: "Sie haben nun Ihr Produkt registriert",
    SUPPORTS: "Unterstützt",
    CAMERA_ACCESSORIES: "Kamera Zubehör",
    AUDIO_CAPTURE: "Audio-Aufnahme",
    BAGS: "Taschen",
    MOBILE_POWER: "Mobile Power",
    VIDEO_TRANSMISSION_SYSTEMS: "Video Übertragungssysteme",
    PROMPTERS: "Prompters",
    MOTION_CONTROL: "Motion Control",
    DISTRIBUTION_RENTAL_SERVICES: "Distribution, rental & services",
    LIGHTING_AND_CONTROLS: "Lighting and controls",
    ROBOTIC_CAMERA_SYSTEMS: "Robotic camera systems",
    MONITORS: "Monitors",
    OUR_BRANDS: "Unsere Marken",
    DEALER_MANDATORY: "Store wurde nicht ausgewählt",
    REGISTER_ACCOUNT_TITLE: "Neues Konto registrieren",
    PASSWORD_CONFIRMATION: "Passwort bestätigen",
    SEND_CODE: "Code senden",
    CODE: 'Code',
    RESET_PASSWORD: 'Passwort zurücksetzen',
    RESET_PASSWORD_SUBTITLE: 'Bitte fügen Sie den Code, den wir an Ihre E-Mail geschickt haben, und Ihr neues Passwort',
    RESET_PASSWORD_NEEDED: "Sie müssen Ihr Passwort zurücksetzen, um fortzufahren",
    SIGNUP_CONFIRM: "Bestätigen Sie die Anmeldung",
    NOT_REGISTERED: "Nicht registriert?",
    NOT_REGISTERED_SUBTITLE: "Klicken Sie auf die Schaltfläche unten, um ein neues Konto zu erstellen.",
    SIGNUP_CONFIRMED: "Ihr Konto wurde verifiziert, klicken Sie auf die Schaltfläche Anmelden, um sich anzumelden.",
    CONFIRM: "Bestätigen",
    LOGIN_WITH_CREDENTIALS: "Melden Sie sich mit dem Videndum-Garantiekonto an",
    LOGIN_WITH_SOCIAL: "Anmeldung mit Social Konto",
    UNLIMITED_WARRANTY: "Unbegrenzte Garantie", 
    VERIFICATION_CODE_NOT_ARRIVED: "Bestätigungscode ist nicht angekommen?",
    CLICK_ON_BUTTON: "Klicken Sie auf die Schaltfläche unten, um den Bestätigungscode erneut zu senden.",
    SEND: "Senden",
    PLEASE_CHOOSE_BRAND: "Bitte wählen Sie mindestens eine Marke aus, die Ihnen gefällt.",
    MANAGE_SUBSCRIPTIONS: "Um Ihre Abonnements zu verwalten, klicken Sie auf {link}",
    COOKIE_CONSENT: "Bitte {link}, um dieses Video anzusehen",
    ACCEPT_STATISTICS_COOKIES: "akzeptieren Sie 'Statistik-Cookies'",
    HERE: "hier",
    QRCODE_NOT_VALID: "QR-Code ist nicht gültig", 
    DOWNLOAD_AREA: "Downloadbereich", 
    DOWNLOADABLE_CONTENTS: "Herunterladbare Inhalte", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_TITLE: "Savage Green Screen Wizard Lite - Digital Background Library", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_OVERVIEW_SUBTITLE1: "Users may choose to download all categories to your device at once, or revisit this page in the future by simply logging into this portal with your SavageUniversal.com account credentials.", 
    DOWNLOADABLE_CONTENTS_TITLE: "Savage Green Screen Wizard Lite Users", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_NO_SERIAL: "This download portal is designated for purchasers of the Savage Green Screen Wizard Lite computer program for chroma key applications. Included with our software is exclusive access to our robust image library of 3,500 high-quality digital backgrounds for your unlimited use.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE1_NO_SERIAL: "Please use the button below to register your unique serial number included in your product package to gain access to the library.", 
    DOWNLOADABLE_CONTENTS_SUBTITLE_WITH_SERIAL: "Here you can find your downloadable contents.", 
    ADD_SERIAL_NUMBER: "Seriennummer hinzufügen", 
    CANCEL: "kündigen", 
    DOWNLOAD: "Herunterladen", 
    YOUR_SERIAL_NUMBER: "Ihre Seriennummer: {serialNumber}", 

    INFO_DATA_SAVED: "Daten gespeichert",
    INFO_REQUEST_SENT: "Anfrage gesendet",
    INFO_CHANGE_PASSWORD_NOT_PERMITTED: "Nur Nicht-Manfrotto-Benutzer können das Passwort ändern.",
    INFO_USER_ACCOUNT_SAVED: "Benutzer Konto gespeichert",
    INFO_DO_YOU_WANT_TO_CHANGE_EMAIL: "Sind Sie sicher, dass Sie Ihre E-Mail-Adresse ändern möchten?",
    INFO_CONFIRM_REGISTRATION: "Sind Sie sicher, dass Sie das Sie dieses Produkt für die Garantie registrieren möchten?",
    INFO_CONFIRM_SERIAL_NUMBER: "Sind Sie sicher, dass Sie diese Seriennummer registrieren möchten?",
    INFO_SERIAL_NUMBER_REGISTERED: "Seriennummer registriert.",

    ERR_USER_ALREADY_EXIST: "Benutzer existiert bereits",
    ERR_CHECK_PRIVACY: "Sie müssen das Kontrollkästchen für den Datenschutz anklicken",
    ERR_USER_NOT_FOUND: "Benutzer wurde nicht gefunden",
    ERR_PASSWORD_REQUIRED: "Passwort erforderlich",
    ERR_PASSWORD_MISMATCH: "Passwort ist falsch",
    ERR_UNABLE_TO_CHANGE_PASSWORD: "Passwort kann nicht geändert werden",
    ERR_OLDPASSWORD_REQUIRED: "Altes Passwort ist erforderlich",
    ERR_NEWPASSWORD_REQUIRED: "Neues Passwort ist erforderlich",
    ERR_NEWPASSWORD_CONFIRMATION_REQUIRED: "Neue Passwortbestätigung ist erforderlich",
    ERR_CAPTCHA_ISREQUIRED: "Bitte lösen Sie das Captcha auf",
    ERR_NEWEMAIL_REQUIRED: "Neue E-Mail ist ungültig",
    ERR_NEWEMAIL_CONFIRMATION_REQUIRED: "Neue E-Mail-Bestätigung ist ungültig",
    ERR_PASSWORD_NOT_VALID: "Passwort ist ungültig",
    ERR_EMAIL_DONOT_MATCH: "E-Mails stimmen nicht überein",
    ERR_PRODUCT_NOT_FOUND: "Produkt nicht gefunden",
    ERR_MANFROTTO_USERS_CANNOT_CHANGE_PASSWORD: "Videndum users cannot change password",
    ERR_RESET_PASSWORD_FAILED: "Unable to reset your password.",
    ERR_UNABLE_TO_UPDATE_USER: "Unable to update user informations.",
    ERR_BRANDS_MANDATORY: "Es muss mindestens eine Marke angegeben werden.",
    ERR_INVALID_SERIAL_NUMBER: "Geben Sie die gültige Seriennummer an.",
    ERR_SERIAL_NUMBER_NOT_FOUND: "Seriennummer nicht gefunden.",

    AVAILABLE_ONLY_ON: "Alleen beschikbaar op",
    GITZO_LEGENDE: "GITZO Légende", 
    LEGENDE_JOIN_CAMPAIGN_INDIEGOGO: "Gitzo Légende – Melden Sie sich an für das exklusive Angebot auf Indiegogo.",
    LEGENDE_TRIPOD_AND_BACKPACK: "Légende Stativ und Rucksack",
    LEGENDE_UNLIMITED_WARRANTY: "Uneingeschränkte Garantie",
    LEGENDE_REPAIR_REPLACE_ADVENTURE: "Reparatur oder Ersatz für endlose Abenteuer.",
    LEGENDE_UNLIMITED_WARRANTY_DESCRIPTION: "Alle bei Indiegogo bestellten Stative und Rucksäcke der Reihe Légende genießen bei normaler Nutzung uneingeschränkte Garantie. Bei Materialfehlern oder Fehlern in der Verarbeitung reparieren wir das Produkt, schicken Ersatzteile oder ersetzen das Produkt jeweils kostenlos.",
    LEGENDE_READ_TC: "AGB lesen",
    LEGENDE_TRIPOD: "Stativ Légende",
    LEGENDE_TRIPOD_SUB: "Das beste Stativ, das wir je hergestellt haben, lässt sich immer wieder reparieren.",
    LEGENDE_100_PARTS_REPLACEABLE: "100 % der austauschbaren Teile können immer wieder repariert werden.",
    LEGENDE_70_REPLACEABLE: "70% der Teile können vom Nutzer ausgetauscht werden",
    LEGENDE_SELF_SERVICEABLE: "Die Wartung kann selbst erledigt werden.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE: "Die Wartung kann selbst erledigt werden – für eine optimale Leistung über die gesamte Lebensdauer.",
    LEGENDE_SELF_SERVICEABLE_ENTIRE_LIFE_DESCRIPTION: "Durch ein paar simple Wartungsschritte bleibt das Stativ Légende in perfektem Zustand, wohin die Reise auch geht.",
    LEGENDE_BACKPACK: "Rucksack Légende",
    LEGENDE_BACKPACK_DESIGNED_FOR: "Von Experten entworfen für Fotografen auf Reisen, ausgelegt auf Nachhaltigkeit und lange Nutzung.",
    LEGENDE_65_RECYCLED: "Zu 65% recyceltes Gewebe",
    LEGENDE_ECOFRIENDLY_LEATHER: "Umweltfreundliches Kunstleder",
    LEGENDE_HOWTO_CARE_BACKPACK: "So pflegen Sie Ihren Légende Rucksack:",
    LEGENDE_FOLLOW_BASIC_STEP_CARE_BACKPACK: "Befolgen Sie die grundlegenden Schritte zur Pflege Ihres Légende Rucksacks.",
    LEGENDE_URL_VIDEO_TRIPOD: "https://player.vimeo.com/video/505053611?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_VIDEO_BACKPACK: "https://player.vimeo.com/video/505050183?title=0&amp;byline=0&amp;portrait=0",
    LEGENDE_URL_TC: "https://www.gitzo.com/de-de/customer-service/garantie/"
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface IMyProductsContext {
    isLoading: boolean
    products: IProduct[]
}

interface IProduct {
    registrationId: string
    sku: string
    serialNumber: string
    qrCode: string
    isQrCodeMandatory: boolean
    storeName: string
    storeCountry: string
    registeredOn: Date
    unlimited: boolean
    purchaseDate: Date
    extensionDate?: Date
    months: number
    receiptUploaded: boolean
    receiptDownloadUrl: string
}

export let myProductsContext = React.createContext({} as IMyProductsContext);

let { Provider } = myProductsContext;

let MyProductsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    let [isLoading, setLoading] = useState(false);
    let [products, setProducts] = useState<IProduct[]>([]);


    useEffect(() => {
        getProducts();
    }, [])

    let getProducts = async () => {
        try {
            setLoading(true);
            
            let res = await axios.get('/api/Product/GetRegisteredProducts')
            setProducts(res.data.items);

        } finally {
            setLoading(false);
        }
    }

    return (
        <Provider
            value={{
                isLoading,
                products
            }}>
            {children}
        </Provider>
    )
}

export default MyProductsProvider;
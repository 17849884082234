import 'css/page/account.scss';
import { useContext } from 'react';
import { authContext } from 'helpers/services';
import { useIntl, FormattedMessage } from 'react-intl';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { Spinner } from 'components';


let Login = () => {
    let { formatMessage } = useIntl();

    let authService = useContext(authContext);

    let loginSchema = yup.object({
        clientId: yup.string().required().label("Email").default(''),
        clientSecret: yup.string().required().label("Password").default('')
    })


    return (
        <Formik
            enableReinitialize={true}
            initialValues={loginSchema.getDefault()}
            validationSchema={loginSchema}
            onSubmit={async (values, action) => {
                action.setSubmitting(true);
                await authService.signInWithCredentials(values.clientId, values.clientSecret);
                action.setSubmitting(false);
            }}
        >
            <div className="accountpanel">
                <div className="bg-white p-0 pt-5 pt-lg-0">
                    <div className="container py-4 px-5">
                        <h5 className="pb-4">{formatMessage({ id: "LOGIN_WITH_SOCIAL" })}</h5>

                        <div className="row pb-5">
                            <div className="col-6">
                                <button className="accountButton account-fb" onClick={() => authService.federatedSignIn('Facebook')}>Facebook</button>
                            </div>
                            <div className="col-6">
                                <button className="accountButton account-google" onClick={() => authService.federatedSignIn('Google')}>Google</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h5 className="pb-4">{formatMessage({ id: "LOGIN_WITH_CREDENTIALS" })}</h5>
                                <Form>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="textinput">{formatMessage({ id: "EMAIL" })}</label>
                                                <Field
                                                    className="form-control"
                                                    disabled={authService.isLoading}
                                                    label="Email"
                                                    type="email"
                                                    name="clientId"
                                                />
                                                <ErrorMessage name="clientId" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'EMAIL' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="textinput">{formatMessage({ id: "PASSWORD" })}</label>
                                                <Field
                                                    disabled={authService.isLoading}
                                                    className="form-control"
                                                    name="clientSecret"
                                                    type="password"
                                                />
                                                <ErrorMessage name="clientSecret" component="span">
                                                    {message => <span className="badge badge-pill badge-danger">{formatMessage({ id: 'FIELD_REQUIRED' }, { field: formatMessage({ id: 'PASSWORD' }) })}</span>}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <RouterLink to="/account/forgotpassword" className="btn btn-link btn-sm">{formatMessage({ id: "FORGOT_PASSWORD" })}</RouterLink>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {authService.isLoading && <Spinner />}
                                            {authService.isLoading === false &&
                                                <button style={{ float: 'right' }} className="btn btn-block btn-success" type="submit">
                                                    {formatMessage({ id: 'LOGIN' })}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4 p-5 bg-success text-white">
                        <div className="row">
                            <div className="col-12">
                                <h5><FormattedMessage id="NOT_REGISTERED" /></h5>
                                <p><FormattedMessage id="NOT_REGISTERED_SUBTITLE" /></p>
                                <RouterLink to="/account/signup">
                                    <button className="btn btn-light bg-white">
                                        <FormattedMessage id="REGISTER" />
                                    </button>
                                </RouterLink>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Formik>
    );
}

export default Login;
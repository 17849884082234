import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import ProductInfo from '../ProductInfo';
import Progress from '../Progress';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LocateQrCode from './LocateQrCode';
import { newRegistrationContext } from 'pages/NewRegistration/NewRegistrationContext';


let QrCode = () => {
    let history = useHistory();
    let service = useContext(newRegistrationContext);

    let [qrCodeVerified, setQrCodeVerified] = useState(false);
    let [qrCodeIsValid, setQrCodeValid] = useState(false);

    let validateQrCode = async () => {
        try {
            setQrCodeVerified(false);
            setQrCodeValid(false);

            let request = {
                qrCode: service.registration.qrCode,
                sku: service.registration.sku
            }

            let res = await axios.get('/api/Product/CheckQr', { params: { ...request } });
            if (res.data === true)
                history.push('/newregistration/locatestore');
            else 
            {
                setQrCodeVerified(true);
                setQrCodeValid(false);
            }
        } catch (err) {
            setQrCodeVerified(true);
            setQrCodeValid(false);
        }
    }

    let handleQrCode = (e: any) => {
        service.updateRegistration({
            ...service.registration,
            qrCode: e.target.value
        });
    }

    return (
        <div className="text-center container pb-5">
            <h2><FormattedMessage id="REGISTER_PRODUCT_TO_EXTEND" /></h2>
            <p><FormattedMessage id="INSERT_PRODUCT_CODE" /></p>

            <Progress />
            <ProductInfo />
            <hr className="mt-2 mb-2" />

            <div className="row justify-content-center text-left">
                <div className="col-3 text-center">
                    <img src="/images/icons/check.svg" alt="" height="30px" className="mt-3" />
                </div>
                <div className="col">
                    <p className="pt-3 pb-3 m-0 font-s-lg">
                        <strong><FormattedMessage id="PURCHASE_DATE" />: </strong><Moment date={service.registration.purchaseDate} format="LL" />
                        {service.registration.receiptId &&
                            <strong className="pl-lg-5 d-block d-md-inline-block text-success">
                                <FormattedMessage id="RECEIPT_UPLOADED" />
                            </strong>
                        }
                        {!service.registration.receiptId &&
                            <strong className="pl-lg-5 d-block d-md-inline-block text-warning">
                                <FormattedMessage id="RECEIPT_NOT_UPLOADED" />
                            </strong>
                        }
                    </p>
                </div>
            </div>
            <hr className="mt-2 mb-2" />

            <div className="regw__form justify-content-center text-left">
                <div className="col-12 col-md-8 pt-5 pb-4 text-center mx-auto">
                    <h5 className="mb-0">
                        <FormattedMessage id="INSERT_PRODUCT_QR_CODE" />
                    </h5>
                    <p>
                        <FormattedMessage id="LOCATE_PRODUCT_QR_CODE" />
                        <RouterLink to='/support'><u><FormattedMessage id="SUPPORT" /></u></RouterLink>.
                    </p>
                </div>
                <div className="">
                    <label className="w-100" htmlFor="example1">QR code:</label>
                    <span className="regw__form__input"><img src="/images/icons/qrcode.svg" alt="" />
                        <input
                            type="text"
                            value={service.registration.qrCode}
                            onChange={handleQrCode}
                            placeholder="Qr code"
                        />
                    </span>
                    {qrCodeVerified === true && qrCodeIsValid === false && 
                        <p className="alert alert-warning mt-1">
                            <FormattedMessage id="QRCODE_NOT_VALID" />
                        </p>
                    }
                </div>
                <LocateQrCode />
            </div>

            <div className="regw__proceed">
                <button
                    className="btn btn-success mx-auto"
                    onClick={validateQrCode}
                >
                    <FormattedMessage id="SAVE_AND_PROCEED" />
                </button>
            </div>
        </div >
    )
}

export default QrCode;